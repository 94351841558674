import React, { Component } from "react";
import { isEmpty } from "lodash";
import { Card, DataTable, Text } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { formatMoney } from "utils/numbers";

class UnionOSSPreviewOld extends Component {
  renderSummaryRow = (sum, vat, title) => {
    const { currency } = this.props;
    const currencyIcon =
      currency.toLowerCase() === "eur" ? " \u{20AC} " : currency;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Text variant='bodyMd'>
          {title}: {formatMoney(sum)} {currencyIcon}
        </Text>
        <Text variant='bodyMd'>
          VAT due: {formatMoney(vat)} {currencyIcon}
        </Text>
      </div>
    );
  };

  renderSummary = () => {
    const {
      sumGoods,
      vatGoods,
      sumServices,
      vatServices,
      withDomestic,
      sumDomestic,
      vatDomestic,
      step,
    } = this.props;

    return (
      <div>
        {this.renderSummaryRow(sumGoods, vatGoods, "Sales of goods")}
        {step > 1 &&
          this.renderSummaryRow(sumServices, vatServices, "Sales of services")}
        {step > 2 &&
          withDomestic &&
          this.renderSummaryRow(
            sumDomestic,
            vatDomestic,
            "Sales domestic by OMP"
          )}
        <br />
      </div>
    );
  };

  renderRowByType = (data, country, title, type) =>
    data.map((item, ind) => {
      return [
        ind === 0 ? country : "",
        ind === 0 ? title : "",
        formatMoney(item.total_sum),
        `${item.vat_percent}%`,
        formatMoney(item.total_vat),
      ];
    });

  renderSumRow = (title, revenue, vat) => {
    return [
      <Text fontWeight='semibold'>{title}</Text>,
      "",
      <Text fontWeight='semibold'>{revenue}</Text>,
      "",
      <Text fontWeight='semibold'>{vat}</Text>,
    ];
  };

  columns = [
    this.props.t("createReturns.country"),
    "",
    `Revenue (${this.props.currency})`,
    "VAT rate",
    "VAT",
  ];

  getRows = () => {
    const {
      sumGoods,
      vatGoods,
      sumServices,
      vatServices,
      sumDomestic,
      vatDomestic,
      data,
    } = this.props;

    const rows = !isEmpty(data)
      ? data
          .map((item) => {
            const goodsRows = this.renderRowByType(
              item.sales_of_goods,
              item.country,
              "Sales of goods",
              "g"
            );

            const servicesRows = this.renderRowByType(
              item.sales_of_services,
              isEmpty(item.sales_of_goods) ? item.country : "",
              "Sales of services",
              "s"
            );

            const domesticsRows = this.renderRowByType(
              item.sales_of_domestic,
              isEmpty(item.sales_of_goods) && isEmpty(item.sales_of_services)
                ? item.country
                : "",
              "Sales of domestic",
              "d"
            );

            return [...goodsRows, ...servicesRows, ...domesticsRows];
          })
          .flat()
      : [["-", "", "0", "-", "0"]];

    const revenue = formatMoney(sumGoods + sumServices + sumDomestic);
    const vat = formatMoney(vatGoods + vatServices + vatDomestic);

    rows.push(this.renderSumRow("Total", revenue, vat));

    return rows;
  };

  render() {
    const { data } = this.props;

    return (
      <div>
        {this.renderSummary()}
        {data && (
          <Card>
            <DataTable
              columnContentTypes={["text", "text", "text", "numeric"]}
              headings={this.columns.map((label) => (
                <Text fontWeight='semibold'>{label}</Text>
              ))}
              rows={this.getRows()}
              hideScrollIndicator
              increasedTableDensity
            />
          </Card>
        )}
      </div>
    );
  }
}

export default withTranslation()(UnionOSSPreviewOld);
