import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import { parseServerError } from "utils/errors";

export const fetchRegNumbers = createAsyncThunk(
  "registration/fetchRegNumbers",
  async (active = false, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/vat_registration?is_active=${active}`,
        "GET"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(parseServerError(error));
    }
  }
);

export const fetchVatCountryStates = createAsyncThunk(
  "registration/fetchVatCountryStates",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/vat_registration/vat_country_states",
        "GET"
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const getAllNotCompletedOrders = createAsyncThunk(
  "registration/getAllNotCompletedOrders",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/vat_registration/answer", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(parseServerError(error));
    }
  }
);

export const fetchRegCountries = createAsyncThunk(
  "registration/fetchRegCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/vat_registration/country", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(parseServerError(error));
    }
  }
);

export const addRegNumber = createAsyncThunk(
  "registration/addRegNumber",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/vat_registration/add", "POST", params);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(parseServerError(error));
    }
  }
);

export const editRegNumber = createAsyncThunk(
  "registration/editRegNumber",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/vat_registration/edit", "POST", params);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(parseServerError(error));
    }
  }
);

export const deleteRegOrder = createAsyncThunk(
  "registration/deleteRegOrder",
  async (answer, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/vat_registration/answer",
        "DELETE",
        answer
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(parseServerError(error));
    }
  }
);

export const fetchGermanyStates = createAsyncThunk(
  "registration/fetchGermanyStates",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/vat_registration/vat_germany_states",
        "GET"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(parseServerError(error));
    }
  }
);

export const fetchReasons = createAsyncThunk(
  "registration/fetchReasons",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/vat_registration/reason_for_registration",
        "GET"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(parseServerError(error));
    }
  }
);

export const fetchRegQuestions = createAsyncThunk(
  "registration/fetchRegQuestions",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/vat_registration/questions",
        "GET",
        params
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(parseServerError(error));
    }
  }
);

export const orderRegNumber = createAsyncThunk(
  "registration/orderRegNumber",
  async ({ country, reason, data }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/vat_registration/order?country_id=${country}&reason_for_registration_id=${reason}`,
        "POST",
        data,
        {
          contentType: "multipart/form-data",
        }
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(parseServerError(error));
    }
  }
);

export const sendRegOrderData = createAsyncThunk(
  "registration/sendRegOrderData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/vat_registration/answer", "POST", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(parseServerError(error));
    }
  }
);

const initialState = {
  reasons: [],
  regNumbers: [],
  regQuestions: [],
  regCountries: [],
  germanyStates: [],
  vatCountryStates: [],
  addRegNumber: null,
  blocks: null,
  notCompletedOrders: [],
};

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReasons.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchReasons.fulfilled, (state, action) => {
        state.fetching = false;
        state.fetchingError = null;
        state.reasons = action.payload;
      })
      .addCase(fetchReasons.rejected, (state, action) => {
        state.fetching = false;
        state.reasons = [];
        state.fetchingError = parseServerError(action.error);
      });

    builder
      .addCase(fetchRegNumbers.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchRegNumbers.fulfilled, (state, action) => {
        state.fetching = false;
        state.fetchingError = null;
        state.regNumbers = action.payload;
      })
      .addCase(fetchRegNumbers.rejected, (state, action) => {
        state.fetching = false;
        state.regNumbers = [];
        state.fetchingError = parseServerError(action.error);
      });

    builder
      .addCase(fetchRegQuestions.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchRegQuestions.fulfilled, (state, action) => {
        state.fetching = false;
        state.fetchingError = null;
        state.regQuestions = action.payload;
        state.blocks = action.payload;
      })
      .addCase(fetchRegQuestions.rejected, (state, action) => {
        state.fetching = false;
        state.regQuestions = [];
        state.fetchingError = parseServerError(action.error);
      });

    builder
      .addCase(fetchRegCountries.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchRegCountries.fulfilled, (state, action) => {
        state.fetching = false;
        state.fetchingError = null;
        state.regCountries = action.payload;
      })
      .addCase(fetchRegCountries.rejected, (state, action) => {
        state.fetching = false;
        state.regCountries = [];
        state.fetchingError = parseServerError(action.error);
      });

    builder
      .addCase(fetchGermanyStates.pending, (state) => {
        state.fetchingStates = true;
      })
      .addCase(fetchGermanyStates.fulfilled, (state, action) => {
        state.fetchingStates = false;
        state.fetchingStatesError = null;
        state.germanyStates = action.payload;
      })
      .addCase(fetchGermanyStates.rejected, (state, action) => {
        state.fetchingStates = false;
        state.germanyStates = [];
        state.fetchingStatesError = parseServerError(action.error);
      });

    builder
      .addCase(addRegNumber.pending, (state) => {
        state.adding = true;
        state.addingError = null;
      })
      .addCase(addRegNumber.fulfilled, (state, action) => {
        state.adding = false;
        state.addingError = null;
        state.addRegNumber = { ...action.payload };
      })
      .addCase(addRegNumber.rejected, (state, action) => {
        state.adding = false;
        state.addingError = parseServerError(action.error);
      });

    builder
      .addCase(editRegNumber.pending, (state) => {
        state.editing = true;
        state.editingError = null;
      })
      .addCase(editRegNumber.fulfilled, (state, action) => {
        state.editing = false;
        state.editingError = null;
      })
      .addCase(editRegNumber.rejected, (state, action) => {
        state.editing = false;
        state.editingError = parseServerError(action.error);
      });

    builder
      .addCase(deleteRegOrder.pending, (state) => {
        state.deleting = true;
        state.deletingError = null;
      })
      .addCase(deleteRegOrder.fulfilled, (state, action) => {
        state.deleting = false;
        state.deletingError = null;
      })
      .addCase(deleteRegOrder.rejected, (state, action) => {
        state.deleting = false;
        state.deletingError = parseServerError(action.error);
      });

    builder
      .addCase(orderRegNumber.pending, (state) => {
        state.ordering = true;
        state.orderingError = null;
      })
      .addCase(orderRegNumber.fulfilled, (state, action) => {
        state.ordering = false;
        state.orderingError = null;
        state.orderRegNumber = { ...action.payload };
      })
      .addCase(orderRegNumber.rejected, (state, action) => {
        state.ordering = false;
        state.orderingError = parseServerError(action.error);
      });

    builder
      .addCase(sendRegOrderData.pending, (state) => {
        state.sending = true;
        state.sendingError = null;
      })
      .addCase(sendRegOrderData.fulfilled, (state, action) => {
        state.sending = false;
        state.sendingError = null;
        state.sendRegOrderData = { ...action.payload };
      })
      .addCase(sendRegOrderData.rejected, (state, action) => {
        state.sending = false;
        state.sendingError = parseServerError(action.error);
      });

    builder
      .addCase(getAllNotCompletedOrders.pending, (state) => {
        state.getting = true;
        state.gettingError = null;
      })
      .addCase(getAllNotCompletedOrders.fulfilled, (state, action) => {
        state.getting = false;
        state.gettingError = null;
        state.notCompletedOrders = action.payload;
      })
      .addCase(getAllNotCompletedOrders.rejected, (state, action) => {
        state.getting = false;
        state.gettingError = parseServerError(action.error);
      });

    builder
      .addCase(fetchVatCountryStates.pending, (state) => {
        state.fetchingVatCountryStates = true;
      })
      .addCase(fetchVatCountryStates.fulfilled, (state, action) => {
        state.fetchingVatCountryStates = false;
        state.fetchingVatCountryStatesError = null;
        state.vatCountryStates = action.payload;
      })
      .addCase(fetchVatCountryStates.rejected, (state, action) => {
        state.fetchingVatCountryStates = false;
        state.fetchingVatCountryStatesError = parseServerError(action.error);
      });
  },
});

export default registrationSlice.reducer;
