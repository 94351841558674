import React from "react";
import { formatMoney } from "utils/numbers";
import formatRow from "utils/formatRow";
import { Card, DataTable, Text } from "@shopify/polaris";
import _ from "lodash";
import { getCurrencySymbol } from "utils/currency";
import { t } from "i18next";

const FurniturePreviewSimplified = (props) => {
  const { data, tableProps } = props;

  const columns = [
    {
      property: "revenue",
      header: {
        label: t("eprReporting.revenue"),
      },
      cell: {
        formatters: [(value) => formatMoney(value) + getCurrencySymbol("eur")],
      },
    },
    {
      property: "tax",
      header: {
        label: t("eprReporting.tax"),
      },
      cell: {
        formatters: [(value) => `${value}%`],
      },
    },
    {
      property: "fee",
      header: {
        label: t("eprReporting.fee"),
      },
      cell: {
        formatters: [(value) => formatMoney(value) + getCurrencySymbol("eur")],
      },
    },
  ];

  const sortedRows = !_.isEmpty(data) ? formatRow([data], columns) : [];

  return (
    <Card>
      <DataTable
        columnContentTypes={["text", "text", "numeric"]}
        headings={columns.map(({ header }) => {
          return <Text fontWeight='semibold'>{header.label}</Text>;
        })}
        rows={sortedRows}
        hideScrollIndicator
        increasedTableDensity
        showTotalsInFooter
        {...tableProps}
      />
    </Card>
  );
};

export default FurniturePreviewSimplified;
