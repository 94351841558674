import _ from "lodash";
import { email } from "utils/validation";
import moment from "moment";

const noValidate = _.noop;

const createSchemas = ({ t }) => {
  const emptyFieldErrorString = t("dac7.pleaseFillThisField");

  const validateText = (value) => (!value ? emptyFieldErrorString : false);

  const validateEmail = (value) =>
    !value ? emptyFieldErrorString : email(value);

  const naturalPersonSchema = [
    {
      type: "input",
      props: {
        id: "merchant_name",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.merchantName"),
        validate: validateText,
      },
    },
    {
      type: "select",
      props: {
        id: "user_status",
        placeholder: t("dac7.userStatus"),
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "tin",
        type: "text",
        infoTooltipText: "Tax identification number",
        inputMode: "text",
        placeholder: t("dac7.tin"),
        validate: validateText,
      },
    },
    {
      type: "select",
      props: {
        id: "tin_issued_by",
        placeholder: t("dac7.tinIssuedByOptional"),
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "email",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.email"),
        validate: validateEmail,
      },
    },
    {
      type: "input",
      props: {
        id: "phone_number",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.telephoneNumber"),
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "iban",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.merchantsIbanOptional"),
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "bic",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.bicOptional"),
        validate: noValidate,
      },
    },
    {
      type: "select",
      props: {
        id: "country",
        placeholder: t("dac7.country"),
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "city",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.city"),
        validate: validateText,
      },
    },
    {
      type: "input",
      props: {
        id: "address_line",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.addressLine"),
        validate: validateText,
      },
    },
    {
      type: "input",
      props: {
        id: "zip",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.zip"),
        validate: validateText,
      },
    },
    {
      type: "datepicker",
      props: {
        id: "date_birth",
        type: "text",
        placeholder: t("dac7.birthDate"),
        customFormat: (date) => moment(date).format("yyyy-MM-DD"),
        validate: noValidate,
        datepickerProps: {
          showYearDropdown: true,
          yearDropdownItemNumber: 80,
          scrollableYearDropdown: true,
          maxDate: Date.now(),
        },
      },
    },
    {
      type: "input",
      props: {
        id: "tax_number",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.taxNumber"),
        validate: noValidate,
      },
    },
  ];

  const merchantIdInput = {
    type: "input",
    props: {
      id: "merchant_id",
      type: "text",
      inputMode: "text",
      placeholder: t("dac7.merchantId"),
      validate: validateText,
    },
  };

  const legalEntitySchema = [
    {
      type: "input",
      props: {
        id: "merchant_name",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.merchantBusinessName"),
        validate: validateText,
      },
    },
    {
      type: "input",
      props: {
        id: "commercial_name",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.commercialNameOrUsernameOnThePlatform"),
        validate: validateText,
      },
    },
    {
      type: "input",
      props: {
        id: "tin",
        type: "text",
        infoTooltipText: "Tax identification number",
        inputMode: "text",
        placeholder: t("dac7.tin"),
        validate: validateText,
      },
    },
    {
      type: "select",
      props: {
        id: "tin_issued_by",
        placeholder: t("dac7.tinIssuedByOptional"),
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "email",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.email"),
        validate: validateEmail,
      },
    },
    {
      type: "input",
      props: {
        id: "phone_number",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.telephoneNumber"),
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "tax_number",
        type: "text",
        inputMode: "numeric",
        placeholder: t("dac7.taxNumber"),
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "iban",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.merchantsIbanOptional"),
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "bic",
        type: "text",
        inputMode: "numeric",
        placeholder: t("dac7.bicOptional"),
        validate: noValidate,
      },
    },
    {
      type: "select",
      props: {
        id: "country",
        placeholder: t("dac7.country"),
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "city",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.city"),
        validate: validateText,
      },
    },
    {
      type: "input",
      props: {
        id: "address_line",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.addressLine"),
        validate: validateText,
      },
    },
    {
      type: "input",
      props: {
        id: "zip",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.zip"),
        validate: validateText,
      },
    },
    {
      type: "input",
      props: {
        id: "url",
        type: "text",
        inputMode: "text",
        placeholder: "URL",
        validate: validateText,
      },
    },
  ];

  const relevantActivitySchema = [
    {
      type: "input",
      props: {
        id: "total_gross_amount_q",
        type: "number",
        inputMode: "numeric",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "gross_amount_of_transactions_q",
        type: "number",
        inputMode: "numeric",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "total_number_of_transactions_q",
        type: "number",
        inputMode: "numeric",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "related_number_of_transactions_q",
        type: "number",
        inputMode: "numeric",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "total_amount_of_commissions_q",
        type: "number",
        inputMode: "numeric",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "vat_withheld_q",
        type: "number",
        inputMode: "numeric",
        validate: noValidate,
      },
    },
  ];

  const relevantActivityImmovableSchema = [
    {
      type: "input",
      props: {
        id: "total_gross_amount_q",
        type: "number",
        inputMode: "numeric",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "total_number_of_transactions_q",
        type: "number",
        inputMode: "numeric",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "total_amount_of_commissions_q",
        type: "number",
        inputMode: "numeric",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "vat_withheld_q",
        type: "number",
        inputMode: "numeric",
        validate: noValidate,
      },
    },
  ];

  const tableSchema = [
    {
      property: "merchant_name",
      header: {
        label: t("dac7.name"),
      },
    },
    {
      property: "legal_status",
      header: {
        label: t("dac7.legalStatus"),
      },
    },
    {
      property: "total_gross_amount",
      header: {
        label: t("dac7.totalGrossAmountOfTransactions"),
      },
    },
    {
      property: "total_number_of_transactions",
      header: {
        label: t("dac7.numberOfTransactions"),
      },
    },
  ];

  const immovablePropertyAdditionalInfoSchema = [
    {
      type: "select",
      props: {
        id: "property_country",
        placeholder: t("dac7.country"),
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "property_address",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.propertyAddress"),
        validate: validateText,
      },
    },
    {
      type: "input",
      props: {
        id: "land_registration_number",
        type: "text",
        inputMode: "text",
        placeholder: t("dac7.landRegistrationNumber"),
        validate: validateText,
      },
    },
    {
      type: "select",
      props: {
        id: "property_type",
        placeholder: t("dac7.propertyType"),
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "number_of_rented_days",
        type: "number",
        inputMode: "text",
        placeholder: t("dac7.numberOfRentedDays"),
        validate: validateText,
      },
    },
  ];

  return {
    legalEntitySchema,
    naturalPersonSchema,
    relevantActivitySchema,
    relevantActivityImmovableSchema,
    immovablePropertyAdditionalInfoSchema,
    merchantIdInput,
    tableSchema,
  };
};

export { createSchemas };
