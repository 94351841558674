import { Button, Modal } from '@shopify/polaris'
import { t } from 'i18next'
import backArrow from 'img/arrow-left.svg'
import nextArrow from 'img/arrow-right-white.svg'
import React from 'react'
import CancelSubscriptionOne from './CancelSubscriptionOne'
import CancelSubscriptionStepThree from './CancelSubscriptionStepThree'
import CancelSubscriptionStepTwo from './CancelSubscriptionStepTwo'

const CancelSubscriptionModalSteps = ({
	visible,
	onClose,
	countSteps,
	nextStep,
	prevStep,
	changeQuestion,
	saveChangeQuestion,
	writeYourReasonValue,
	subsList,
	subsForDel,
	saveSetSubsForDel,
	subsForDelAddons,
	saveSetSubsForDelAddons,
	cancelSubscriptionHandler,
	deletingPlan
}) => {
	const progressWidth = `${(countSteps / 3) * 100}%`
	return (
		<Modal
			title={<div style={{fontWeight: 'bold'}}>{`STEP ${countSteps} / 3`}</div >}
			open={visible}
			onClose={onClose}
			small={true}
			noScroll={true}
			footer={
				<div className='cancelSubscriptionBuilder-wrapperButtons'>
						<div className='cancelSubscriptionBuilder-button_account'>
							<Button
								onClick={onClose}
								size='large'
								outline={'false'}
								plain
								icon={
									<img
										style={{marginRight: 10}}
										src={backArrow}
										alt='back arrow'
									/>
								}
							>
								<span style={{color: '#637381'}}>My Account</span >
							</Button >
						</div >
						<div className='cancelSubscriptionBuilder-wrapperButtons_block'>
							<Button
								className='cancelSubscriptionBuilder-button cancelSubscriptionBuilder-button_back'
								onClick={prevStep}
								disabled={countSteps === 1}
								size='large'
							>
	              {t("orderReg.back")}
						</Button >
							{
								countSteps !== 3
									?
									<Button
										className='cancelSubscriptionBuilder-button'
										primary
										onClick={nextStep}
										size='large'
										disabled={subsForDel.length === 0 && subsForDelAddons.length === 0}
									>
									  <div style={{display: "flex", alignItems: "center"}}>
										   {t("orderReg.next")}
										  <img
											  style={{marginLeft: 10}}
											  src={nextArrow}
											  alt='next arrow'
										  />
									  </div >
									</Button >
									:
									<Button
										className='cancelSubscriptionBuilder-button'
										primary
										onClick={cancelSubscriptionHandler}
										size='large'
										loading={deletingPlan}
									>
			            {t("orderReg.steps.submit")}
								</Button >
							}
						</div >
	        </div >
			}
		>
	      <div className='cancelSubscriptionBuilder-progress_bar'>
				  <div
					  className='cancelSubscriptionBuilder-progress_bar-progress_line'
					  style={{width: progressWidth}}
				  />
				</div >
			{
				countSteps === 1
				&&
				<CancelSubscriptionOne
					subsList={subsList}
					subsForDel={subsForDel}
					saveSetSubsForDel={saveSetSubsForDel}
					subsForDelAddons={subsForDelAddons}
					saveSetSubsForDelAddons={saveSetSubsForDelAddons}
				/>
			}
			{
				countSteps === 2
				&&
				<CancelSubscriptionStepTwo
					changeQuestion={changeQuestion}
					saveChangeQuestion={saveChangeQuestion}
					writeYourReasonValue={writeYourReasonValue}
				/>
			}
			{
				countSteps === 3
				&&
				<CancelSubscriptionStepThree
					subsList={subsList}
					subsForDel={subsForDel}
					subsForDelAddons={subsForDelAddons}
				/>
			}
		</Modal >
	)
}

export default CancelSubscriptionModalSteps
