import { Button, ButtonGroup } from "@shopify/polaris";
import Modal from "components/Modal";
import useAppDispatch from "hooks/useAppDispatch";
import React from "react";
import { useSelector } from "react-redux";
import { deleteVatInvoice } from "redux/features/documents/documentsSlice";

const DeleteInvoice = (props) => {
  const dispatch = useAppDispatch();
  const { deleteVatInvoice: loading } = useSelector((state) => state.documents);
  const { data, onClose, afterDelete, isOpen } = props;

  const onDelete = () => {
    dispatch(deleteVatInvoice(data)).then(() => {
      afterDelete();
      onClose();
    });
  };

  return (
    <Modal
      title={"Delete VAT Invoice draft?"}
      contentOnCenter
      iconType={"danger"}
      description={"Are you sure you want to delete this VAT Invoice draft?"}
      visible={isOpen}
      onClose={onClose}
      footer={
        <ButtonGroup fullWidth>
          <Button size='large' onClick={onClose}>
            Cancel
          </Button>
          <Button destructive size='large' onClick={onDelete} loading={loading}>
            Delete
          </Button>
        </ButtonGroup>
      }
    />
  );
};

export default DeleteInvoice;
