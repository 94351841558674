import React, { useCallback, useEffect, useState } from 'react';

const useNotifications = (initKeys) => {
  const [notifications, setNotifications] = useState({});

  useEffect(() => {
    // initialization of state by provided keys
    const newNotifications = {};
    initKeys.forEach((key) => {
      newNotifications[key] = false;
    });
    setNotifications(newNotifications);
  }, []);

  const onChange = useCallback((key, checked) => {
    setNotifications((state) => {
      const items =
        typeof key === 'string'
          ? {
              [key]: checked,
            }
          : key;

      return {
        ...state,
        ...items,
      };
    });
  }, []);

  return [notifications, onChange];
};

export default useNotifications;
