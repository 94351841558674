const AddIcon = () => (<svg width="20" height="20" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
  <path fill="none" d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>);

const AlertIcon = ({ stroke }) => (
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5996 7V11M11.5996 15H11.6096M21.5996 11C21.5996 16.5228 17.1225 21 11.5996 21C6.07676 21 1.59961 16.5228 1.59961 11C1.59961 5.47715 6.07676 1 11.5996 1C17.1225 1 21.5996 5.47715 21.5996 11Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export {
  AddIcon,
  AlertIcon
};
