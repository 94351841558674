/**
 * Precise rounding of a number with a specified number of significant digits.
 *
 * @param {number} v - The number to be rounded.
 * @param {number} n - The number of significant digits (default is 2).
 * @param {string} type - The rounding type: 'round' (default), 'ceil', or 'floor'.
 * @returns {number} - The rounded number.
 */
export function preciseRound(v, n = 2, type = "round") {
  // If the number of significant digits is not provided or is 0, perform regular rounding.
  if (typeof n === "undefined" || +n === 0) {
    return Math[type](v);
  }

  let val = +v;
  const num = +n;

  // Convert the number to a string in exponential notation.
  val = val.toString().split("e");

  // Apply rounding to the number with the specified significant digits.
  val = Math[type](+(val[0] + "e" + (val[1] ? +val[1] + num : +num)));

  // Convert the result back to a string and handle exponential notation.
  val = val.toString().split("e");

  // Return the final rounded number.
  return Number(val[0] + "e" + (val[1] ? +val[1] - num : -num));
}

/**
 * Format a number as a money string with two decimal places and thousands separator.
 *
 * @param {number} v - The number to be formatted as money.
 * @returns {string} - The formatted money string.
 */
export function formatMoney(v) {
  return parseFloat(v)
    .toFixed(2) // Ensure two decimal places.
    .replace(/(\d)(?=(\d{3})+\.)/g, "$1 ") // Add a space as a thousands separator.
    .replace(",", "."); // Replace comma with a dot as a decimal separator.
}
