import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";

export const fetchTaxListCompany = createAsyncThunk(
  "tax/fetchTaxListCompany",
  async () => {
    const response = await callApi("/tax_agent/tax_agent_list_company", "GET");
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch tax list company");
    }
  }
);

export const addNewClient = createAsyncThunk(
  "tax/addNewClient",
  async ({
    companyName,
    postcode,
    country,
    city,
    street,
    housenum,
    apartment,
    phone,
    vatNum,
    name,
    surname,
    persons,
    data,
  }) => {
    const response = await callApi(
      `/tax_agent/add_new_client?city=${city}&company_name=${companyName}&country=${country}&director_name=${name}&director_surname=${surname}&housenum=${housenum}&postcode=${postcode}&street=${street}&tax_agent_users=${persons}&apartment=${apartment}&phone=${phone}&vat_number=${vatNum}`,
      "POST",
      data,
      { contentType: "multipart/form-data" }
    );
    if (response.ok) {
      const jsonData = await response.json();
      return jsonData;
    } else {
      throw new Error("Failed to add new client");
    }
  }
);

export const deleteClient = createAsyncThunk("tax/deleteClient", async (id) => {
  const response = await callApi(
    `/tax_agent/delete_client_in_tax_portal?company_id=${id}`,
    "POST"
  );
  if (response.ok) {
    const jsonData = await response.json();
    return jsonData;
  } else {
    throw new Error("Failed to delete client");
  }
});

export const fetchBonusProgram = createAsyncThunk(
  "tax/fetchBonusProgram",
  async () => {
    const response = await callApi("/tax_agent/get_partner_program", "GET");
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch bonus program");
    }
  }
);

const initialState = {
  taxListCompany: {},
  bonusProgram: {},
  fetchingTaxListCompany: false,
  fetchingTaxListCompanyError: null,
  adding: false,
  addingError: null,
  deleting: false,
  deletingError: null,
  fetchingBonus: false,
  fetchingBonusError: null,
};

const taxSlice = createSlice({
  name: "tax",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxListCompany.pending, (state) => {
        state.fetchingTaxListCompany = true;
      })
      .addCase(fetchTaxListCompany.fulfilled, (state, action) => {
        state.fetchingTaxListCompany = false;
        state.fetchingTaxListCompanyError = null;
        state.taxListCompany = action.payload;
      })
      .addCase(fetchTaxListCompany.rejected, (state, action) => {
        state.fetchingTaxListCompany = false;
        state.fetchingTaxListCompanyError = action.error.message;
      })
      .addCase(addNewClient.pending, (state) => {
        state.adding = true;
        state.addingError = null;
      })
      .addCase(addNewClient.fulfilled, (state) => {
        state.adding = false;
        state.addingError = null;
      })
      .addCase(addNewClient.rejected, (state, action) => {
        state.adding = false;
        state.addingError = action.error.message;
      })
      .addCase(deleteClient.pending, (state) => {
        state.deleting = true;
        state.deletingError = null;
      })
      .addCase(deleteClient.fulfilled, (state) => {
        state.deleting = false;
        state.deletingError = null;
      })
      .addCase(deleteClient.rejected, (state, action) => {
        state.deleting = false;
        state.deletingError = action.error.message;
      })
      .addCase(fetchBonusProgram.pending, (state) => {
        state.fetchingBonus = true;
      })
      .addCase(fetchBonusProgram.fulfilled, (state, action) => {
        state.bonusProgram = action.payload;
        state.fetchingBonus = false;
        state.fetchingBonusError = null;
      })
      .addCase(fetchBonusProgram.rejected, (state, action) => {
        state.fetchingBonus = false;
        state.fetchingBonusError = action.error.message;
      });
  },
});

export default taxSlice.reducer;
