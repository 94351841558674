import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import { parseServerError } from "utils/errors";

export const fetchCountries = createAsyncThunk(
  "other/fetchCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/countries", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchAllCountries = createAsyncThunk(
  "other/fetchAllCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/countries_all", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchCountriesVatReg = createAsyncThunk(
  "other/fetchCountriesVatReg",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/vat_registration_countries", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchCountriesEU = createAsyncThunk(
  "other/fetchCountriesEU",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/countries_EU", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchCurrency = createAsyncThunk(
  "other/fetchCurrency",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/currency", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchServices = createAsyncThunk(
  "other/fetchServices",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/services", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchTypesServicesPvd = createAsyncThunk(
  "other/fetchTypesServicesPvd",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/company/type_services_provided", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchServicesPvd = createAsyncThunk(
  "other/fetchServicesPvd",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/company/services_provided", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createServiceProvided = createAsyncThunk(
  "other/createServiceProvided",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/company/services_provided",
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteServiceProvided = createAsyncThunk(
  "other/deleteServiceProvided",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/company/services_provided?id=${id}`,
        "DELETE"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const editServiceProvided = createAsyncThunk(
  "other/editServiceProvided",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/company/services_provided", "PUT", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchCountriesEuAndUk = createAsyncThunk(
  "other/fetchCountriesEuAndUk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/get_eu_and_uk", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteCompany = createAsyncThunk(
  "other/deleteCompany",
  async (password, { rejectWithValue }) => {
    try {
      const response = await callApi(`/company?password=${password}`, "DELETE");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchCountriesAll = createAsyncThunk(
  "other/fetchCountriesAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/countries_all", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchPaymentButtonLayout = createAsyncThunk(
  "other/fetchPaymentButtonLayout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/payment_button_layout", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchPaymentOperators = createAsyncThunk(
  "other/fetchPaymentOperators",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/payment_operators", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const editBillingContact = createAsyncThunk(
  "other/editBillingContact",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/company/billing_contact", "POST", data);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

const otherSlice = createSlice({
  name: "other",
  initialState: {
    countries: [],
    countriesAll: [],
    countriesEU: [],
    countries_eu_and_uk: [],
    countriesVatReg: [],
    currency: [],
    paymentOperators: [],
    paymentButton: [],
    services: [],
    tariffs: [],
    timezones: [],
    typesServicesPvd: [],
    servicesPvd: [],
    deletingCompany: false,
    errorDeletingCompany: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.fetchingCountries = true;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.fetchingCountries = false;
        state.countries = action.payload;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.fetchingCountries = false;
        state.countries = [];
        state.fetchingCountriesError = parseServerError(action.error);
      })
      .addCase(fetchAllCountries.pending, (state) => {
        state.fetchingCountries = true;
      })
      .addCase(fetchAllCountries.fulfilled, (state, action) => {
        state.fetchingCountries = false;
        state.countriesAll = action.payload;
      })
      .addCase(fetchAllCountries.rejected, (state, action) => {
        state.fetchingCountries = false;
        state.countriesAll = [];
        state.fetchingCountriesError = parseServerError(action.error);
      })
      .addCase(fetchCountriesEU.pending, (state) => {
        state.fetchingCountriesEU = true;
      })
      .addCase(fetchCountriesEU.fulfilled, (state, action) => {
        state.fetchingCountriesEU = false;
        state.countriesEU = action.payload;
      })
      .addCase(fetchCountriesEU.rejected, (state, action) => {
        state.fetchingCountriesEU = false;
        state.countriesEU = [];
        state.fetchingCountriesEUError = parseServerError(action.error);
      })
      .addCase(fetchCountriesAll.pending, (state) => {
        state.fetchingCountriesAll = true;
      })
      .addCase(fetchCountriesAll.fulfilled, (state, action) => {
        state.fetchingCountriesAll = false;
        state.countriesAll = action.payload;
      })
      .addCase(fetchCountriesAll.rejected, (state, action) => {
        state.fetchingCountriesAll = false;
        state.countriesAll = [];
        state.fetchingCountriesAllError = parseServerError(action.error);
      })
      .addCase(fetchCountriesVatReg.pending, (state) => {
        state.fetchingCountriesVATReg = true;
      })
      .addCase(fetchCountriesVatReg.fulfilled, (state, action) => {
        state.fetchingCountriesVATReg = false;
        state.countriesVatReg = action.payload;
      })
      .addCase(fetchCountriesVatReg.rejected, (state, action) => {
        state.fetchingCountriesVATReg = false;
        state.countriesVatReg = [];
        state.fetchingCountriesVATRegError = parseServerError(action.error);
      })
      .addCase(fetchCurrency.pending, (state) => {
        state.fetchingCurrency = true;
      })
      .addCase(fetchCurrency.fulfilled, (state, action) => {
        state.fetchingCurrency = false;
        state.currency = action.payload;
      })
      .addCase(fetchCurrency.rejected, (state, action) => {
        state.fetchingCurrency = false;
        state.currency = [];
        state.fetchingCurrencyError = parseServerError(action.error);
      })
      .addCase(fetchPaymentOperators.pending, (state) => {
        state.fetchingPaymentOperators = true;
      })
      .addCase(fetchPaymentOperators.fulfilled, (state, action) => {
        state.fetchingPaymentOperators = false;
        state.paymentOperators = action.payload;
      })
      .addCase(fetchPaymentOperators.rejected, (state, action) => {
        state.fetchingPaymentOperators = false;
        state.paymentOperators = [];
        state.fetchingPaymentOperatorsError = parseServerError(action.error);
      })
      .addCase(fetchPaymentButtonLayout.pending, (state) => {
        state.fetchingButtonLayout = true;
      })
      .addCase(fetchPaymentButtonLayout.fulfilled, (state, action) => {
        state.fetchingButtonLayout = false;
        state.paymentButton = action.payload;
      })
      .addCase(fetchPaymentButtonLayout.rejected, (state, action) => {
        state.fetchingButtonLayout = false;
        state.paymentButton = [];
        state.fetchingButtonLayoutError = parseServerError(action.error);
      })
      .addCase(fetchServices.pending, (state) => {
        state.fetchingServices = true;
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.fetchingServices = false;
        state.services = action.payload;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.fetchingServices = false;
        state.services = [];
        state.fetchingServicesError = parseServerError(action.error);
      })
      .addCase(fetchTypesServicesPvd.pending, (state) => {
        state.fetchingTypesServicesPvd = true;
      })
      .addCase(fetchTypesServicesPvd.fulfilled, (state, action) => {
        state.fetchingTypesServicesPvd = false;
        state.typesServicesPvd = action.payload;
      })
      .addCase(fetchTypesServicesPvd.rejected, (state, action) => {
        state.fetchingTypesServicesPvd = false;
        state.typesServicesPvd = [];
        state.fetchingTypesServicesPvdError = parseServerError(action.error);
      })
      .addCase(fetchServicesPvd.pending, (state) => {
        state.fetchingServicesPvd = true;
      })
      .addCase(fetchServicesPvd.fulfilled, (state, action) => {
        state.fetchingServicesPvd = false;
        state.servicesPvd = action.payload;
      })
      .addCase(fetchServicesPvd.rejected, (state, action) => {
        state.fetchingServicesPvd = false;
        state.servicesPvd = [];
        state.fetchingServicesPvdError = parseServerError(action.error);
      })
      .addCase(createServiceProvided.pending, (state) => {
        state.creatingServicePvd = true;
      })
      .addCase(createServiceProvided.fulfilled, (state) => {
        state.creatingServicePvd = false;
        state.creatingServicePvdError = null;
      })
      .addCase(createServiceProvided.rejected, (state, action) => {
        state.creatingServicePvd = false;
        state.creatingServicePvdError = parseServerError(action.error);
      })
      .addCase(deleteServiceProvided.pending, (state) => {
        state.deletingServicePvd = true;
      })
      .addCase(deleteServiceProvided.fulfilled, (state) => {
        state.deletingServicePvd = false;
        state.deletingServicePvdError = null;
      })
      .addCase(deleteServiceProvided.rejected, (state, action) => {
        state.deletingServicePvd = false;
        state.deletingServicePvdError = parseServerError(action.error);
      })
      .addCase(editServiceProvided.pending, (state) => {
        state.editingServicePvd = true;
      })
      .addCase(editServiceProvided.fulfilled, (state) => {
        state.editingServicePvd = false;
        state.editingServicePvdError = null;
      })
      .addCase(editServiceProvided.rejected, (state, action) => {
        state.editingServicePvd = false;
        state.editingServicePvdError = parseServerError(action.error);
      })
      .addCase(fetchCountriesEuAndUk.pending, (state) => {
        state.fetchingCountriesEuAndUk = true;
      })
      .addCase(fetchCountriesEuAndUk.fulfilled, (state, action) => {
        state.fetchingCountriesEuAndUk = false;
        state.countries_eu_and_uk = action.payload;
      })
      .addCase(fetchCountriesEuAndUk.rejected, (state, action) => {
        state.fetchingCountriesEuAndUk = false;
        state.countries_eu_and_uk = [];
        state.fetchingCountriesEuAndUkError = parseServerError(action.error);
      })
      .addCase(deleteCompany.pending, (state) => {
        state.deletingCompany = true;
      })
      .addCase(deleteCompany.fulfilled, (state) => {
        state.deletingCompany = false;
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.deletingCompany = false;
        state.errorDeletingCompany = parseServerError(
          action.error || action.payload.error
        );
      })
      .addCase(editBillingContact.pending, (state) => {
        state.editBillingContact = true;
        state.ededitBillingContactError = null;
      })
      .addCase(editBillingContact.fulfilled, (state) => {
        state.editBillingContact = false;
        state.ededitBillingContactError = null;
      })
      .addCase(editBillingContact.rejected, (state, action) => {
        state.editBillingContact = false;
        state.ededitBillingContactError = parseServerError(action.error);
      });
  },
});

export default otherSlice.reducer;
