import React, { Component } from "react";
import {
  TextField,
  FormLayout,
  Select,
  Button,
  TextStyle,
  TextContainer,
  Heading,
  Banner,
  Inline,
  Text,
} from "@shopify/polaris";
import PageHelmet from "components/PageHelmet";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import ReCAPTCHA from "components/ReCAPTCHA";
import { activateResend, registerUser } from "redux/features/user/userSlice";
import { fetchAllCountries } from "redux/features/other/otherSlice";
import { isEmail, isLatin } from "utils/validation";
import fetchDefaultCountry from "utils/defaultCountry";
import moment from "moment";
import _ from "lodash";
import config from "config";
import AuthContainer from "../../AuthContainer";

import emailIMG from "img/email.svg";
import personIMG from "img/person.svg";
import suitcaseIMG from "img/suitcase.svg";
import { createCustomDispatch } from "helpers/customDispatch";

let recaptchaResponse = undefined;

function submitRecaptcha(response) {
  recaptchaResponse = response;
}
function expiredRecaptcha() {
  recaptchaResponse = undefined;
}
// функции вызываемые reCaptcha должны быть глобальными
window.submitRecaptcha = submitRecaptcha;
window.expiredRecaptcha = expiredRecaptcha;

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      errorEmail: false,
      surname: "",
      errorSurname: false,
      companyName: "",
      erorrCompanyName: false,
      errorCountry: false,
      defaultTimezone: moment.tz.guess(),
      countriesOptions: [],
      referral: "",
      country: "",
    };
  }

  componentDidMount() {
    this.props.fetchAllCountries();

    fetchDefaultCountry().then((defaultCountry) => {
      this.setState({ country: defaultCountry });
    });
  }

  componentDidUpdate() {
    const { rewardfulLoaded } = this.state;
    if (window.rewardful && !rewardfulLoaded) {
      this.doRewardfullReady();
    }
  }

  doRewardfullReady() {
    window.rewardful("ready", () => {
      this.setState({
        rewardfulLoaded: true,
      });
      if (window.Rewardful.referral) {
        this.setState({
          referral: window.Rewardful.referral,
        });
      }
    });
  }

  doResendEmail = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.activateResend({ email: this.state.email });
  };

  doSignup = (event) => {
    event.preventDefault();
    const { email, name, surname, companyName, country, referral } = this.state;
    const { refererLang, t } = this.props;
    if (
      !email ||
      !name ||
      !surname ||
      !companyName ||
      !country ||
      !isLatin(name) ||
      !isLatin(surname) ||
      !isLatin(companyName)
    ) {
      const nameErr = isLatin(name)
        ? t("signUp.enterName")
        : "Use the Latin alphabet!";
      const surnameErr = isLatin(surname)
        ? t("signUp.enterSurname")
        : "Use the Latin alphabet!";
      const companyErr = isLatin(companyName)
        ? t("signUp.enterCompany")
        : "Use the Latin alphabet!";

      this.setState({
        errorEmail: email ? false : t("signUp.invalidEmail"),
        errorName: name && isLatin(name) ? false : nameErr,
        errorSurname: surname && isLatin(surname) ? false : surnameErr,
        errorCompanyName:
          companyName && isLatin(companyName) ? false : companyErr,
        errorCountry: country ? false : t("signUp.enterCountry"),
      });
      return;
    }
    if (recaptchaResponse) {
      const data = {
        email: email,
        first_name: name,
        last_name: surname,
        "g-recaptcha-response": recaptchaResponse,
        company_name: companyName,
        country: country,
        lang: refererLang,
        referral,
      };

      if (this.props.params.token) {
        data.token = this.props.params.token;
      }

      this.props
        .registerUser(data)
        .then(() => {
          this.setState({ email });
        })
        .catch(() => {
          this.setState({ errorEmail: t("signUp.connectErr") });
          /*this.refs.form.updateInputsWithError({
              password: this.props.registerError
            });*/
        });
    }
  };

  validateEmail = (email) => {
    const { t } = this.props;

    if (email.length > 0 && !isEmail(email)) {
      this.setState({
        errorEmail: t("signUp.invalidEmail"),
      });
    } else {
      this.setState({
        errorEmail: false,
      });
    }
  };

  handleChange = (value, id, errorName, errorText) => {
    if (value.length > 0 && isLatin(value)) {
      this.setState({
        [id]: value,
        [errorName]: false,
      });
    } else if (value.length > 0 && !isLatin(value)) {
      this.setState({
        [id]: value,
        [errorName]: "Use the Latin alphabet!",
      });
    } else {
      this.setState({
        [id]: "",
        [errorName]: errorText,
      });
    }
  };

  handleChangeCountry = (country) => {
    const { t } = this.props;

    if (country.length > 0) {
      this.setState({
        country,
        errorCountry: false,
      });
    } else {
      this.setState({
        country: "",
        errorCountry: t("signUp.enterCountry"),
      });
    }
  };

  renderSignUpForm = () => {
    const { countries, registering, t } = this.props;
    const {
      email,
      errorEmail,
      name,
      errorName,
      surname,
      errorSurname,
      companyName,
      errorCompanyName,
      errorCountry,
      referral,
    } = this.state;

    return (
      <form ref='form'>
        <FormLayout>
          <TextField
            name='email'
            type='email'
            prefix={<img src={emailIMG} alt='email' style={{ marginTop: 6 }} />}
            label={
              <Text color='subdued' variant='bodySm'>
                {t("signUp.email")}
              </Text>
            }
            placeholder={t("signUp.email")}
            autoComplete='email'
            value={email}
            error={errorEmail}
            onBlur={() => this.validateEmail(email)}
            onChange={(value) => {
              this.setState({ email: value, errorEmail: false });
            }}
          />

          <Select
            label={
              <Text color='subdued' variant='bodySm'>
                {t("signUp.country")}
              </Text>
            }
            placeholder={t("signUp.country")}
            error={errorCountry}
            options={[
              {
                label: "",
                value: "",
              },
              ...countries.map((country) => ({
                label: country.name_no_article,
                value: country.code,
              })),
            ]}
            onChange={this.handleChangeCountry}
            value={this.state.country}
          />

          <FormLayout.Group condensed>
            <TextField
              id='name'
              type='text'
              prefix={
                <img src={personIMG} alt='name' style={{ marginTop: 6 }} />
              }
              label={
                <Text color='subdued' variant='bodySm'>
                  {t("signUp.name")}
                </Text>
              }
              placeholder={t("signUp.name")}
              value={name}
              error={errorName}
              onChange={(value, id) =>
                this.handleChange(value, id, "errorName", t("signUp.enterName"))
              }
            />
            <TextField
              id='surname'
              type='text'
              prefix={
                <img src={personIMG} alt='surname' style={{ marginTop: 6 }} />
              }
              label={
                <Text color='subdued' variant='bodySm'>
                  {t("signUp.surname")}
                </Text>
              }
              placeholder={t("signUp.surname")}
              value={surname}
              error={errorSurname}
              onChange={(value, id) =>
                this.handleChange(
                  value,
                  id,
                  "errorSurname",
                  t("signUp.enterSurname")
                )
              }
            />
          </FormLayout.Group>

          <TextField
            id='companyName'
            type='text'
            prefix={
              <img
                src={suitcaseIMG}
                alt='company name'
                style={{ marginTop: 6 }}
              />
            }
            label={
              <Text color='subdued' variant='bodySm'>
                {t("signUp.companyName")}
              </Text>
            }
            placeholder={t("signUp.companyName")}
            value={companyName}
            error={errorCompanyName}
            onChange={(value, id) =>
              this.handleChange(
                value,
                id,
                "errorCompanyName",
                t("signUp.enterCompany")
              )
            }
          />

          <br />

          <input type='hidden' name='referral' value={referral} />

          <ReCAPTCHA
            ref='recaptcha'
            sitekey={config.recaptcha.sitekey}
            onChange={submitRecaptcha}
            onExpired={expiredRecaptcha}
          />
          {this.props.registerError && (
            <TextStyle variation='negative'>
              {this.props.registerError}
            </TextStyle>
          )}
          <Button
            disabled={registering}
            loading={registering}
            primary
            onClick={this.doSignup}
          >
            {t("signUp.signUp")}
          </Button>
        </FormLayout>
      </form>
    );
  };

  renderSuccess = () => {
    const { sent, t } = this.props;
    return (
      <TextContainer>
        <Heading>{t("signUp.finishReg")}</Heading>
        <p>
          {t("signUp.weSent1")}{" "}
          <a href='' onClick={this.doResendEmail}>
            {t("signUp.weSent2")}
          </a>
          .
        </p>
        {sent && (
          <Banner
            title='New email has been successfully sent.'
            status='success'
          />
        )}
      </TextContainer>
    );
  };

  render() {
    const { registered, t } = this.props;
    return (
      <>
        <PageHelmet title={"Sign Up"} />
        <AuthContainer
          title='Sign Up'
          description={"Easily manage taxes in EU or USA"}
          content={registered ? this.renderSuccess() : this.renderSignUpForm()}
          secondaryContent={
            <Inline align='end' alignY='center' spacing='4'>
              <Text color='subdued'>Already have an account ?</Text>
              <Button onClick={() => this.props.navigate("/login")}>
                Log in
              </Button>
            </Inline>
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  registered: state.user.registered,
  registering: state.user.registering,
  registerError: state.user.registerError,
  resendError: state.user.resendError,
  countries: state.other.countriesAll,
  refererLang: state.user.refererLang,
  sent: state.user.sent,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    registerUser: (params) => dispatch(registerUser(params)),
    activateResend: (params) => dispatch(activateResend(params)),
    fetchAllCountries: () => dispatch(fetchAllCountries()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUp))
);
