import { unwrapResult } from "@reduxjs/toolkit";

export const createCustomDispatch = (dispatch) => {
  return async (actionCreator) => {
    try {
      const actionResult = await dispatch(actionCreator);
      const result = unwrapResult(actionResult);

      return result;
    } catch (error) {
      throw error;
    }
  };
};
