import React, { useRef, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { useSelector } from "react-redux";
import { createNewMessage } from "redux/features/messages/messagesSlice";
import { useNavigate } from "react-router-dom";
import {
  AlphaStack,
  Banner,
  Button,
  Card,
  Inline,
  Layout,
  Page,
  Stack,
  Tag,
  Text,
  TextField,
} from "@shopify/polaris";

import ClipIMG from "img/clip.svg";
import useAppDispatch from "hooks/useAppDispatch";

const AddMessage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const fileUploadInput = useRef();
  const { user } = useSelector((state) => state.user);
  const { creatingMessage, creatingMessageError } = useSelector(
    (state) => state.messages
  );
  const [fileNameList, setFileNameList] = useState({
    proof_files: [],
  });
  const [docsFiles, setDocsFiles] = useState({
    proof_files: [],
  });

  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [sender, setSender] = useState("");

  const handleSubmit = () => {
    const formData = new FormData();
    const data = {
      sender,
      subject,
      text,
      datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    const files = fileUploadInput.current.files;

    formData.append("data", JSON.stringify(data));

    for (const file of files) {
      if (fileNameList.proof_files.includes(file.name)) {
        const fn = file.name;
        const ext = "." + fn.split(".").at(-1);
        const name = fn.split(".").slice(0, -1).join(".").replaceAll(".", "-");
        const filename = name + ext;

        const newFile = new File([file], filename, { type: file.type }); // replacing "." in file name with "-"

        formData.append("files", newFile);
      }
    }

    dispatch(createNewMessage(formData))
      .then(() => {
        navigate("/messages");
      })
      .catch(_.noop);
  };

  const handleFileSelect = (docsType) => {
    const newFileNameList = { ...fileNameList };
    if (fileUploadInput.current) {
      fileUploadInput.current.click();
      fileUploadInput.current.onchange = () => {
        const files = fileUploadInput.current.files;
        for (let i = 0; i < files.length; i++) {
          doDeleteFile(docsType, files[i].name); // delete the same file from list, comparing inside 'doDeleteFile' function
          setDocsFiles((state) => {
            return {
              ...state,
              [docsType]: [...state[docsType], files[i]], // add file
            };
          });
          newFileNameList[docsType].push(files[i].name); // add filename
        }
        setFileNameList(newFileNameList);
      };
    }
  };

  const doDeleteFile = (fileListName, fileName) => {
    const newFileNameList = { ...fileNameList };
    const fileToDelete = docsFiles[fileListName].findIndex(
      (file) => file.name === fileName
    );

    if (fileToDelete !== -1) {
      setDocsFiles({
        ...docsFiles,
        [fileListName]: docsFiles[fileListName].filter(
          (_, index) => index !== fileToDelete
        ),
      }); // delete file from the list
      newFileNameList[fileListName].splice(fileToDelete, 1); // delete file name from the list
      setFileNameList(newFileNameList);
    }
  };

  const renderMultipleFileSelect = (docType) => {
    return (
      <div>
        <Stack alignment='center'>
          <Button
            icon={<img src={ClipIMG} alt='clip' style={{ marginTop: 4 }} />}
            onClick={() => handleFileSelect(docType)}
          >
            Add file
            <input
              type='file'
              multiple
              style={{ display: "none" }}
              ref={fileUploadInput}
            />
          </Button>
          <Text color='subdued'>Choose *jpg, *jpeg, *png files to upload</Text>
        </Stack>
        <div>
          {_.map(fileNameList[docType], (fileName, index) => {
            return (
              <div
                style={{ display: "inline-block", margin: "5px" }}
                key={index}
              >
                <Tag
                  key={index}
                  onRemove={() => {
                    doDeleteFile(docType, fileName);
                  }}
                >
                  {fileName}
                </Tag>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Page
      fullWidth
      title={
        <Text variant='heading3xl' as='span'>
          Add Message
        </Text>
      }
      subtitle={
        <Text variant='bodyLg' as='span' color='subdued'>
          Here you can add a message for {user?.company.name || ""}
        </Text>
      }
    >
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <AlphaStack spacing='4' fullWidth>
              {creatingMessageError && (
                <Banner status='critical'>Error! {creatingMessageError}</Banner>
              )}
              <TextField
                label='Sender'
                value={sender}
                onChange={(value) => setSender(value)}
              />
              <TextField
                label='Subject'
                value={subject}
                onChange={(value) => setSubject(value)}
              />
              <TextField
                label='Text'
                value={text}
                onChange={(value) => setText(value)}
                multiline={10}
              />
              {renderMultipleFileSelect("proof_files")}
              <Inline align='flex-end'>
                <Button
                  primary
                  onClick={handleSubmit}
                  loading={creatingMessage}
                  disabled={[sender, subject, text].some((a) => !a)}
                >
                  Submit
                </Button>
              </Inline>
            </AlphaStack>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default AddMessage;
