import { FormLayout, Select } from '@shopify/polaris'
import React from 'react'
import { useSelector } from 'react-redux'
import { DocumentType } from '../../../DocumentTemplate/common/constants'
import InlineText from '../../../DocumentTemplate/common/InlineText'
import { mapNumbersWithMultipleLabelsToOptions } from '../../../DocumentTemplate/templates/Invoice/helpers'

const VatNumberSelect = ({
	documentType,
	onLinkNavigate,
	data,
	setData,
}) => {
	const { regNumbers } = useSelector((state) => state.vatRegistration);
	const hasVatNumbers = Boolean(regNumbers.length);
	
	if (documentType !== DocumentType.InvoiceVAT) {
		return null;
	}
	
	const onSelectNumber = (id) => {
		setData((state) => ({
			...state,
			vat_number_id: id,
		}));
	};
	
	return hasVatNumbers ? (
		<FormLayout>
      <FormLayout.Group>
        <Select
	        options={mapNumbersWithMultipleLabelsToOptions(
		        regNumbers,
		        ["vat_number", "tin_number"],
		        "id"
	        )}
	        label='Vat Number'
	        onChange={onSelectNumber}
	        value={data.vat_number_id}
        />
        <></>
      </FormLayout.Group>
    </FormLayout>
	) : (
		<InlineText
			label='Vat Number'
			linkText={"required"}
			onLinkNavigate={() => onLinkNavigate(0)}
		/>
	);
};
export default VatNumberSelect