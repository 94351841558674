import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import PageHelmet from "components/PageHelmet";
import _ from "lodash";
import moment from "moment";
import {
  Page,
  Stack,
  ButtonGroup,
  Button,
  Card,
  Layout,
  TextStyle,
  FormLayout,
  Select,
  Badge,
  Banner,
  Spinner,
  Text,
  DataTable,
  Box,
  Tooltip,
} from "@shopify/polaris";
import {
  fetchNewMessagesCount,
  resetSystemMessage,
} from "redux/features/messenger/messengerSlice";
import { fetchRegNumbers } from "redux/features/registration/registrationSlice";
import { fetchCurrentPlan } from "redux/features/subscription/subscriptionSlice";
import {
  clearDeleteTransactionErrors,
  deleteTransaction,
  downloadFile,
  fetchTransactions,
  fetchTransactionsMessages,
  fetchTransactionsParams,
  fetchTransactionsToPrint,
  transactionIsVoid,
} from "redux/features/transactions/transactionsSlice";
import {
  fetchUserGuide,
  skipStepUserGuide,
} from "redux/features/user/userSlice";
import { formatDate, formatDateForServer, formatTime } from "utils/dates";
import Modal from "components/Modal";
import GuideCompletionPopup from "components/GuideCompletionPopup";
import { preciseRound } from "utils/numbers";
import { Link } from "react-router-dom";
import Datepicker from "components/Datepicker/Datepicker";
import formatRow from "utils/formatRow";
import paginate from "utils/paginate";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import Pagination from "components/Pagination/Pagination";
import Ctrl from "components/Ctrl/Ctrl";

import deleteIMG from "img/delete.svg";
import restoreIMG from "img/restore.svg";
import voidIMG from "img/close.svg";
import AddIcon from "img/add-white.svg";
import parrotIMG from "img/parrot.svg";
import { createCustomDispatch } from "helpers/customDispatch";
import SearchingCountry from '../../components/SearchingCountry/SearchingCountry'

const defaultLimit = 25;

const defaultDates = {
  from: moment().startOf("quarter").toDate(),
  to: moment().toDate(),
};

function sortAofO(items, key) {
  return [...items].sort((a, b) => {
    if (a[key] > b[key]) {
      return 1;
    }
    if (a[key] < b[key]) {
      return -1;
    }
    return 0;
  });
}

class Transactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countries: [],
      sites: [],
      currency: [],

      sortingColumns: [],
      searchColumn: "all",
      returnFillingStatus: undefined,
      query: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),

        website: undefined,
        currency: undefined,
        tj_country: undefined,
        arrival_country: undefined,
        departure_country: undefined,

        limit: defaultLimit,
        from_date: formatDateForServer(defaultDates.from),
        to_date: formatDateForServer(defaultDates.to),
      },

      pagination: {
        page: 1,
        pages: 1,
        perPage: defaultLimit,
      },

      actionDialogs: {
        delete: { open: false },
        change: { open: false },
        subscription: { open: false },
        void: { open: false },
      },
    };

    this.handleActionDialogsClose = this.handleActionDialogsClose.bind(this);
    this.handleActionDialogsOpen = this.handleActionDialogsOpen.bind(this);
  }

  componentDidMount() {
    this.props.fetchUserGuide();
    this.props.fetchCurrentPlan();
    this.props
      .fetchTransactions({
        limit: defaultLimit,
        from_date: formatDateForServer(defaultDates.from),
        to_date: formatDateForServer(defaultDates.to),
      })
      .then(() =>
        this.setState((prevState) => ({
          pagination: {
            ...prevState.pagination,
            pages:
              this.props.transactions && this.props.transactions.count
                ? Math.ceil(this.props.transactions.count / defaultLimit) || 1
                : 1,
          },
        }))
      )
      .catch(_.noop);
    this.props.fetchTransactionsParams().then((transactionsParams) => {
      const sites = [];
      if (transactionsParams.website) {
        for (const websiteId in transactionsParams.website) {
          if (websiteId >= 0) {
            sites.push({
              value: websiteId,
              label: transactionsParams.website[websiteId],
            });
          }
        }
      }

      let countries = [];
      if (
        !_.isEmpty(transactionsParams) &&
        transactionsParams.consumer_country
      ) {
        countries = _.map(
          transactionsParams.consumer_country,
          (label, value) => ({ label, value })
        );
      }

      let currency = [];
      if (
        !_.isEmpty(transactionsParams) &&
        transactionsParams.currency &&
        transactionsParams.currency.length
      ) {
        currency = transactionsParams.currency.map((item) => {
          return {
            label: item.toUpperCase(),
            value: item.toUpperCase(),
          };
        });
      }

      this.setState({
        sites,
        countries,
        currency,
      });
    });
    this.props.fetchRegNumbers(); // for 'fill the return' dialog
    this.props.fetchTransactionsMessages();
  }

  componentWillReceiveProps(nextProps) {
    const pagination = this.state.pagination;
    const { query } = this.state;
    pagination.pages =
      nextProps.transactions && nextProps.transactions.count
        ? Math.ceil(nextProps.transactions.count / defaultLimit) || 1
        : 1;
    if (query && !query.offset) {
      pagination.page = 1;
    }
    this.setState({ pagination });
  }

  onSelectPage = (inputPage) => {
    const pages =
      Math.ceil(
        this.props.transactions.count / this.state.pagination.perPage
      ) || 1;
    const page = Math.min(Math.max(inputPage, 1), pages);

    const query = {
      offset: (page - 1) * this.state.pagination.perPage,
      limit: defaultLimit,
    };
    this.setState(
      {
        query: _.assign(this.state.query, query),
        pagination: {
          ...this.state.pagination,
          page: page,
          pages: pages,
        },
      },
      () => {
        this.props.fetchTransactions(this.state.query);
      }
    );
  };

  handleAddTransactionsClick = () => {
    const { user, currentPlan } = this.props;

    if (
      (!user.is_manager &&
        !user.is_omp &&
        currentPlan &&
        !currentPlan.is_active &&
        !currentPlan.is_active_25 &&
        !currentPlan.is_active_sales_tax) ||
      (!user.is_manager && !user.is_omp && !currentPlan)
    ) {
      this.handleActionDialogsOpen("subscription");
    } else {
      this.props.navigate("/transactions/import");
    }
  };

  getAllSitesForSelect = () => {
    const { t } = this.props;
    const optionsSites = [
      {
        key: "all",
        label: t("transactions.allWbs"),
        value: "all",
      },
    ];
    this.state.sites.map((site) => {
      optionsSites.push({
        label: site.label,
        value: site.value,
      });
    });
    return optionsSites;
  };

  getAllCountriesForSelect = () => {
    const { t } = this.props;

    const optionsCountries = [
      {
        key: "all",
        label: t("transactions.allCntr"),
        value: "all",
      },
    ];
    this.state.countries.map((country) => {
      optionsCountries.push({
        label: country.label,
        value: country.value,
      });
    });
    return optionsCountries;
  };

  getAllCurrencyForSelect = () => {
    const { t } = this.props;

    const optionsCurrency = [
      {
        key: "all",
        label: t("transactions.all"),
        value: "all",
      },
    ];
    this.state.currency.map((item) => {
      optionsCurrency.push({
        label: item.label,
        value: item.value,
      });
    });
    return optionsCurrency;
  };

  getBannerStatus = (value) => {
    let status = "";
    switch (value) {
      case "error":
        status = "critical";
        break;
      case "ready":
        status = "success";
        break;
      default:
        status = "warning";
    }
    return status;
  };

  getBannerMessage = (message) => {
    const { t } = this.props;

    switch (message.status) {
      case "error":
        return (
          <div>
            <p>
              {t("transactions.mess.err.part1")}{" "}
              {message.platform[0].toUpperCase() + message.platform.slice(1)}{" "}
              {t("transactions.mess.err.part2")} {message.from}{" "}
              {t("transactions.mess.err.part3")} {message.to}{" "}
              {t("transactions.mess.err.part4")} {message.website}{" "}
              {t("transactions.mess.err.part5")}
            </p>
            {message.link_url && (
              <a
                className='Polaris-Link'
                target='_blank'
                href={message.link_url}
              >
                {message.link_title}
              </a>
            )}
          </div>
        );
      case "in process":
        return `${
          message.platform[0].toUpperCase() + message.platform.slice(1)
        } ${t("transactions.mess.in.part1")} ${message.from} ${t(
          "transactions.mess.in.part2"
        )} ${message.to} ${t("transactions.mess.in.part3")} ${
          message.website
        } ${t("transactions.mess.in.part4")}`;

      case "ready":
        return `${
          message.platform[0].toUpperCase() + message.platform.slice(1)
        } ${t("transactions.mess.ready.part1")} ${message.from} ${t(
          "transactions.mess.ready.part2"
        )} ${message.to} ${t("transactions.mess.ready.part3")} ${
          message.website
        } ${t("transactions.mess.ready.part4")}`;

      case "warning":
        return `Your ${message.website} transactions have "Warning" status from ${message.from} to ${message.to}. This may be due to incorrect Store settings. Please review and contact support.`;
      default:
        return null;
    }
  };

  doFilter = () => {
    const query = JSON.parse(JSON.stringify(this.state.query));
    const keysWithAllValue = [
      "website",
      "currency",
      "arrival_country",
      "departure_country",
      "tj_country",
    ];
    
    _.each(query, (val, key) => {
      if (!_.isUndefined(val) && !_.isNull(val) && val !== '') {
        if (keysWithAllValue.includes(key) && val === "all") {
          query[key] = undefined;
        } else if (_.isArray(val)) {
          query[key] = _.map(val, "value");
        } else if (_.isObject(val)) {
          if (key === "from_date" || key === "to_date") {
            query[key] = moment(val).format("YYYY-MM-DD");
          } else {
            query[key] = val.value;
          }
        } else {
          query[key] = val;
        }
      } else {
        delete query[key];
      }
    });

    query.from_date = formatDateForServer(this.state.query.from_date);
    query.to_date = formatDateForServer(this.state.query.to_date);

    const pagination = _.pick(this.state.query, ["offset", "limit"]);
    pagination.offset = 0;
    
    this.setState(
      {
        query: _.assign(query, pagination),
      },
      () => {
        this.props.fetchTransactions(this.state.query);
      }
    );
  };

  doSkipStep = (step) => {
    const { userGuide } = this.props;
    if (userGuide && userGuide.show_user_guide) {
      this.props
        .skipStepUserGuide(step)
        .then(() => {
          this.props.fetchUserGuide();
        })
        .catch(_.noop);
    }
  };

  makePrintablePromise = () => {
    const query = _.cloneDeep(this.state.query);
    if (query.from_date) {
      query.from_date = formatDateForServer(query.from_date);
    }
    if (query.to_date) {
      query.to_date = formatDateForServer(query.to_date);
    }
    delete query.limit;

    return new Promise((resolve, reject) => {
      this.props
        .fetchTransactionsToPrint(query)
        .then((resp) => {
          const result = { ...resp };
          if (_.isArray(resp.items)) {
            result.items = resp.items.map((item) => {
              const newItem = { ...item };
              if (this.props.transactionsParams.website) {
                newItem.website =
                  this.props.transactionsParams.website[item.website_id] || "";
              }
              const d = moment(item.transaction_datetime);
              newItem.transaction_date = d.format("YYYY-MM-DD HH:MM");
              
              newItem.transaction_sum = preciseRound(item.transaction_sum, 2);
              newItem.vat = preciseRound(item.vat, 4);

              newItem.vat_rate = item.vat_percent + " %";

              return newItem;
            });
          }
          resolve(result);
        })
        .catch(reject);
    });
  };

  doDeleteTransaction = () => {
    const { actionDialogs, query } = this.state;
    const transactionId = _.get(actionDialogs, "cellData.transaction_id");

    if (transactionId) {
      this.props
        .deleteTransaction(transactionId)
        .then(() => {
          this.props.fetchTransactions(query);
          this.handleActionDialogsClose("delete");
        })
        .catch(_.noop);
    }
  };

  doIsVoidTransaction = () => {
    const { actionDialogs, query } = this.state;
    const transactionId = _.get(actionDialogs, "cellData.transaction_id");

    if (transactionId) {
      this.props
        .transactionIsVoid(transactionId)
        .then(() => {
          this.props.fetchTransactions(query);
          this.handleActionDialogsClose("void");
        })
        .catch(_.noop);
    }
  };

  generateShowingInfo = (page, pages, count) => {
    const { t } = this.props;

    let message = "";
    if (count < 25) {
      message = `${t("transactions.showing")} ${count} ${t(
        "transactions.of"
      )} ${count} ${t("transactions.results")}`;
    } else {
      if (page !== pages) {
        message = `${t("transactions.showing")} ${page * 25 - 24}-${
          page * 25
        } ${t("transactions.of")} ${count} ${t("transactions.results")}`;
      } else {
        message = `${t("transactions.showing")} ${page * 25 - 24}-${count} ${t(
          "transactions.of"
        )} ${count} ${t("transactions.results")}`;
      }
    }
    return message;
  };

  handleMonthChangeQueryData = (month, year) => {
    const { query } = this.state;
    query.month = month;
    query.year = year;
    this.setState({ query });
  };

  handleFromDateChange = (startDate) => {
    const { query } = this.state;
    query.from_date = startDate;

    query.month = moment(startDate).month() + 1;
    query.year = moment(startDate).year();
    this.setState({ query });
    this.doFilter();
  };

  handleTillDateChange = (endDate) => {
    const { query } = this.state;

    query.to_date = endDate;

    this.setState({ query });
    this.doFilter();
  };

  handleActionDialogsOpen(name, data = {}) {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.props.clearDeleteTransactionErrors();
    this.setState({ actionDialogs });
  }

  handleActionDialogsClose(name) {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  }

  renderDeleteActionDialog = () => {
    const { actionDialogs } = this.state;
    const { transactionDeletingError, t } = this.props;

    return !_.isEmpty(actionDialogs.cellData) ? (
      <Modal
        title={t("transactions.confDel")}
        description={`${t("transactions.areYouSureDel1")}
        ${actionDialogs.cellData.transaction_id}
        ${t("transactions.areYouSureDel2")}?`}
        contentOnCenter
        iconType={"danger"}
        visible={actionDialogs.delete.open}
        onClose={this.handleActionDialogsClose.bind(null, "delete")}
        content={
          <>
            {(_.isObject(transactionDeletingError) ||
              _.isArray(transactionDeletingError)) && (
              <div>
                {_.map(transactionDeletingError, (val, key) => (
                  <div key={key}>{_.isArray(val) ? val.join("\n") : val}</div>
                ))}
              </div>
            )}
            {_.isString(transactionDeletingError) && (
              <div>
                <TextStyle variation='negative'>
                  {transactionDeletingError}
                </TextStyle>
              </div>
            )}
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button
              onClick={this.handleActionDialogsClose.bind(null, "delete")}
              size='large'
            >
              {t("transactions.close")}
            </Button>
            <Button destructive onClick={this.doDeleteTransaction} size='large'>
              {t("transactions.del")}
            </Button>
          </ButtonGroup>
        }
      />
    ) : undefined;
  };

  renderIsVoidActionDialog = () => {
    const { actionDialogs } = this.state;
    const { transactionIsVoidError, t } = this.props;

    return !_.isEmpty(actionDialogs.cellData) ? (
      <Modal
        title={`Comfirm to ${
          actionDialogs.cellData.is_void ? "restore " : "void "
        }`}
        description={`Are you sure you want to 
        ${actionDialogs.cellData.is_void ? "restore " : "void "}
        transaction ${actionDialogs.cellData.transaction_id}?`}
        contentOnCenter
        visible={actionDialogs.void.open}
        onClose={this.handleActionDialogsClose.bind(null, "void")}
        content={
          <>
            {(_.isObject(transactionIsVoidError) ||
              _.isArray(transactionIsVoidError)) && (
              <div>
                {_.map(transactionIsVoidError, (val, key) => (
                  <div key={key}>{_.isArray(val) ? val.join("\n") : val}</div>
                ))}
              </div>
            )}
            {_.isString(transactionIsVoidError) && (
              <div>
                <TextStyle variation='negative'>
                  {transactionIsVoidError}
                </TextStyle>
              </div>
            )}
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button
              onClick={this.handleActionDialogsClose.bind(null, "void")}
              size='large'
            >
              {t("transactions.close")}
            </Button>
            <Button primary onClick={this.doIsVoidTransaction} size='large'>
              {actionDialogs.cellData.is_void ? "Restore" : "Void"}
            </Button>
          </ButtonGroup>
        }
      />
    ) : undefined;
  };

  renderSubscriptionDialog = () => {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={t("transactions.warning")}
        visible={actionDialogs.subscription.open}
        onClose={() => this.handleActionDialogsClose("subscription")}
        content={
          <div
            style={{ display: "flex", alignItems: "center", maxWidth: "400px" }}
          >
            <p>
              {t("createReturns.contUsing")} (
              {
                <Link className='Polaris-Link' to='/subscription'>
                  {t("createReturns.selectPlan")}
                </Link>
              }
              )
            </p>
            <img src={parrotIMG} alt='lovat_logo' width='20%' />
          </div>
        }
      />
    );
  };

  render() {
    const { sortingColumns, pagination, query } = this.state;
    const { currentPlan, user, userGuide, transactionsMessages, fetching, t } =
      this.props;
    let { countries } = this.state;
    countries = sortAofO(countries, "label");

    const rows = this.props.transactions.items
      ? JSON.parse(JSON.stringify(this.props.transactions.items))
      : [];
    if (rows.length) {
      _.forEach(rows, (item, index) => {
        item.id = index;
        const d = moment(item.transaction_datetime).utcOffset("+0000");
        item.transaction_date = formatDate(d);
        item.transaction_time = formatTime(d);
        item.transaction_sum = preciseRound(item.transaction_sum, 2);
        item.vat = preciseRound(item.vat, 4);
        item.vat_rate = item.vat_percent + "%"; // vat percent, not coefficient
        if (this.props.transactionsParams.website) {
          item.website =
            this.props.transactionsParams.website[item.website_id] || "";
        }
      });
    }

    const columns = [
      {
        property: "transaction_id",
        header: {
          label: t("transactions.transID"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <span
                style={
                  rowData.is_void ? { textDecoration: "line-through" } : {}
                }
              >
                {value}
              </span>
            ),
          ],
        },
      },
      {
        property: "transaction_date",
        header: {
          label: t("transactions.date"),
        },
      },
      {
        property: "transaction_time",
        header: {
          label: t("transactions.time"),
        },
      },
      {
        property: "transaction_type",
        header: {
          // label: t("transactions.status"),
          label: "Transaction type",
        },
        cell: {
          formatters: [
            (value) => (
              <Badge status={value === "Warning" ? "attention" : "success"}>
                {value.toString()[0].toUpperCase() +
                  value.toString().substr(1).split("_")[0]}
              </Badge>
            ),
          ],
        },
      },
      {
        property: "website",
        header: {
          label: t("transactions.store"),
        },
      },
      {
        property: "consumer_country_id",
        header: {
          label: t("transactions.country"),
        },
      },
      {
        property: "currency",
        header: {
          label: t("transactions.currency"),
        },
      },
      {
        property: "transaction_sum",
        header: {
          label: t("transactions.sum"),
        },
      },
      {
        property: "vat_rate",
        header: {
          label: "TAX rate",
        },
      },
      {
        property: "vat",
        header: {
          label: "TAX amount",
        },
      },
      {
        property: "website_id",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <div className='ButtonOnHover'>
                {(user.is_manager ||
                  user.is_omp ||
                  (currentPlan &&
                    (currentPlan.is_active ||
                      currentPlan.is_active_sales_tax))) &&
                  rowData.is_void && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "0.5rem",
                      }}
                    >
                      <Tooltip content='Delete'>
                        <img
                          src={deleteIMG}
                          alt='icon delete'
                          onClick={this.handleActionDialogsOpen.bind(
                            null,
                            "delete",
                            rowData
                          )}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                      <Tooltip content='Restore'>
                        <img
                          src={restoreIMG}
                          alt='icon restore'
                          onClick={this.handleActionDialogsOpen.bind(
                            null,
                            "void",
                            rowData
                          )}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </div>
                  )}

                {(user.is_manager ||
                  user.is_omp ||
                  (currentPlan &&
                    (currentPlan.is_active ||
                      currentPlan.is_active_sales_tax))) &&
                  !rowData.is_void && (
                    <Tooltip content='Void'>
                      <img
                        src={voidIMG}
                        alt='icon void'
                        onClick={this.handleActionDialogsOpen.bind(
                          null,
                          "void",
                          rowData
                        )}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  )}
                {(!user.is_manager &&
                  !user.is_omp &&
                  currentPlan &&
                  !currentPlan.is_active &&
                  !currentPlan.is_active_sales_tax) ||
                  (!user.is_manager && !user.is_omp && !currentPlan && (
                    <Tooltip content='Delete'>
                      <img
                        src={deleteIMG}
                        alt='icon delete'
                        onClick={() =>
                          this.handleActionDialogsOpen("subscription")
                        }
                      />
                    </Tooltip>
                  ))}
              </div>
            ),
          ],
        },
      },
    ];

    const sortedRows = formatRow(rows, columns);
    const paginatedRows = paginate(pagination)(sortedRows);

    return (
      <Page
        separator
        fullWidth
        title={
          <Text variant='heading3xl' as='span'>
            {t("transactions.transactions")}
          </Text>
        }
        subtitle={
          <Text variant='bodyLg' as='span' color='subdued'>
            {t("transactions.onThisPage")}
          </Text>
        }
        primaryAction={
          <div
            style={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <PageHelmet title={"Transactions"} />
            <Stack>
              <Ctrl
                printablePromise={this.makePrintablePromise}
                printableProps={_.map(columns, (c) => ({
                  field: c.property,
                  displayName: c.header.label,
                })).filter((item) => {
                  return item &&
                    item.field !== "transaction_time" &&
                    item.field !== "website_id"
                    ? item
                    : false;
                })}
                saveFilename={"transactions"}
                saveTitle={t("transactions.save")}
                printTitle={t("transactions.print")}
              />
              <Button
                primary
                icon={
                  <img
                    src={AddIcon}
                    alt='add'
                    width='15'
                    style={{ marginTop: 4, marginRight: 5 }}
                  />
                }
                onClick={this.handleAddTransactionsClick}
              >
                {t("transactions.addTrans")}
              </Button>
            </Stack>
          </div>
        }
      >
        <Layout>
          <Layout.Section>
            <GuideCompletionPopup
              title={t("userGuide.completed4")}
              description={t("userGuide.completed4_desc")}
              visible={
                userGuide &&
                userGuide.show_user_guide &&
                userGuide.steps[3].show_banner
              }
              step={4}
            />

            {!_.isEmpty(transactionsMessages) && (
              <div>
                {transactionsMessages.map((message, index) => (
                  <Banner
                    key={`${index}banner`}
                    onDismiss={
                      message.status !== "in process"
                        ? () => {
                            this.props
                              .resetSystemMessage({
                                modelsName: `transactions`,
                                id: message.id,
                              })
                              .then(() =>
                                this.props.fetchTransactionsMessages()
                              )
                              .then(() => this.props.fetchNewMessagesCount())
                              .catch(_.noop);
                          }
                        : false
                    }
                    status={this.getBannerStatus(message.status)}
                  >
                    {this.getBannerMessage(message)}
                  </Banner>
                ))}
                <br />
              </div>
            )}
            <Card sectioned>
              <form >
                <FormLayout>
                  <FormLayout.Group condensed>
                    <Select
                      id='web_slct'
                      label={`${t("transactions.store")}:`}
                      options={this.getAllSitesForSelect()}
                      onChange={(site) => {
                        query.website = site;
                        this.setState({ query });
                        this.doFilter({ query })
                      }}
                      value={query.website}
                    />

                    <Select
                      id='crnc_slct'
                      label={`${t("transactions.currency")}:`}
                      options={this.getAllCurrencyForSelect()}
                      onChange={(value) => {
                        query.currency = value;
                        this.setState({ query });
                        this.doFilter({ query })
                      }}
                      value={query.currency}
                    />

                    <Datepicker
                      label={t("transactions.from")}
                      value={query.from_date}
                      onChange={this.handleFromDateChange}
                      allowRange={false}
                    />
                    <Datepicker
                      label={t("transactions.till")}
                      value={query.to_date}
                      onChange={this.handleTillDateChange}
                      allowRange={false}
                      alignment={"right"}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    
                    <SearchingCountry
                      title={'Taxable Jurisdiction:'}
                      placeholder={'Enter Taxable Jurisdiction'}
                      onChange={(country) => {
                        query.tj_country = country;
                        this.setState({ query });
                        this.doFilter({ query })
                      }}
                      dataCountriesList={this.getAllCountriesForSelect()}
                      changeCountry={query.tj_country}
                    />
                    
                    <SearchingCountry
                      title={'Arrival Country:'}
                      placeholder={'Enter Arrival Country'}
                      onChange={(country) => {
                        query.arrival_country = country;
                        this.setState({ query });
                        this.doFilter({ query })
                      }}
                      dataCountriesList={this.getAllCountriesForSelect()}
                      changeCountry={query.arrival_country}
                    />
                    
                    <SearchingCountry
                      title={'Departure Country:'}
                      placeholder={'Enter Departure Country'}
                      onChange={(country) => {
                        query.departure_country = country;
                        this.setState({ query });
                        this.doFilter({ query })
                      }}
                      dataCountriesList={this.getAllCountriesForSelect()}
                      changeCountry={query.departure_country}
                    />
                    <br />
                  </FormLayout.Group>
                </FormLayout>
              </form>
            </Card>

            <Card>
              {fetching ? (
                <Card.Section>
                  <Spinner />
                </Card.Section>
              ) : (
                <React.Fragment>
                  <>
                    <DataTable
                      columnContentTypes={[
                        "text",
                        "text",
                        "text",
                        "text",
                        "text",
                        "text",
                        "text",
                        "text",
                      ]}
                      headings={columns.map(({ header }) => (
                        <Text fontWeight='semibold'>{header.label}</Text>
                      ))}
                      rows={paginatedRows.rows}
                      hideScrollIndicator
                      hoverable
                    />
                    {_.isEmpty(sortedRows) && (
                      <NoDataMessage
                        description='Add transactions to see data'
                        buttonText='Add transactions'
                        onClick={this.handleAddTransactionsClick}
                      />
                    )}
                  </>
                </React.Fragment>
              )}
            </Card>
            {!_.isEmpty(sortedRows) && (
              <Box padding='4'>
                <Pagination
                  total={this.props.transactions.count}
                  current={pagination.page}
                  pageSize={defaultLimit}
                  onChange={(current) => this.onSelectPage(current)}
                />
              </Box>
            )}
          </Layout.Section>
        </Layout>

        {this.renderSubscriptionDialog()}
        {this.renderDeleteActionDialog()}
        {this.renderIsVoidActionDialog()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  userGuide: state.user.userGuide,
  currentPlan: state.subscription.currentPlan,
  transactions: state.transactions.transactions,
  fetching: state.transactions.fetching,
  transactionsMessages: state.transactions.transactionsMessages,
  transactionsParams: state.transactions.transactionsParams,
  transactionDeletingError: state.transactions.deletingError,
  transactionIsVoidError: state.transactions.voidingError,
  defaultLanguage: state.user.defaultLanguage,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchCurrentPlan: () => dispatch(fetchCurrentPlan()),
    fetchTransactions: (params) => dispatch(fetchTransactions(params)),
    fetchTransactionsParams: () => dispatch(fetchTransactionsParams()),
    fetchTransactionsToPrint: (params) =>
      dispatch(fetchTransactionsToPrint(params)),
    downloadFile: (params) => dispatch(downloadFile(params)),
    deleteTransaction: (transactionId) =>
      dispatch(deleteTransaction(transactionId)),
    transactionIsVoid: (transactionId) =>
      dispatch(transactionIsVoid(transactionId)),
    clearDeleteTransactionErrors: () =>
      dispatch(clearDeleteTransactionErrors()),
    fetchTransactionsMessages: () => dispatch(fetchTransactionsMessages()),
    resetSystemMessage: (params) => dispatch(resetSystemMessage(params)),
    fetchNewMessagesCount: () => dispatch(fetchNewMessagesCount()),
    fetchUserGuide: () => dispatch(fetchUserGuide()),
    skipStepUserGuide: (step) => dispatch(skipStepUserGuide(step)),
    fetchRegNumbers: (active) => dispatch(fetchRegNumbers(active)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Transactions))
);
