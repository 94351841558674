import { Card, Checkbox, Stack } from '@shopify/polaris'
import { t } from 'i18next'
import React from 'react'

const CancelSubscriptionOne = ({
	subsList,
	subsForDel,
	saveSetSubsForDel,
	subsForDelAddons,
	saveSetSubsForDelAddons,
}) => {
	return (
		<div className="cancelSubscriptionBuilder-description">
      <h2 className="cancelSubscriptionBuilder-description_topTitle">
        {`${t('profileMemb.cancelSub')}?`}
      </h2>
      <div className="cancelSubscriptionBuilder-cards">
        {subsList.map((subscription, index) => (
	        <div className="cancelSubscriptionBuilder-cards_item" key={index}>
            <Card
	            key={index}
	            title={
		            <h2 className="cancelSubscriptionBuilder-cards_item-label">
                  <Checkbox
	                  label={subscription.label}
	                  checked={subsForDel.some(
		                  (item) => item.value === subscription.value && item.checked
	                  )}
	                  onChange={(checked) => saveSetSubsForDel(subscription.value, checked)}
                  />
                </h2>
	            }
	            sectioned
            >
              <Stack
	              vertical
	              spacing="tight"
              >
	              
                <div className="cancelSubscriptionBuilder-cards_item-addons">
	                {subscription?.addons?.map((addon) => (
	              	  <Checkbox
	              		  key={`${addon.addons_id}-${addon.type_id}`}
	              		  label={
	              			  <div className="cancelSubscriptionBuilder-cards_item-addons_line">
	                        <span >{addon.name}</span >
	                      </div >
	              		  }
	              		  checked={subsForDelAddons.some(
	              			  (item) =>
	              				  item.subscription === subscription.value &&
	              				  item.addons.some((addonItem) => {
	              					  return (
	              						  addonItem.body.addons_id === addon.addons_id &&
	              						  addonItem.body.type_id === addon.type_id &&
	              						  addonItem.checked
	              					  );
	              				  })
	              		  )}
	              		  onChange={(checked) =>
	              			  saveSetSubsForDelAddons({
	              				  subscription: subscription.value,
	              				  addon: {
	              					  value: addon.addons_id,
	              					  type_id: addon.type_id,
	              					  checked,
	              					  body: addon,
	              				  },
	              			  })
	              		  }
	              	  />
	                ))}
	              </div >
	             
              </Stack >
            </Card >
          </div >
        ))}
      </div >
    </div >
	)
}

export default CancelSubscriptionOne
