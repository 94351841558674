import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { Card } from "@shopify/polaris";
import cx from "classnames";

import modalSuccessIMG from "img/modalSuccess.svg";
import modalRedIMG from "img/modalRed.svg";
import modalYellowIMG from "img/modalYellow.svg";
import closeIMG from "img/close.svg";

import "./index.scss";
import classNames from "classnames";

ReactModal.setAppElement("#root");

export default function Modal({
  visible,
  onClose,
  title,
  description,
  content,
  footer = null,
  iconType,
  closeIconVisible = true,
  infoIconVisible = true,
  contentOnCenter = false,
  titleOnCenter = false,
}) {
  let infoIconType;

  switch (iconType) {
    case "success": {
      infoIconType = modalSuccessIMG;
      break;
    }
    case "danger": {
      infoIconType = modalRedIMG;
      break;
    }
    case "warning": {
      infoIconType = modalYellowIMG;
      break;
    }
    default: {
      infoIconType = modalSuccessIMG;
    }
  }

  return (
    <ReactModal
      closeTimeoutMS={100}
      isOpen={visible}
      onRequestClose={onClose}
      className='reactModal'
      overlayClassName='reactModalOverlay'
    >
      <Card>
        <div className={cx("modal", { contentOnCenter })}>
          <div className='modal__header'>
            {infoIconVisible && (
              <div className='modal__infoIconWrap'>
                <img
                  src={infoIconType}
                  alt='modalType'
                  className='modal__infoIcon'
                />
              </div>
            )}
            <div
              className={classNames("modal__title", {
                "modal__title-centered": titleOnCenter,
              })}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {closeIconVisible && (
              <img
                src={closeIMG}
                alt='close'
                onClick={onClose}
                className='modal__close'
              />
            )}
          </div>
          <div className='modal__content'>
            {description && (
              <div className='modal__description'>{description}</div>
            )}
            {content}
          </div>
          {footer && <div className='modal__footer'>{footer}</div>}
        </div>
      </Card>
    </ReactModal>
  );
}

Modal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.node,
  description: PropTypes.node,
  content: PropTypes.node,
  footer: PropTypes.node,
  iconType: PropTypes.oneOf(["danger", "warning", "success"]),
  closeIconVisible: PropTypes.bool,
  infoIconVisible: PropTypes.bool,
  contentOnCenter: PropTypes.bool,
};
