import React from 'react'
const CustomButton = ({outputNumber, currency, handler, title, curent}) => {
	return (
		<button
			style={{
				width: '100%', display: 'flex', justifyContent: 'space-between', backgroundColor: 'transparent',
				minHeight: '2.25rem', border: '1px solid rgba(118, 118, 118, 0.3)', borderRadius: '4px',
				alignItems: 'center',
				cursor: 'pointer',
			}}
			onClick={() => {
				handler({title: title, count: curent})
			}}
		>
			<span >{outputNumber}</span >
			<span style={{color: 'rgba(109, 113, 117, 1)'}}>{currency}</span >
		</button >
	)
}
export default CustomButton