import { FormLayout, Select } from '@shopify/polaris'
import React from 'react'
import { useSelector } from 'react-redux'
import { DocumentType } from '../../../DocumentTemplate/common/constants'
import InlineText from '../../../DocumentTemplate/common/InlineText'
import { mapNumbersToOptions } from '../../../DocumentTemplate/templates/Invoice/helpers'

const SalesTaxSelect= ({
	documentType,
	onLinkNavigate,
	data,
	setData,
}) => {
	const { taxPermits } = useSelector((state) => state.declarations);
	const hasSalesTaxNumbers = Boolean(taxPermits.length);
	
	if (documentType !== DocumentType.InvoiceSalesTax) {
		return null;
	}
	
	const onSelectNumber = (id) => {
		setData((state) => ({
			...state,
			sales_tax_number_id: id,
		}));
	};
	
	return hasSalesTaxNumbers ? (
		<FormLayout>
      <FormLayout.Group>
        <Select
	        options={mapNumbersToOptions(taxPermits, "sales_tax_id", "id")}
	        label='Sales Tax Number'
	        onChange={onSelectNumber}
	        value={data.sales_tax_number_id}
        />
        <></>
      </FormLayout.Group>
    </FormLayout>
	) : (
		<InlineText
			label='Sales Tax Number'
			linkText={"required"}
			onLinkNavigate={() => onLinkNavigate(1)}
		/>
	);
};

export default SalesTaxSelect