import { AlphaStack, Card, Layout, Text } from "@shopify/polaris";
import React, { useEffect } from "react";
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch";
import useNotifications from "../hooks/useNotifications";
import {
  editNotifications,
  fetchNotifications,
} from "redux/features/user/userSlice";
import { useTranslation } from "react-i18next";
import useAppDispatch from "hooks/useAppDispatch";

const toggleSwitchProps = [
  {
    label: "Monthly Newsletter",
    key: "monthlyNewsletter",
    value: "monthly_newsletter",
  },
  {
    label: "Notifications",
    key: "notifications",
    value: "notifications",
  },
  {
    label: "Support Updates",
    key: "supportUpdates",
    value: "support_updates",
  },
];

const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [notifications, setNotifications] = useNotifications([
    "monthly_newsletter",
    "notifications",
    "support_updates",
  ]);

  useEffect(() => {
    dispatch(fetchNotifications()).then((res) =>
      setNotifications({
        monthly_newsletter: res.monthly_newsletter,
        notifications: res.notifications,
        support_updates: res.support_updates,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNotifications]);

  const onChange = (value, checked) => {
    const data = { notification_type: value };

    setNotifications(value, checked);

    dispatch(editNotifications(data));
  };

  return (
    <Layout.AnnotatedSection
      title={
        <Text variant='headingLg' as='p'>
          {t("profile.personalSettings.notifications")}
        </Text>
      }
      description={
        <Text color='subdued'>
          {t("profile.personalSettings.manageCommunication")}
        </Text>
      }
    >
      <Card sectioned>
        <AlphaStack spacing='6'>
          {toggleSwitchProps.map((props, index) => (
            <ToggleSwitch
              key={index}
              label={t(`profile.personalSettings.${props.key}`)}
              onChange={(checked) => onChange(props.value, checked)}
              checked={notifications[props.value]}
            />
          ))}
        </AlphaStack>
      </Card>
    </Layout.AnnotatedSection>
  );
};

export default Notifications;
