import { Inline, Layout, Page, Tabs, Text } from "@shopify/polaris";
import React, { useState } from "react";
import FAQ from "./FAQ/FAQ";
import { useTranslation } from "react-i18next";
import Support from "./Support/Support";
import PageHelmet from "components/PageHelmet";
import { useLocation } from "react-router-dom";

const HelpCenter = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [selected, setSelected] = useState(location.state?.defaultTab ?? 0);

  return (
    <Page
      title={
        <Text variant='heading3xl' as='span'>
          Help Center
        </Text>
      }
      subtitle={
        <>
          <Text variant='bodyLg' as='span' color='subdued'>
            Here you will find training videos of lovat and you can get support
            from our manager
          </Text>
          <Inline spacing='4'>
            <a
              className='Polaris-Link'
              href='/files/MerchantsQuastionnaire.doc'
              download
              style={{ textDecoration: "none" }}
            >
              <Text variation='subdued'>{t("help.merch")}</Text>
            </a>
            <a
              className='Polaris-Link'
              href='/files/Terms of use of website vatcompliance.net.pdf'
              download
              style={{ textDecoration: "none" }}
            >
              <Text variation='subdued'>{t("help.licence")}</Text>
            </a>
          </Inline>
        </>
      }
      fullWidth
    >
      <PageHelmet title={"Help Center"} />

      <Tabs
        tabs={[
          {
            id: "faq",
            content: "FAQ",
            accessibilityLabel: "FAQ",
            panelID: "faq-tab",
          },
          {
            id: "support",
            content: "Support",
            accessibilityLabel: "Support",
            panelID: "support-tab",
          },
        ]}
        selected={selected}
        onSelect={setSelected}
      />
      <Layout>
        <Layout.Section>
          <br />
          {selected === 0 && <FAQ />}
          {selected === 1 && <Support />}
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default HelpCenter;
