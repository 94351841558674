import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import _ from "lodash";
import { Button, ButtonGroup } from "@shopify/polaris";
import printJS from "print-js";

import printIMG from "img/printBtn.svg";

class Ctrl extends Component {
  constructor(props) {
    super(props);
    this.print = this.print.bind(this);
  }

  state = {
    open: false,
  };

  print() {
    const {
      printable,
      printableProps,
      printableHeader,
      printablePromise,
      printableOpenInNewTab,
    } = this.props;

    if (_.isArray(printable)) {
      printJS({
        printable,
        properties: printableProps,
        type: "json",
        header: printableHeader,
      });
    } else {
      if (printableOpenInNewTab) {
        const newTab = window.open("about:blank", "pdf");
        printablePromise()
          .then((resp) => {
            newTab.open(resp.link, "pdf");
          })
          .catch(() => {
            newTab.close();
          });
      } else {
        printablePromise()
          .then((resp) => {
            if (!_.isUndefined(resp.items)) {
              printJS({
                printable: resp.items,
                properties: printableProps,
                type: "json",
                header: printableHeader,
                style: "body { margin: 0; font-size: 8pt; }",
              });
            }
          })
          .catch(_.noop);
      }
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { printTitle } = this.props;
    return (
      <div>
        <ButtonGroup>
          <Button
            onClick={this.print}
            icon={
              <img
                src={printIMG}
                alt='icon print'
                style={{
                  width: "1rem",
                  marginRight: "0.5rem",
                  marginTop: "2px",
                }}
              />
            }
          >
            {printTitle}
          </Button>
        </ButtonGroup>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Ctrl))
);
