import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import { parseServerError } from "utils/errors";

export const createNewMessage = createAsyncThunk(
  "messages/createNewMessage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/messages", "POST", data, {
        contentType: "multipart/form-data",
      });
      if (!response.ok) {
        throw new Error("Server error");
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const setMessageRead = createAsyncThunk(
  "messages/setMessageRead",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/messages/set_message_read?message_id=${id}`,
        "PUT"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteMessage = createAsyncThunk(
  "messages/deleteMessage",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(`/messages?message_id=${id}`, "DELETE");
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchMessages = createAsyncThunk(
  "messages/fetchMessages",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/messages", "GET", params);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const setMessageMarked = createAsyncThunk(
  "messages/setMessageMarked",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/messages/set_marked?message_id=${id}`,
        "PUT"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  messages: [],
  messagesCount: 0,

  fetchingMessages: false,
  creatingMessage: false,
  deletingMessage: false,

  creatingMessageError: null,
  deletingMessageError: null,
  fetchingMessagesError: null,
  setMessageMarkedError: null,
  setMessageReadError: null,
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchMessages.pending, (state) => {
        state.fetchingMessages = true;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.fetchingMessages = false;
        state.fetchingMessagesError = null;
        state.messages = action.payload.items;
        state.messagesCount = action.payload.count;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.fetchingMessages = false;
        state.fetchingMessagesError = parseServerError(action.error);
      })
      .addCase(createNewMessage.pending, (state) => {
        state.creatingMessage = true;
      })
      .addCase(createNewMessage.fulfilled, (state) => {
        state.creatingMessage = false;
        state.creatingMessageError = null;
      })
      .addCase(createNewMessage.rejected, (state, action) => {
        state.creatingMessage = false;
        state.creatingMessageError = parseServerError(action.error);
      })
      .addCase(deleteMessage.pending, (state) => {
        state.deletingMessage = true;
      })
      .addCase(deleteMessage.fulfilled, (state) => {
        state.deletingMessage = false;
        state.deletingMessageError = null;
      })
      .addCase(deleteMessage.rejected, (state, action) => {
        state.deletingMessage = false;
        state.deletingMessageError = parseServerError(action.error);
      })
      .addCase(setMessageMarked.fulfilled, (state, action) => {
        state.setMessageMarkedError = null;
        const { message_id, marked } = action.payload;
        const messageIndex = state.messages.findIndex(
          (message) => message.id === message_id
        );
        if (messageIndex !== -1) {
          state.messages[messageIndex].marked = marked;
        }
      })
      .addCase(setMessageMarked.rejected, (state, action) => {
        state.setMessageMarkedError = parseServerError(action.error);
      }),
});

export default messagesSlice.reducer;
