import { useEffect, useRef } from "react";

/**
 * Hook to detect clicks outside a specified DOM element and trigger a callback.
 * @param {function} handler - The callback function to be executed when a click outside the element is detected.
 * @returns {object} - A React ref object representing the DOM node to be observed.
 */

export const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let fn = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", fn);

    return () => {
      document.removeEventListener("mousedown", fn);
    };
  }, []);

  return domNode;
};
