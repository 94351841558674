import React from "react";
import { Link } from "react-router-dom";
import { ButtonGroup, Button, Text } from "@shopify/polaris";
import Modal from "../Modal";

import ParrotIMG from "img/userGuideParrot.svg";

import "./index.scss";

const Welcome = ({ onClose, navigate, visible }) => {
  const onPurchaseNow = () => {
    navigate("/subscription");

    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      title='Welcome to LOVAT!'
      infoIconVisible={false}
      visible={visible}
      content={
        <>
          <img src={ParrotIMG} alt='parrot' className='parrot-image' />
          <div style={{ maxWidth: "400px" }}>
            <Text color='subdued' variant='bodyMd'>
              Congratulations on joining LOVAT, your gateway to stress-free tax
              compliance for online selling.
              <br />
              <br />
              We're thrilled to have you on board! 🎉
              <br />
              <br />
            </Text>
          </div>
          <Text color='subdued' variant='bodyMd'>
            To learn more about how to start with Lovat, check{" "}
            <Link className='Polaris-Link' to='/video'>
              out our video.
            </Link>
          </Text>
        </>
      }
      footer={
        <>
          <Text>
            Experience a 30-day free trial or secure your subscription plan
            today.
          </Text>
          <br />
          <ButtonGroup>
            <Button primary onClick={onClose}>
              Try free for 30 days
            </Button>
            <p>or</p>
            <Button plain onClick={onPurchaseNow}>
              purchase now
            </Button>
          </ButtonGroup>
        </>
      }
    />
  );
};

export default Welcome;
