import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  checkPromoCode,
  orderPayment,
  orderVatRegRedirect,
} from "redux/features/payment/paymentSlice";
import {
  Button,
  Stack,
  TextField,
  FormLayout,
  TextStyle,
  ButtonGroup,
  Text,
  Link,
} from "@shopify/polaris";
import StripePayment from "../StripePayment/StripePayment";
import { createCustomDispatch } from "helpers/customDispatch";

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentStatus: "",
      promo: null,
      promoEntered: "",
      promoError: null,
    };
  }
  pay = (service, comment, promocode, country, regReason) => {
    const data = {
      service,
      comment,
      promocode,
      country,
      reason_for_registration: regReason,
    };
    this.props
      .orderPayment({ data })
      .then((resp) => {
        this.setState({ orderingStatus: resp });
        this.props.closeDialog(this.props.currentDialog);
        this.props.openNextDialog(this.props.nextDialog);
      })
      .catch((resp) => {
        this.setState({ orderingError: resp });
      });
  };

  doRequestOfFunds = (service, sum, comment, promocode) => {
    const data = {
      service,
      sum,
      comment,
      promocode,
    };
    this.props
      .orderPayment(data)
      .then((resp) => {
        this.setState({ orderingStatus: resp });
        this.props.closeDialog(this.props.currentDialog);
        this.props.openNextDialog(this.props.nextDialog);
      })
      .catch((resp) => {
        this.setState({ orderingError: resp });
      });
  };

  checkPromo = (event) => {
    event.preventDefault();
    const { promoEntered } = this.state;
    const data = {
      promo_code: promoEntered,
    };
    this.props
      .checkPromoCode(data)
      .then((resp) => {
        if (resp !== null) {
          this.setState({ promo: resp });
        } else {
          this.setState({
            promo: resp,
            promoError: true,
          });
        }
      })
      .catch((resp) => {
        this.setState({ checkingPromoError: resp });
      });
  };

  render() {
    const { promo, promoError } = this.state;
    let codeValue = null;
    let discountValue = null;
    let { sum, t } = this.props;
    const {
      currencyIcon,
      currency,
      service,
      comment,
      regReason,
      country,
      orderingVatReg,
    } = this.props;
    if (promo) {
      sum = Math.round((this.props.sum / 100) * (100 - promo.discount));
      discountValue = promo.discount / 100;
      codeValue = promo.code;
    }

    const styles = {
      table: {
        width: "100%",
      },
      tableRow: {
        display: "flex",
        justifyContent: "space-between",
        minHeight: "25px",
      },
      tableHeader: {
        height: "30px",
        textAlign: "left",
        paddingTop: "10px",
        fontWeight: 400,
        color: "#aaaaaa",
      },
      tableHeaderRight: {
        height: "30px",
        textAlign: "right",
        paddingTop: "10px",
        fontWeight: 400,
        color: "#aaaaaa",
      },
      tableRowColumn: {
        verticalAlign: "middle",
        height: "25px",
        textAlign: "center",
        padding: "5px 0 5px 0",
        maxWidth: "60%",
      },
      "tableRowColumn:first-child": {
        textAlign: "right",
      },
      tableRowColumnLeft: {
        verticalAlign: "middle",
        textAlign: "left",
        paddingTop: "5px",
        paddingBottom: "5px",
        maxWidth: "60%",
      },
    };
    return (
      <div>
        {service ===
          "VAT registration" /* PPL buttons now avalible only for VAT registration */ && (
          <div>
            <table className='tbl_full' style={styles.table}>
              <tbody>
                <tr className='tr_dlg' style={styles.tableRow}>
                  <th className='th_dlg' style={styles.tableHeader}>
                    {t("payment.desc")}
                  </th>
                  {/* <th className="th_dlg" style={styles.tableHeader}>{t("payment.unitPrice")}</th> */}
                  {/* <th className="th_dlg" style={styles.tableHeader}>{t("payment.qty")}</th> */}
                  <th className='th_dlg' style={styles.tableHeaderRight}>
                    {t("payment.total")}
                  </th>
                </tr>

                <tr className='tr_dlg' style={styles.tableRow}>
                  <td className='td_dlg' style={styles.tableRowColumnLeft}>
                    {comment}
                  </td>
                  {/* <td className="td_dlg" style={styles.tableRowColumn}>
                    {this.props.sum} {currencyIcon}
                  </td> */}
                  {/* <td className="td_dlg" style={styles.tableRowColumn}>
                     &mdash;
                  </td> */}
                  <td className='td_dlg' style={styles.tableRowColumn}>
                    {this.props.sum || 0} {currencyIcon}
                  </td>
                </tr>
                <tr className='tr_dlg' style={styles.tableRow}>
                  <td
                    className='td_dlg'
                    style={styles.tableRowColumnLeft}
                    colSpan={3}
                  >
                    {t("payment.promo")}
                  </td>
                  <td className='td_dlg' style={styles.tableRowColumn}>
                    {codeValue ? `- ${this.props.sum * discountValue}` : 0}{" "}
                    {currencyIcon}
                  </td>
                </tr>

                <tr style={styles.tableRow}>
                  <td
                    className='td_dlg'
                    style={styles.tableRowColumnLeft}
                    colSpan={3}
                  >
                    <span style={{ fontWeight: "bold" }}>Sub.total</span>
                  </td>
                  <td className='td_dlg' key='' style={styles.tableRowColumn}>
                    <span style={{ fontWeight: "bold" }}>
                      {Math.round(sum) || 0} {currencyIcon}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <form autoComplete='off'>
              <FormLayout>
                <FormLayout.Group>
                  <Stack distribution='fill'>
                    <TextField
                      placeholder='Enter promo code'
                      value={this.state.promoEntered}
                      onChange={(promoEntered) =>
                        this.setState({ promoEntered })
                      }
                    />
                    <Button onClick={this.checkPromo} primary fullWidth>
                      {t("payment.apply")}
                    </Button>
                  </Stack>
                </FormLayout.Group>
              </FormLayout>
            </form>

            <Stack distribution='trailing'>
              {promo && (
                <TextStyle variation='positive'>
                  {t("payment.promoApplied")}
                </TextStyle>
              )}
              {promoError && !promo && (
                <TextStyle variation='negative'>
                  {t("payment.invalidePromo")}
                </TextStyle>
              )}
              <br />
            </Stack>

            <br />
            <StripePayment
              service={service}
              data={{
                comment,
                country,
                reason_for_registration: regReason,
                service,
              }}
              promoCode={codeValue}
              onClose={() => this.props.closeDialog(this.props.currentDialog)}
            />
            <br />
            <Text alignment='center'>
              or you can{" "}
              <Link
                onClick={() =>
                  this.pay(service, comment, codeValue, country, regReason)
                }
              >
                {t("payment.orderInv")}
              </Link>
            </Text>
          </div>
        )}
        {service === "VAT payment" && (
          <div>
            <p>
              {comment}, sum: {this.props.sum} {currency}
            </p>
            <br />
            <a
              className='Polaris-Link'
              onClick={() =>
                this.doRequestOfFunds(
                  service,
                  sum,
                  comment + ", currency: " + currency,
                  codeValue
                )
              }
            >
              {t("payment.reqOfFunds")}
            </a>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orderingVatReg: state.payment.ordering,
  orderingError: state.payment.orderingError,
  defaultLanguage: state.user.defaultLanguage,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    orderPayment: (params) => dispatch(orderPayment(params)),
    checkPromoCode: (params) => dispatch(checkPromoCode(params)),
    orderVatRegRedirect: (params) => dispatch(orderVatRegRedirect(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Payment))
);
