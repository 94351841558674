import { Button, Checkbox } from '@shopify/polaris'
import Modal from 'components/Modal'
import React from 'react'

const ModalForAutopilotDetails = ({showSaveModal, saveConfirmed, onHide, checkedStatus, setCheckedStatus}) => {
	return (
		<Modal
			iconType={"warning"}
			title={'Do you want to activate Autopilot?'}
			visible={showSaveModal}
			contentOnCenter={true}
			onClose={() => onHide(false)}
			content={
				<div
					style={{
						display: "flex",
						alignItems: "center",
						maxWidth: "400px",
						flexDirection: 'column',
						justifyContent: 'center'
					}}
				>
          <div style={{display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginTop: 20}}>
             <Checkbox
	             label={'I agree to turn on this feature for free for the first month. Then it will be charged 10% of monthly payment amount.'}
	             checked={checkedStatus}
	             onChange={() => setCheckedStatus(!checkedStatus)}
             />
          </div >
						<div
							style={{
								width: '100%',
								display: "flex",
								alignItems: "center",
								justifyContent: 'space-around',
								marginTop: 20
							}}
						>
            <Button
	            size='large'
	            onClick={() => onHide(false)}
            >
	            Cancel
						</Button >
            <Button
	            size='large'
	            primary
	            onClick={saveConfirmed}
	            disabled={!checkedStatus}
            >
	            Turn on
						</Button >
          </div >
        </div >
			}
		/>
	)
}

export default ModalForAutopilotDetails