import { Badge, Checkbox } from '@shopify/polaris'
import React from 'react'
import useSelectedRows from '../tabs/Invoices/hooks/useSelectedRows'

const getBadgeStatus = (status) => {
	switch (status) {
		case "sent":
		case "paid":
			return "success";
		case "draft":
			return "new";
		default:
			return "attention";
	}
};

export default function GetColumnsEx(changeInOrOut, saveDocumentLength, resFilForDocument) {
	const {selectedRows, handleSelectAll, handleRowSelect} =
		useSelectedRows(resFilForDocument)
	
	
	const columns = [
		{
			property: "checkbox",
			header: {
				label: (
					<Checkbox
						label=''
						checked={
							selectedRows.length > 0 &&
							selectedRows.length === saveDocumentLength
						}
						onChange={handleSelectAll}
					/>
				),
			},
			cell: {
				formatters: [
					(value, { rowData }) => (
						<Checkbox
							label=''
							checked={selectedRows.includes(rowData.id)}
							onChange={() => handleRowSelect(rowData.id)}
						/>
					),
				],
			},
		},
		{
			property: changeInOrOut === 'in' ? 'name' : 'name',
			header: { label: `Company Name` },
			cell: {
				formatters: [
					(value, { rowData }) => rowData?.company_name
				],
			}
		},
		{
			property: changeInOrOut === 'in' ? 'name' : 'name',
			header: { label: `${changeInOrOut === 'in' ? 'Buyer location' : 'Seller address'}` },
			cell: {
				formatters: [
					(value, { rowData }) => rowData?.cert_adress
				],
			}
		},
		{
			property: "type_of_exemption",
			header: { label: "Exemption type" },
		},
		{
			property: "exempt_region",
			header: { label: "Exemption region" },
		},
		{
			property: "cert_number",
			header: { label: "Certificate" },
		},
		{
			property: "end_date",
			header: { label: "Expiration date" },
		},
		{
			property: "status",
			header: { label: "Status" },
			cell: {
				formatters: [
					(value) => (
						<Badge status={getBadgeStatus(value)}>{value}</Badge>
					),
				],
			},
		},
	];
	return columns
}