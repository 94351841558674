import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Stepper from "components/Stepper";
import withRouter from "helpers/withRouter";
import {
  Card,
  Layout,
  Button,
  TextStyle,
  ButtonGroup,
  TextField,
  FormLayout,
  Text,
  Stack,
} from "@shopify/polaris";
import { preciseRound } from "utils/numbers";
import SaveBar from "components/SaveBar/SaveBar";
import OSSPreview from "../Previews/OSSPreview";
import Modal from "components/Modal";
import _ from "lodash";
import { createDeclaration } from "redux/features/declarations/declarationsSlice";

import step1IMG from "img/step1.svg";
import RenderCreatedReturnDialog from "../common/RenderCreatedReturnDialog";
import { createCustomDispatch } from "helpers/customDispatch";

class OSSReturn extends Component {
  constructor(props) {
    super(props);
    const data = {};
    props.returnData.data.map((item) => {
      if (
        !_.isEmpty(
          item.sales_of_services.filter(
            (country) => country.total_sum !== 0 || country.total_vat !== 0
          )
        )
      ) {
        data[item.country_id] = {};
        data[item.country_id].sales_of_services = item.sales_of_services;
      }
    });

    this.state = {
      stepIndex: 1, // 0 - intro in Create Return
      data,

      actionDialogs: {
        reset: { open: false },
        created: { open: false },
      },
    };
  }

  getStepContent(stepIndex) {
    switch (stepIndex) {
      /* case 0:
            return this.step1();*/
      case 1:
        return this.step2();
      case 2:
        return this.step3();
      default:
        return "";
    }
  }

  handleNext = () => {
    const { stepIndex } = this.state;
    if (stepIndex < 2) {
      this.setState({
        stepIndex: stepIndex + 1,
        month: false,
        year: false,
      });
    }
  };

  handlePrev = () => {
    const { stepIndex } = this.state;
    if (stepIndex > 1) {
      this.setState({ stepIndex: stepIndex - 1 });
    }
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  updateInput = (country, id, vatPercent, value, type) => {
    let data = { ...this.state.data };
    if (!data[country]) {
      data = {
        ...data,
        [country]: {
          [type]: [],
        },
      };
      data[country][type][id] = {
        total_sum: value,
        vat_percent: vatPercent,
        total_vat: (value * vatPercent) / 100,
      };
    } else if (data[country] && !data[country][type]) {
      data[country][type] = [];
      data[country][type][id] = {
        total_sum: value,
        vat_percent: vatPercent,
        total_vat: (value * vatPercent) / 100,
      };
    } else {
      data[country][type] = data[country][type] ? [...data[country][type]] : [];
      data[country][type][id] = data[country][type][id]
        ? { ...data[country][type][id] }
        : {};
      data[country][type][id].total_sum = value;
      data[country][type][id].vat_percent = vatPercent;
      data[country][type][id].total_vat = (value * vatPercent) / 100;
    }
    this.setState({
      data,
    });
  };

  numberInput = (
    type,
    id,
    country,
    name,
    value,
    currency,
    vatPercent,
    onChangeFunc
  ) => {
    const uniqueKey = id + country + vatPercent;
    const { data } = this.state;

    const initValue =
      data[country] && data[country][type] && data[country][type][id]
        ? data[country][type][id].total_sum *
          data[country][type][id].vat_percent
        : value * vatPercent;

    const isSummReserve = data[id] && data[id].summ_reserve;

    return (
      <TextField
        id={uniqueKey}
        key={uniqueKey}
        name={uniqueKey}
        type='number'
        step={0.01}
        suffix={"  " + currency}
        value={
          name === "total_sum" || name === "vat_percent"
            ? `${preciseRound(value)}`
            : `${preciseRound(initValue)}`
        }
        placeholder='0,00'
        disabled={name !== "total_sum" || isSummReserve}
        onChange={(v) => {
          if (name === "total_sum") {
            onChangeFunc(country, id, vatPercent, +v, type);
          }
        }}
      />
    );
  };

  calculateSummary = (data2, key, v) => {
    let sum = 0;
    const f = []
      .concat(...Object.values(data2))
      .filter((i) => i[key])
      .map((dataItem) => dataItem[key].filter(Boolean));
    if (!_.isEmpty(f)) {
      sum = f
        .map((i) =>
          []
            .concat(
              ...i.map((a) =>
                v === "sum" ? a.total_sum : (a.total_sum * a.vat_percent) / 100
              )
            )
            .reduce((prev, current) => prev + current, 0)
        )
        .reduce((prev, current) => prev + current, 0);
    }
    return sum;
  };

  downloadFile(link) {
    const from = link.indexOf("/") + 1;
    const fileName = link.substring(from);
    const a = document.createElement("a");
    a.setAttribute("href", link);
    a.setAttribute("download", fileName);
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(link);
    document.body.removeChild(a);
  }

  fillReturn = (returnParams, data, finalType) => {
    const filteredData = { ...data };
    for (const country in filteredData) {
      if (this.state.data.hasOwnProperty(country)) {
        if (filteredData[country].sales_of_services) {
          filteredData[country].sales_of_services = filteredData[
            country
          ].sales_of_services
            .filter(Boolean)
            .filter((item) => item.total_sum !== 0);
        }
        if (_.isEmpty(filteredData[country])) {
          delete filteredData[country];
        }
      }
    }

    const params = {
      ...returnParams,
      data: filteredData,
    };

    if (finalType) {
      params[finalType] = true;
    }

    this.props
      .createDeclaration(params)
      .then((result) => {
        if (finalType) {
          this.setState({
            addingResult: result || true,
            addingError: null,
          });
          if (finalType === "report") {
            this.downloadFile(result);
          } else {
            this.handleActionDialogsOpen("created", { finalType });
          }
        } else {
          this.setState({
            previewResult: result,
            addingError: null,
          });
          this.handleNext();
        }
      })
      .catch((result) => {
        this.handleActionDialogsOpen("created", {});
        this.setState({
          addingError: result,
        });
      });
  };

  step2() {
    const { returnParams, returnData, creating, t } = this.props;
    const { data } = this.state;

    const renderTypeBlock = (rates, item) =>
      rates.map((rate, ind) => {
        const itemType = "sales_of_services";
        const updatedInd =
          ind < item.sales_of_services.length
            ? ind
            : ind - item.sales_of_services.length;

        const initTotalSum =
          data[item.country_id] &&
          data[item.country_id][itemType] &&
          data[item.country_id][itemType][updatedInd] &&
          data[item.country_id][itemType][updatedInd].total_sum;
        const initVatSum =
          data[item.country_id] &&
          data[item.country_id][itemType] &&
          data[item.country_id][itemType][updatedInd] &&
          (data[item.country_id][itemType][updatedInd].total_sum *
            data[item.country_id][itemType][updatedInd].vat_percent) /
            100;

        return (
          <div
            key={`${ind}row`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              marginBottom: "1rem",
            }}
          >
            {ind === 0 ? (
              <div style={{ width: "20%" }}>
                <TextStyle variation='subdued'>{item.country}</TextStyle>
              </div>
            ) : (
              <div style={{ width: "20%" }} />
            )}

            <div style={{ width: "15%" }}>
              {this.numberInput(
                itemType,
                updatedInd,
                item.country_id,
                "total_sum",
                initTotalSum || initTotalSum === 0
                  ? initTotalSum
                  : rate.total_sum,
                returnData.currency,
                rate.vat_percent,
                this.updateInput
              )}
            </div>

            <div style={{ width: "30%" }}>
              {this.numberInput(
                itemType,
                updatedInd,
                item.country_id,
                "vat_percent",
                rate.vat_percent,
                "%"
              )}
            </div>

            <div style={{ width: "30%" }}>
              {this.numberInput(
                itemType,
                updatedInd,
                item.country_id,
                "vat_percent",
                initVatSum || initVatSum === 0 ? initVatSum : rate.total_vat,
                returnData.currency
              )}
            </div>
          </div>
        );
      });

    return (
      <div>
        {this.renderSummary()}
        <Card sectioned>
          <FormLayout>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <div style={{ width: "20%" }}>
                <TextStyle variation='strong'>{"Country"}</TextStyle>
              </div>
              <div style={{ width: "15%", textAlign: "center" }}>
                <TextStyle variation='strong'>
                  {"Revenue"} ({returnData.currency})
                </TextStyle>
              </div>
              <div style={{ width: "30%", textAlign: "center" }}>
                <TextStyle variation='strong'>VAT rate</TextStyle>
              </div>
              <div style={{ width: "30%", textAlign: "center" }}>
                <TextStyle variation='strong'>
                  {t("createReturns.vatSum")}
                </TextStyle>
              </div>
            </div>

            {returnData.data.map((item, i) =>
              renderTypeBlock(item.sales_of_services, item)
            )}
          </FormLayout>
        </Card>

        <SaveBar title={t("createReturns.createVR")}>
          <ButtonGroup>
            <Button
              disabled={creating}
              onClick={() => this.handleActionDialogsOpen("reset", {})}
            >
              {t("createReturns.back")}
            </Button>
            <Button
              primary
              loading={creating}
              onClick={() => this.fillReturn(returnParams, data)}
            >
              {t("createReturns.next")}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </div>
    );
  }

  step3() {
    const { returnParams, creating, t } = this.props;
    const { previewResult, data } = this.state;

    return (
      <div>
        <OSSPreview
          sumServices={previewResult.sales_of_services}
          vatServices={previewResult.vat_of_services}
          currency={previewResult.currency}
          data={previewResult.data}
        />

        <SaveBar title={t("createReturns.createVR")}>
          <ButtonGroup>
            <Button disabled={creating} onClick={() => this.handlePrev()}>
              {t("createReturns.back")}
            </Button>
            <Button
              primary
              disabled={creating}
              onClick={() => this.fillReturn(returnParams, data, "submit")}
            >
              {"Submit"}
            </Button>
            <Button
              primary
              disabled={creating}
              onClick={() => this.fillReturn(returnParams, data, "save")}
            >
              {"Save"}
            </Button>
            <Button
              primary
              disabled={creating}
              onClick={() => this.fillReturn(returnParams, data, "report")}
            >
              {"Download report"}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </div>
    );
  }

  renderResetDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={t("createReturns.warning")}
        visible={actionDialogs.reset.open}
        onClose={() => this.handleActionDialogsClose("reset")}
        contentOnCenter
        iconType={"warning"}
        description={
          <>
            <p>{t("createReturns.willLose")}</p>
            <p>{t("createReturns.wantComeBack")}</p>
          </>
        }
        footer={
          <Stack distribution='center'>
            <Button
              onClick={() => {
                this.props.reset();
                this.handleActionDialogsClose("reset");
              }}
            >
              {t("createReturns.toTheFirst")}
            </Button>
          </Stack>
        }
      />
    );
  }

  renderCreatedReturnDialog() {
    return (
      <RenderCreatedReturnDialog
        state={this.state}
        declarationType={this.props.title}
        handleActionDialogsClose={this.handleActionDialogsClose}
      />
    );
  }

  renderSummary = () => {
    const { returnData, t } = this.props;
    const { data } = this.state;

    let sumServices = returnData.sales_of_services;
    let vatServices = returnData.vat_of_services;

    if (!_.isEmpty(data)) {
      sumServices = this.calculateSummary(data, "sales_of_services", "sum");
      vatServices = this.calculateSummary(data, "sales_of_services", "vat");
    }

    return (
      <OSSPreview
        sumServices={sumServices}
        vatServices={vatServices}
        currency={returnData.currency}
      />
    );
  };

  render() {
    const { stepIndex } = this.state;
    const { t, title } = this.props;

    const steps = [
      {
        title: t("createReturns.returnParams"),
        icon: step1IMG,
      },
      {
        title: t("createReturns.outputVat"),
        icon: step1IMG,
      },
      {
        title: t("createReturns.vatDue"),
        icon: step1IMG,
      },
    ];

    return (
      <div>
        <Layout>
          <Layout.Section secondary>
            {stepIndex > 0 && (
              <Stepper
                steps={steps}
                activeStep={stepIndex}
                activeColor='#E4F3FE'
                completeColor='#216DC5'
                circleFontColor='#212B36'
                defaultTitleColor='#919EAB'
                completeTitleColor='#919EAB'
                activeTitleColor='#212B36'
                completeBorderColor={"#367C41"}
                defaultBorderWidth={4}
                defaultBarColor='#ADADAD'
                size={24}
                circleFontSize={14}
              />
            )}
          </Layout.Section>
          <Layout.Section>
            <div style={{ marginBottom: 102 }}>
              <Text variant='headingMd'>{title}</Text>
              <br />
              {this.getStepContent(stepIndex)}
            </div>
          </Layout.Section>
        </Layout>

        {this.renderResetDialog()}
        {this.renderCreatedReturnDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  defaultLanguage: state.user.defaultLanguage,
  creating: state.declarations.creating,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    createDeclaration: (params) => dispatch(createDeclaration(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(OSSReturn))
);
