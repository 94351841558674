import React from "react";

const VATReturnsIcon = ({ selected }) => {
  if (selected) {
    return (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.33398 18.3332H12.6673C14.0674 18.3332 14.7675 18.3332 15.3023 18.0607C15.7727 17.821 16.1552 17.4386 16.3948 16.9681C16.6673 16.4334 16.6673 15.7333 16.6673 14.3332V5.6665C16.6673 4.26637 16.6673 3.56631 16.3948 3.03153C16.1552 2.56112 15.7727 2.17867 15.3023 1.93899C14.7675 1.6665 14.0674 1.6665 12.6673 1.6665H12.2507C11.5826 1.6665 11.2485 1.6665 10.949 1.76317C10.6839 1.8487 10.4397 1.98864 10.2318 2.17403C9.99698 2.38354 9.82803 2.67176 9.49012 3.2482L3.62899 13.2466C3.60875 13.2811 3.59863 13.2984 3.58901 13.3156C3.42831 13.6025 3.34085 13.9246 3.33437 14.2535C3.33398 14.2731 3.33398 14.2932 3.33398 14.3332C3.33398 15.7333 3.33398 16.4334 3.60647 16.9681C3.84615 17.4386 4.2286 17.821 4.69901 18.0607C5.23379 18.3332 5.93385 18.3332 7.33398 18.3332Z'
          fill='#267FD7'
        />
        <path
          d='M4.65369 7.04357L4.65354 7.04405C4.64704 7.06422 4.64055 7.08441 4.63406 7.10463C4.62773 7.08419 4.62138 7.06371 4.61501 7.04321L4.01372 5.06712L3.53537 5.21267L4.01372 5.06712C3.91121 4.73024 3.60049 4.5 3.24837 4.5H2.91429C2.36973 4.5 1.98432 5.03225 2.15423 5.54962L3.53631 9.75795C3.64416 10.0863 3.95073 10.3083 4.29637 10.3083H4.88786C5.22755 10.3083 5.5302 10.0938 5.64271 9.77329L7.11989 5.56496C7.30251 5.0447 6.91643 4.5 6.36505 4.5H6.05771C5.71064 4.5 5.40315 4.72379 5.29645 5.05406L5.77224 5.20777L5.29645 5.05406L4.65369 7.04357ZM9.80047 5.04935L9.32564 5.20601L9.80047 5.04935C9.6923 4.72149 9.386 4.5 9.04075 4.5H8.33827C7.99182 4.5 7.68474 4.72301 7.57753 5.05245L6.20813 9.26079L6.68359 9.4155L6.20813 9.26079C6.03991 9.77775 6.42522 10.3083 6.96886 10.3083H7.25428C7.60531 10.3083 7.91533 10.0795 8.01876 9.74405L7.54096 9.59673L8.01876 9.74405L8.26491 8.94573H9.07528L9.33774 9.75511C9.44471 10.0849 9.75198 10.3083 10.0987 10.3083H10.4292C10.9742 10.3083 11.3597 9.77526 11.1889 9.25768L9.80047 5.04935ZM11.3704 9.50833C11.3704 9.95016 11.7286 10.3083 12.1704 10.3083H12.4614C12.9032 10.3083 13.2614 9.95016 13.2614 9.50833V6.2348H13.8667C14.3085 6.2348 14.6667 5.87663 14.6667 5.4348V5.3C14.6667 4.85817 14.3085 4.5 13.8667 4.5H10.7796C10.3378 4.5 9.9796 4.85817 9.9796 5.3V5.4348C9.9796 5.87663 10.3378 6.2348 10.7796 6.2348H11.3704V9.50833Z'
          fill='black'
          stroke='white'
        />
      </svg>
    );
  }
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.12'
        d='M7.3335 18.3337H12.6668C14.067 18.3337 14.767 18.3337 15.3018 18.0612C15.7722 17.8215 16.1547 17.439 16.3943 16.9686C16.6668 16.4339 16.6668 15.7338 16.6668 14.3337V5.66699C16.6668 4.26686 16.6668 3.5668 16.3943 3.03202C16.1547 2.56161 15.7722 2.17916 15.3018 1.93948C14.767 1.66699 14.067 1.66699 12.6668 1.66699H10.4168L3.3335 13.7503V14.3337C3.3335 15.7338 3.3335 16.4339 3.60598 16.9686C3.84566 17.439 4.22811 17.8215 4.69852 18.0612C5.2333 18.3337 5.93336 18.3337 7.3335 18.3337Z'
        fill='#707070'
      />
      <path
        d='M10.4168 1.66699H12.6668C14.067 1.66699 14.767 1.66699 15.3018 1.93948C15.7722 2.17916 16.1547 2.56161 16.3943 3.03202C16.6668 3.5668 16.6668 4.26686 16.6668 5.66699V14.3337C16.6668 15.7338 16.6668 16.4339 16.3943 16.9686C16.1547 17.439 15.7722 17.8215 15.3018 18.0612C14.767 18.3337 14.067 18.3337 12.6668 18.3337H7.3335C5.93336 18.3337 5.2333 18.3337 4.69852 18.0612C4.22811 17.8215 3.84566 17.439 3.60598 16.9686C3.3335 16.4339 3.3335 15.7338 3.3335 14.3337V13.7503'
        stroke='#707070'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.88786 9.80833C5.01525 9.80833 5.12874 9.72789 5.17093 9.60769L6.64811 5.39936C6.7166 5.20426 6.57181 5 6.36505 5H6.05771C5.92756 5 5.81225 5.08392 5.77224 5.20777L5.12948 7.19728C4.94191 7.77988 4.76153 8.36248 4.63126 8.93099C4.63032 8.93509 4.62668 8.93798 4.62248 8.93798C4.61822 8.93798 4.61454 8.93499 4.61366 8.93083C4.49067 8.34845 4.32465 7.79406 4.13709 7.19015L3.53537 5.21267C3.49693 5.08634 3.38041 5 3.24837 5H2.91429C2.71008 5 2.56555 5.19959 2.62927 5.39361L4.01135 9.60194C4.0518 9.72509 4.16676 9.80833 4.29637 9.80833H4.88786Z'
        fill='#707070'
      />
      <path
        d='M9.22067 8.44573C9.3507 8.44573 9.46593 8.5295 9.50604 8.65319L9.81336 9.60087C9.85347 9.72456 9.9687 9.80833 10.0987 9.80833H10.4292C10.6336 9.80833 10.7781 9.60843 10.7141 9.41434L9.32564 5.20601C9.28508 5.08306 9.17021 5 9.04075 5H8.33827C8.20835 5 8.09319 5.08363 8.05299 5.20717L6.68359 9.4155C6.6205 9.60936 6.765 9.80833 6.96886 9.80833H7.25428C7.38591 9.80833 7.50217 9.72252 7.54096 9.59673L7.83061 8.65734C7.86939 8.53155 7.98565 8.44573 8.11729 8.44573H9.22067ZM8.45096 7.78227C8.24793 7.78227 8.10353 7.58482 8.16507 7.39134L8.4174 6.59802C8.49642 6.31504 8.57543 5.98296 8.64738 5.70611C8.64846 5.70198 8.65218 5.69913 8.65644 5.69913C8.66071 5.69913 8.66443 5.70199 8.6655 5.70612C8.73746 5.98284 8.81659 6.30797 8.90998 6.59802L9.16664 7.38975C9.22947 7.58355 9.08499 7.78227 8.88127 7.78227H8.45096Z'
        fill='#707070'
      />
      <path
        d='M11.8704 9.50833C11.8704 9.67402 12.0047 9.80833 12.1704 9.80833H12.4614C12.6271 9.80833 12.7614 9.67402 12.7614 9.50833V6.0348C12.7614 5.86912 12.8957 5.7348 13.0614 5.7348H13.8667C14.0324 5.7348 14.1667 5.60049 14.1667 5.4348V5.3C14.1667 5.13431 14.0324 5 13.8667 5H10.7796C10.6139 5 10.4796 5.13431 10.4796 5.3V5.4348C10.4796 5.60049 10.6139 5.7348 10.7796 5.7348H11.5704C11.7361 5.7348 11.8704 5.86912 11.8704 6.0348V9.50833Z'
        fill='#707070'
      />
    </svg>
  );
};

export default VATReturnsIcon;
