import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import withRouter from "helpers/withRouter";
import { formatDate, formatDateForServer } from "utils/dates";
import _ from "lodash";
/*import strings from "./locales";*/
import { editNews, fetchNews } from "redux/features/dashboard/dashboardSlice";
import {
  Page,
  Layout,
  Card,
  Stack,
  TextStyle,
  TextField,
  DatePicker,
  Button,
  Banner,
  Spinner,
  Text,
  Inline,
} from "@shopify/polaris";
import PageHelmet from "components/PageHelmet";
import Datepicker from "components/Datepicker/Datepicker";
import { createCustomDispatch } from "helpers/customDispatch";

const NewsItem = (props) => {
  const { user, fetchingNews } = props;
  const { newsId } = useParams();
  const [newsItem, setNewsItem] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [selected, setSelected] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [text, setText] = useState("");
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [editingError, setEditingError] = useState(false);
  const [visibilityDatePicker, setVisibilityDatePicker] = useState(false);

  useEffect(() => {
    props.fetchNews();
  }, []);

  useEffect(() => {
    setNewsItem(props.news.find((item) => item.id === +newsId));
  }, [JSON.stringify(props.news)]);

  const doEditNews = function () {
    const formData = {
      id: newsItem.id,
      date: selected ? formatDateForServer(selected) : newsItem.date,
      description: description ? description : newsItem.description,
      text: text ? text : newsItem.text,
      title: title ? title : newsItem.title,
    };

    props
      .editNews(formData)
      .then(() => {
        props.navigate("/dashboard/news");
      })
      .catch((err) => setEditingError("Error"));
  };

  return (
    <Page
      fullWidth
      separator
      title={
        <Text variant='heading3xl' as='span'>
          Article
        </Text>
      }
    >
      <PageHelmet title={newsItem?.title || ""} />

      <Layout>
        <Layout.Section>
          {fetchingNews && <Spinner />}
          {!fetchingNews && newsItem && !isEdit && (
            <Card
              title={
                <Inline align='space-between' alignY='center'>
                  <Text variant='headingLg' as='h3'>
                    {newsItem.title}
                  </Text>
                  <Inline spacing='4' alignY='center'>
                    <Text color='subdued'>{formatDate(newsItem.date)}</Text>
                    {user.is_manager && (
                      <Button plain onClick={() => setIsEdit(true)}>
                        Edit
                      </Button>
                    )}
                  </Inline>
                </Inline>
              }
              sectioned
            >
              <TextStyle variation='subdued'>
                <span
                  dangerouslySetInnerHTML={{ __html: newsItem.description }}
                />
              </TextStyle>
              <br />
              <br />
              <div dangerouslySetInnerHTML={{ __html: newsItem.text }} />
            </Card>
          )}

          {newsItem && isEdit && user.is_manager && (
            <Card sectioned>
              {editingError && (
                <Banner title={editingError} status='critical' />
              )}
              <Stack>
                <Datepicker
                  label='Date'
                  value={selected ? selected : newsItem.date}
                  allowRange={false}
                  onChange={(startDate, endDate) => {
                    // const selected = {
                    //   start: startDate,
                    //   end: startDate,
                    // };
                    setSelected(startDate);
                  }}
                />
                <Stack.Item fill>
                  <TextField
                    label='Title'
                    value={title ? title : newsItem.title}
                    onChange={(value) => setTitle(value)}
                  />
                </Stack.Item>
              </Stack>
              <br />
              <TextField
                label='Short description'
                value={description ? description : newsItem.description}
                onChange={(value) => setDescription(value)}
                multiline={4}
              />
              <br />
              <TextField
                label='Text'
                value={text ? text : newsItem.text}
                onChange={(value) => setText(value)}
                multiline={10}
              />
              <br />
              <Stack alignment='leading' distribution='equalSpacing'>
                <br />
                <Button
                  disabled={!text && !description && !title && !selected}
                  primary
                  onClick={doEditNews}
                >
                  Edit news
                </Button>
              </Stack>
            </Card>
          )}
        </Layout.Section>
      </Layout>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  news: state.dashboard.news,
  fetchingNews: state.dashboard.fetchingNews,
  defaultLanguage: state.user.defaultLanguage,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchNews: (params) => dispatch(fetchNews(params)),
    editNews: (params) => dispatch(editNews(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(NewsItem))
);
