import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  Page,
  Layout,
  FormLayout,
  Card,
  TextStyle,
  DatePicker,
  TextField,
  Select,
  Button,
  Banner,
  Text,
} from "@shopify/polaris";
import {
  createPayment,
  fetchPendingInvoices,
} from "redux/features/manager/managerSlice";
import moment from "moment";
import { formatDate, formatDateForServer } from "utils/dates";
import Datepicker from "components/Datepicker/Datepicker";
import PageHelmet from "components/PageHelmet";
import { createCustomDispatch } from "helpers/customDispatch";

const optionsCurrency = [
  { label: "EUR", value: "eur" },
  { label: "USD", value: "usd" },
];

const optionsTypes = [
  { label: "offline", value: "offline" },
  { label: "online", value: "online" },
];

class AddPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sum: 0,
      currency: "eur",
      defaultDate: formatDate(moment().toDate()),
      number: "",
      invoice: "",
      type: "offline",

      monthDate: new Date().getMonth(),
      yearDate: new Date().getFullYear(),
      selectedDate: {
        start: moment().toDate(),
        end: moment().toDate(),
      },
    };
  }

  componentDidMount() {
    this.props.fetchPendingInvoices();
  }

  doReset = () => {
    this.setState({
      sum: 0,
      currency: "eur",
      defaultDate: formatDate(moment().toDate()),
      number: "",
      numberError: false,
      invoice: "",
      type: "offline",

      monthDate: new Date().getMonth(),
      yearDate: new Date().getFullYear(),
      selectedDate: {
        start: moment().toDate(),
        end: moment().toDate(),
      },
    });
  };

  doAddPayment = (event) => {
    event.preventDefault();
    const { selectedDate, number, sum, currency, invoice, type } = this.state;

    if (!number) {
      this.setState({ numberError: "Please enter data" });
      return;
    }

    const formData = {
      date: formatDateForServer(selectedDate.end),
      sum,
      number,
      currency_code: currency,
      invoice,
      type,
    };

    this.props
      .createPayment({ ...formData })
      .then(() => {
        this.doReset();
        this.setState({ addingPayment: true });
      })
      .catch((err) => {
        this.setState({ errorAddPayment: err.error });
      });
  };

  handleMonthChangeData = (monthPeriod, yearPeriod) => {
    this.setState({
      monthPeriod,
      yearPeriod,
    });
  };

  handleDateChangeStartData = (date) => {
    const { selectedPeriod } = this.state;
    let fromDate = formatDate(date.start);
    if (selectedPeriod.end) {
      selectedPeriod.start = date.start;
      fromDate = formatDate(date.start);
    }
    if (!selectedPeriod.end) {
      selectedPeriod.start = date.end;
      fromDate = formatDate(date.end);
    }

    this.setState({
      selectedPeriod,
      visibilityDatePickerFrom: false,
      from: fromDate,
      fromError: false,
    });
  };

  handleDateChangeEndData = (date) => {
    const { selectedPeriod } = this.state;
    selectedPeriod.end = date.end;
    const toDate = formatDate(date.end);
    this.setState({
      selectedPeriod,
      visibilityDatePickerTo: false,
      till: toDate,
      tillError: false,
    });
  };

  handleChangeData = (value, name, errorName) => {
    if (value.length > 0) {
      this.setState({
        [name]: value,
        [errorName]: false,
      });
    } else {
      this.setState({
        [name]: "",
        [errorName]: `Please enter data`,
      });
    }
  };

  render() {
    const {
      visibilityDatePicker,
      errorAddPayment,
      addingPayment,
      defaultDate,
    } = this.state;
    const { pendingInvoices } = this.props;

    const optionsInvoices = [
      {
        value: "",
        label: "",
      },
    ];

    pendingInvoices.map((invoice) => {
      optionsInvoices.push({
        value: `${invoice.number}`,
        label: `${invoice.number} ${invoice.date}`,
      });
    });

    return (
      <Page
        title={
          <Text variant='heading3xl' as='span'>
            Add payment
          </Text>
        }
        fullWidth
        separator
      >
        <PageHelmet title={"Add payment"} />

        <Layout>
          <Layout.AnnotatedSection
            title='Add payment'
            description={
              <TextStyle variation='subdued'>
                Please enter payment information
              </TextStyle>
            }
          >
            <Layout.Section>
              <Card sectioned>
                {errorAddPayment && (
                  <Banner title='Error' status='critical'>
                    {errorAddPayment}
                  </Banner>
                )}

                {addingPayment && (
                  <div>
                    <Banner
                      status='success'
                      onDismiss={() => {
                        this.setState({ addingPayment: false });
                      }}
                    >
                      Payment has been successfully added
                    </Banner>
                    <br />
                  </div>
                )}

                <FormLayout>
                  <FormLayout.Group>
                    <Datepicker
                      label='Date'
                      allowRange={false}
                      value={
                        this.state.selectedDate.end
                          ? this.state.selectedDate.end
                          : defaultDate
                      }
                      onChange={(startDate, endDate) => {
                        const selectedDate = {
                          start: startDate,
                          end: startDate,
                        };
                        this.setState({
                          selectedDate,
                          visibilityDatePicker: false,
                        });
                      }}
                    />

                    <TextField
                      label='Payment number'
                      type='text'
                      value={this.state.number}
                      error={this.state.numberError}
                      onChange={(number) =>
                        this.handleChangeData(number, "number", "numberError")
                      }
                    />
                  </FormLayout.Group>

                  <FormLayout.Group>
                    <Select
                      label='Invoice'
                      options={optionsInvoices}
                      onChange={(invoice) => this.setState({ invoice })}
                      value={this.state.invoice}
                    />
                    <Select
                      label='Invoice type'
                      options={optionsTypes}
                      onChange={(type) => this.setState({ type })}
                      value={this.state.type}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      label='Sum'
                      type='number'
                      value={this.state.sum.toString()}
                      onChange={(sum) => this.setState({ sum })}
                      connectedRight={
                        <Select
                          label='Currency'
                          labelHidden
                          options={optionsCurrency}
                          onChange={(currency) => this.setState({ currency })}
                          value={this.state.currency}
                        />
                      }
                    />
                    <br />
                  </FormLayout.Group>
                  <Button onClick={this.doAddPayment}>Add payment</Button>
                </FormLayout>
              </Card>
            </Layout.Section>
          </Layout.AnnotatedSection>
        </Layout>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  pendingInvoices: state.manager.pendingInvoices,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    createPayment: (params) => dispatch(createPayment(params)),
    fetchPendingInvoices: () => dispatch(fetchPendingInvoices()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AddPayment))
);
