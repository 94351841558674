import { Card, DataTable, Text } from "@shopify/polaris";
import _ from "lodash";
import React from "react";
import { preciseRound } from "utils/numbers";
import {
  getPackagingSchemaForCountry,
  otherCountriesSchema,
} from "./packagingSchemas";
import { useTranslation } from "react-i18next";

const getValuesTotal = (data) => {
  if (data.total) {
    return data.total;
  }

  const filteredData = { ...data, number_of_csu: 0 };

  const value = Object.values(filteredData).reduce(
    (acc, curr) => acc + Number(curr),
    0
  );

  return preciseRound(value, 3);
};

const PackagingPreview = (props) => {
  const { t } = useTranslation();
  const { data, tableProps = {}, country } = props;

  const totalLabel = "Total kg";
  const schema = getPackagingSchemaForCountry({ country, t });

  const rows = !_.isEmpty(data)
    ? schema.map((item) => [item[0], data[item[1]]])
    : [];

  const formattedRows = rows.map(([label, value]) => [
    label,
    preciseRound(value, 3),
  ]);

  const totalSum = getValuesTotal(data);

  return (
    <Card>
      <DataTable
        columnContentTypes={["text", "numeric"]}
        headings={["Name", "Kg"].map((label) => {
          return <Text fontWeight='semibold'>{label}</Text>;
        })}
        rows={formattedRows}
        showTotalsInFooter
        totals={[totalLabel, totalSum]}
        totalsName={{
          singular: totalLabel,
          plural: totalLabel,
        }}
        hideScrollIndicator
        increasedTableDensity
        {...tableProps}
      />
    </Card>
  );
};

export default PackagingPreview;
