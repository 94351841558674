import { DataTable, Text } from '@shopify/polaris'
import React from 'react'

const StepChangeMultiple = ({rows, columns}) => {
	return (
		<div className="sendEinvoices-wrapper_multiple">
			<DataTable
				columnContentTypes={Array(columns.length).fill("text")}
				headings={columns.map(({ header }) => (
					<Text fontWeight='semibold'>{header.label}</Text>
				))}
				rows={rows}
			/>
		</div>
	)
}

export default StepChangeMultiple