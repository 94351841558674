import React from 'react';
import { Helmet } from 'react-helmet';

const PageHelmet = ({ title }) => {
  const companyName = 'Lovat';
  const fullTitle = `${companyName}: ${title}`;

  return (
    <Helmet>
      <title>{fullTitle}</title>
    </Helmet>
  );
};

export default PageHelmet;
