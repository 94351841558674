export const dialogNames = [
  "merchantSettings",
  "notificationsCheckbox",
  "confirmSubmit",
  "successDialog",
  "uploadMerchants",
  "addMerchant",
];

export const MerchantNotifications = Object.freeze({
  Lovat: "lovat",
  Myself: "myself",
});

export const NotificationMethod = Object.freeze({
  Digital: "digital",
  Paper: "paper",
});

export const MerchantTypes = Object.freeze({
  legal: "Legal entity",
  natural: "Natural person",
});
