import { Card, DataTable, Text } from "@shopify/polaris";
import React from "react";
import { useSelector } from "react-redux";
import { formatRow2 } from "utils/formatRow";

const Summary = ({stepBar}) => {
	const {
		eprSubcategories: {selectedCategory5, quantityArr, unitsDefault, selectedCategory4, selectedCategory3, selectedCategory2, selectedCategory1},
	} = useSelector((state) => state.declarations);
	// console.log('selectedCategory5', selectedCategory5)
	// console.log('selectedCategory4', selectedCategory4)
	// console.log('selectedCategory3', selectedCategory3)
	// console.log('selectedCategory2', selectedCategory2)
	// console.log('selectedCategory1', selectedCategory1)
	// console.log('quantityArr', quantityArr)
	// console.log('unitsDefault', unitsDefault)
	const columns = [
		{
			header: {
				label: "Subcategory 1",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => {
						return selectedCategory5.length === 0 ? rowData.category : rowData[0];
					},
				],
			},
		},
		{
			header: {
				label: "Subcategory 2",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => {
						return <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{selectedCategory5.length === 0 ? rowData.subCategory : rowData[1]}</div>
					},
				],
			},
		},
		
		{
			header: {
				label: "Subcategory 3",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => {
						return <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{
							selectedCategory5
							.length === 0 ? rowData.subSubCategory.name ? `${rowData.subSubCategory.name}` : rowData.subSubCategory : rowData[2]
						}</div>
					},
				],
			},
		},
		
		...(selectedCategory4.length !== 0
			? [
				{
					header: {
						label: "Subcategory 4",
					},
					cell: {
						formatters: [
							(_,
								{rowData}) => {
								// console.log('rowData', rowData);
								return typeof (rowData?.subSubSubCategory) === 'string' ? rowData?.subSubSubCategory : rowData?.subSubSubCategory?.name;
							},
						],
					},
				},
			]
			:
			[]),
		...(selectedCategory5.length !== 0
			? [
				{
					header: {
						label: "Subcategory 4",
					},
					cell: {
						formatters: [
							(_,
								{rowData}) => {
								return selectedCategory5.length === 0 ? rowData.subSubSubCategory : rowData?.[3]?.name ? `${rowData?.[3].name} ${rowData?.[3].units} ` : rowData?.[3];
							},
						],
					},
				},
			]
			:
			[]),
		...(selectedCategory5.length !== 0
			? [
				{
					header: {
						label: "Subcategory 5",
					},
					cell: {
						formatters: [
							(_,
								{rowData}) => {
								return rowData[4];
							},
						],
					},
				},]
			: []),
		{
			header: {
				label: "Quantity",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => {
						// console.log('rowData', rowData)
						return selectedCategory5.length === 0 ? `${rowData?.quantity} ${rowData?.subSubCategory?.units || unitsDefault}` : `${rowData[5]} ${rowData[3]?.units}`;
					},
				],
			},
		},
	];
	
	const arr = selectedCategory5.length === 0 ? quantityArr : selectedCategory5
	const rows = formatRow2(arr, columns);
	return (
		<>
      <Card sectioned>
        <DataTable
	        columnContentTypes={["text", "text", "text", "text", "text", "text"]}
	        headings={columns.map(({header}) => (
		        <Text fontWeight='semibold'>{header.label}</Text >
	        ))}
	        rows={rows}
        />
      </Card >
			
			{stepBar()}
    </>
	);
};

export default Summary;
