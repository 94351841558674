export const unauthorizedMiddleware = (store) => (next) => (action) => {
  // We check that the action contains the error property and the error value is equal to "Unauthorized"
  if (
    action.result &&
    (action.result.error === "Unauthorized" ||
      action.result.error === 401 ||
      action.result === 401)
  ) {
    const publicRoutes = [
      "/login",
      "/manager",
      "/signup",
      "/forgot",
      "/activation",
      "/confirm-2fa",
    ];

    const isPublic = publicRoutes.find((route) =>
      window.location.pathname.includes(route)
    );

    // We redirect to the login page
    if (!isPublic) {
      window.location.replace("/login");
    }
  }

  return next(action);
};
