const getCountryName = (val) => {
  const indexOfParenthesis = val.indexOf("(");

  if (indexOfParenthesis === -1) {
    return val.trim();
  }

  return val.slice(0, indexOfParenthesis).trim();
};

const getProvinceName = (val) => {
  const start = val.indexOf("(");
  const end = val.indexOf(")", start);

  return start !== -1 && end !== -1 ? val.slice(start + 1, end).trim() : null;
};

export { getCountryName, getProvinceName };
