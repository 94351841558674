const isEmpty = (value) =>
  value === undefined || value === null || value === "";
const join = (rules) => (value, data) =>
  rules
    .map((rule) => rule(value, data))
    .filter((error) => !!error)[0 /* first error */];

export function email(value) {
  if (!isEmail(value)) {
    return "Invalid email address";
  }
}
export function phone(value) {
  if (!isPhone(value)) {
    return "Invalid phone number";
  }
}

export function required(value) {
  if (isEmpty(value)) {
    return "Required";
  }
}

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`;
    }
  };
}

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`;
    }
  };
}

export function integer(value) {
  if (!/^[0-9]+$/.test(value)) {
    return "Must be an integer";
  }
}

export function oneOf(enumeration) {
  return (value) => {
    if (!~enumeration.indexOf(value)) {
      return `Must be one of: ${enumeration.join(", ")}`;
    }
  };
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return "Do not match";
      }
    }
  };
}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

// new validation rules

// Company, Users, SignUp, ForgotPassword, LogIn

export function isEmail(value) {
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
}

// Company, SignUp

export function isPhone(value) {
  return /^\+(?:[0-9]\s?){6,14}[0-9]$/.test(value);
}

export function isLatin(value) {
  return !/[^a-zA-Z0-9 -!"#$%&'()*+,./:;<=>?@[\\\]_`{|}~^\-\w]/.test(value);
}

export function isValidPassword(value) {
  return /^[a-zA-Z0-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~]{7,}$/.test(
    value
  );
}
