import { DocumentType } from "containers/DocumentTemplate/common/constants";

export const mapNumbersToOptions = (array, label, value) => {
  return [
    "",
    ...array.map((item) => ({
      label: item[label],
      value: String(item[value]),
    })),
  ];
};

export const mapNumbersWithMultipleLabelsToOptions = (
  array,
  [labelFirst, labelSecond],
  value
) => {
  return [
    "",
    ...array.map((item) => ({
      label: item[labelFirst] ?? item[labelSecond],
      value: String(item[value]),
    })),
  ];
};

const isFieldValid = (field) => field !== undefined && field !== null;

const documentTypeFieldRequirements = {
  [DocumentType.InvoiceVAT]: (data) => ({
    vat_number_id: isFieldValid(data.vat_number_id),
    invoice_number: isFieldValid(data.invoice_number),
  }),
  [DocumentType.InvoiceSalesTax]: (data) => ({
    sales_tax_number_id: isFieldValid(data.sales_tax_number_id),
    invoice_number: isFieldValid(data.invoice_number),
  }),
  [DocumentType.Exemption]: (data) => ({
    sales_certificate_number: isFieldValid(data.sales_certificate_number),
  }),
};

export const validateRequiredFields = (documentType, data) => {
  try {
    const defaultRequirements = {
      customerId: isFieldValid(data.customerId),
      bankId: isFieldValid(data.bankId),
    };

    const typeSpecificRequirements =
      documentTypeFieldRequirements[documentType](data);

    return { ...defaultRequirements, ...typeSpecificRequirements };
  } catch (e) {
    throw new Error("Wrong documentType used!");
  }
};

export const areAllRequiredFieldsValid = (requiredFields) => {
  return Object.values(requiredFields).every(Boolean);
};
