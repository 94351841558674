export const StepIndex = Object.freeze({
  Edit: 0,
  Send: 1,
  Create: 2,
});

export const DocumentType = Object.freeze({
  InvoiceVAT: "invoice-vat",
  InvoiceSalesTax: "invoice-sales-tax",
  Exemption: "exemption-certificate",
});
