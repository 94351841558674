import { useEffect } from "react";

const useMainPadding = (globalState, actionDialogs) => {
  useEffect(() => {
    const { isLogged } = globalState.auth;
    const main = document.querySelector(".Polaris-Frame__Main");

    if (isLogged) {
      main.style.paddingLeft = actionDialogs.sidebar.open
        ? "calc(15rem + env(safe-area-inset-left))"
        : "68px";
    }

    return () => {
      main.style.paddingLeft = "0px";
    };
  }, [actionDialogs.sidebar.open, globalState.auth]);
};

export default useMainPadding;
