import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import { parseServerError } from "utils/errors";
import { formatDateForServer } from "utils/dates";

export const fetchGbrStates = createAsyncThunk(
  "states/fetchGbrStates",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/states?country_id=GBR", "GET");
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const saveAutosubmit = createAsyncThunk(
  "autosubmit/save",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/autosubmit/save", "POST", data);
      if (response.ok) {
        const json = await response.json();
        return json;
      } else {
        const error = await response.json();
        return rejectWithValue(error);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchAutosubmitStores = createAsyncThunk(
  "autosubmit/fetchStores",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/autosubmit/stores", "GET");
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchAutosubmitCountries = createAsyncThunk(
  "autosubmit/fetchCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/autosubmit/countries", "GET");
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchStates = createAsyncThunk(
  "states/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/us_states", "GET");
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchExTypes = createAsyncThunk(
  "exemptionTypes/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/exemption/exemption_document_type",
        "GET"
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchCertTypes = createAsyncThunk(
  "certificateTypes/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/sales_tax_permit/certificate_types",
        "GET"
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const deleteWaiver = createAsyncThunk(
  "waiver/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(`/waiver/delete/${id}`, "DELETE");
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const downloadWaiverFile = createAsyncThunk(
  "waiver/downloadFile",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(`/waiver/download/${id}`, "GET", {
        responseType: "blob",
      });
      if (response.ok) {
        const blob = await response.json();
        return { link: window.URL.createObjectURL(blob) };
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const deleteSalesTaxPermit = createAsyncThunk(
  "salesTaxPermit/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/sales_tax_permit/delete/${id}`,
        "DELETE"
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const deleteExeption = createAsyncThunk(
  "exemption/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(`/exemption/delete/${id}`, "DELETE");
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const downloadExemptionFile = createAsyncThunk(
  "exemption/downloadFile",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(`/exemption/download/${id}`, "GET", {
        responseType: "blob",
      });
      if (response.ok) {
        const blob = await response.json();
        return { link: window.URL.createObjectURL(blob) };
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchExemptions = createAsyncThunk(
  "exemptions/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/exemption", "GET");
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchSalesTaxPermit = createAsyncThunk(
  "salesTaxPermit/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/sales_tax_permit", "GET");
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const createWaiver = createAsyncThunk(
  "waiver/create",
  async (
    { waiverCert, waiverState, waiverSelected, data },
    { rejectWithValue }
  ) => {
    try {
      const response = await callApi(
        `/waiver/add?certificate_number=${waiverCert}&from_date=${formatDateForServer(
          waiverSelected.start
        )}${
          waiverSelected.end
            ? `&till_date=${formatDateForServer(waiverSelected.end)}`
            : ""
        }&state_code=${waiverState}`,
        "POST",
        { data }
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchWaivers = createAsyncThunk(
  "waivers/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/waiver", "GET");
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const createSalesTaxPermit = createAsyncThunk(
  "salesTaxPermit/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/sales_tax_permit/add", "POST", data);
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const createExeption = createAsyncThunk(
  "exemption/create",
  async (
    { exempCert, exempState, exempSelected, data },
    { rejectWithValue }
  ) => {
    try {
      const response = await callApi(
        `/exemption/add?tax_exemption_certificate=${exempCert}&from_date=${formatDateForServer(
          exempSelected.start
        )}${
          exempSelected.end
            ? `&till_date=${formatDateForServer(exempSelected.end)}`
            : ""
        }&state_code=${exempState}`,
        "POST",
        { data }
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

const initialState = {
  salesTaxPermit: [],
  certTypes: [],
  usStates: [],
  exemptions: [],
  exTypes: [],
  waivers: [],
  autoCountries: [],
  autoStores: [],
};

const taxSettingsSlice = createSlice({
  name: "taxSettings",
  initialState,
  reducers: {
    updateTaxSettingsAutopilotCountriesList: (state, action) => {
      const updateData = action.payload;
      const dataArray = JSON.parse(JSON.stringify(state.autoCountries));
      
      updateData.countries.forEach(updateItem => {
        dataArray.forEach(item => {
          if (
            item.country.code === updateItem.country_code &&
            item.type_of_report.id === updateItem.type_of_report_id
          ) {
            item.type_of_report.autosubmit = updateItem.autosubmit;
          }
        });
      });
      state.autoCountries = dataArray;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGbrStates.pending, (state) => {
        state.fetchingGbrStates = true;
      })
      .addCase(fetchGbrStates.fulfilled, (state, action) => {
        state.fetchingGbrStates = false;
        state.gbrStates = action.payload;
        state.fetchingGbrStatesError = null;
      })
      .addCase(fetchGbrStates.rejected, (state, action) => {
        state.fetchingGbrStates = false;
        state.fetchingGbrStatesError = parseServerError(action.error);
      })
      .addCase(saveAutosubmit.pending, (state) => {
        state.savingAuto = true;
        state.savingAutoError = null;
      })
      .addCase(saveAutosubmit.fulfilled, (state) => {
          state.savingAuto = false;
          state.savingAutoError = null;
      })
      .addCase(saveAutosubmit.rejected, (state, action) => {
        state.savingAuto = false;
        state.savingAutoError = parseServerError(action?.error?.message);
      })
      .addCase(fetchAutosubmitStores.pending, (state) => {
        state.fetchingAutoStores = true;
      })
      .addCase(fetchAutosubmitStores.fulfilled, (state, action) => {
        state.autoStores = action.payload.stores;
        state.fetchingAutoStores = false;
        state.fetchingAutoStoresError = null;
      })
      .addCase(fetchAutosubmitStores.rejected, (state, action) => {
        state.fetchingAutoStores = false;
        state.fetchingAutoStoresError = parseServerError(action.error);
      })
      .addCase(fetchAutosubmitCountries.pending, (state) => {
        state.fetchingAutoCountries = true;
      })
      .addCase(fetchAutosubmitCountries.fulfilled, (state, action) => {
        state.autoCountries = action.payload;
        state.fetchingAutoCountries = false;
        state.fetchingAutoCountriesError = null;
      })
      .addCase(fetchAutosubmitCountries.rejected, (state, action) => {
        state.fetchingAutoCountries = false;
        state.fetchingAutoCountriesError = parseServerError(action.error);
      })
      .addCase(fetchStates.pending, (state) => {
        state.fetchingStates = true;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.usStates = action.payload;
        state.fetchingStates = false;
        state.fetchingStatesError = null;
      })
      .addCase(fetchStates.rejected, (state, action) => {
        state.fetchingStates = false;
        state.fetchingStatesError = parseServerError(action.error);
      })
      .addCase(fetchExTypes.pending, (state) => {
        state.fetchingExTypes = true;
      })
      .addCase(fetchExTypes.fulfilled, (state, action) => {
        state.exTypes = action.payload;
        state.fetchingExTypes = false;
        state.fetchingExTypesError = null;
      })
      .addCase(fetchExTypes.rejected, (state, action) => {
        state.fetchingExTypes = false;
        state.fetchingExTypesError = parseServerError(action.error);
      })
      .addCase(fetchCertTypes.pending, (state) => {
        state.fetchingCertTypes = true;
      })
      .addCase(fetchCertTypes.fulfilled, (state, action) => {
        state.certTypes = action.payload;
        state.fetchingCertTypes = false;
        state.fetchingCertTypesError = null;
      })
      .addCase(fetchCertTypes.rejected, (state, action) => {
        state.fetchingCertTypes = false;
        state.fetchingCertTypesError = parseServerError(action.error);
      })
      .addCase(deleteWaiver.pending, (state) => {
        state.deletingWaiver = true;
        state.deletingWaiverError = null;
      })
      .addCase(deleteWaiver.fulfilled, (state) => {
        state.deletingWaiver = false;
        state.deletingWaiverError = null;
      })
      .addCase(deleteWaiver.rejected, (state, action) => {
        state.deletingWaiver = false;
        state.deletingWaiverError = parseServerError(action.error);
      })
      .addCase(downloadWaiverFile.pending, (state) => {
        state.downloadingWaiver = true;
      })
      .addCase(downloadWaiverFile.fulfilled, (state, action) => {
        state.downloadingWaiver = false;
        state.downloadingWaiverError = null;
        state.toDownloadWaiver = { ...action.payload };
      })
      .addCase(downloadWaiverFile.rejected, (state, action) => {
        state.downloadingWaiver = false;
        state.downloadingWaiverError = parseServerError(action.error);
      })
      .addCase(deleteSalesTaxPermit.pending, (state) => {
        state.deletingTaxPermit = true;
        state.deletingTaxPermitError = null;
      })
      .addCase(deleteSalesTaxPermit.fulfilled, (state) => {
        state.deletingTaxPermit = false;
        state.deletingTaxPermitError = null;
      })
      .addCase(deleteSalesTaxPermit.rejected, (state, action) => {
        state.deletingTaxPermit = false;
        state.deletingTaxPermitError = parseServerError(action.error);
      })
      .addCase(deleteExeption.pending, (state) => {
        state.deletingExemp = true;
        state.deletingExempError = null;
      })
      .addCase(deleteExeption.fulfilled, (state) => {
        state.deletingExemp = false;
        state.deletingExempError = null;
      })
      .addCase(deleteExeption.rejected, (state, action) => {
        state.deletingExemp = false;
        state.deletingExempError = parseServerError(action.error);
      })
      .addCase(downloadExemptionFile.pending, (state) => {
        state.downloadingEx = true;
      })
      .addCase(downloadExemptionFile.fulfilled, (state, action) => {
        state.downloadingEx = false;
        state.downloadingExError = null;
        state.toDownloadEx = { ...action.payload };
      })
      .addCase(downloadExemptionFile.rejected, (state, action) => {
        state.downloadingEx = false;
        state.downloadingExError = parseServerError(action.error);
      })
      .addCase(fetchExemptions.pending, (state) => {
        state.fetchingEx = true;
      })
      .addCase(fetchExemptions.fulfilled, (state, action) => {
        state.exemptions = action.payload;
        state.fetchingEx = false;
        state.fetchingExError = null;
      })
      .addCase(fetchExemptions.rejected, (state, action) => {
        state.fetchingEx = false;
        state.fetchingExError = parseServerError(action.error);
      })
      .addCase(fetchSalesTaxPermit.pending, (state) => {
        state.fetchingTaxPermit = true;
      })
      .addCase(fetchSalesTaxPermit.fulfilled, (state, action) => {
        state.salesTaxPermit = action.payload;
        state.fetchingTaxPermit = false;
        state.fetchingTaxPermitError = null;
      })
      .addCase(fetchSalesTaxPermit.rejected, (state, action) => {
        state.fetchingTaxPermit = false;
        state.fetchingTaxPermitError = parseServerError(action.error);
      })
      .addCase(createWaiver.pending, (state) => {
        state.creatingWaivers = true;
      })
      .addCase(createWaiver.fulfilled, (state, action) => {
        state.creatingWaivers = false;
        state.creatingWaiversError = null;
        state.createdWaiversId = action.payload.id;
      })
      .addCase(createWaiver.rejected, (state, action) => {
        state.creatingWaivers = false;
        state.creatingWaiversError = parseServerError(action.error);
      })
      .addCase(fetchWaivers.pending, (state) => {
        state.fetchingWaivers = true;
      })
      .addCase(fetchWaivers.fulfilled, (state, action) => {
        state.waivers = action.payload;
        state.fetchingWaivers = false;
        state.fetchingWaiversError = null;
      })
      .addCase(fetchWaivers.rejected, (state, action) => {
        state.fetchingWaivers = false;
        state.fetchingWaiversError = parseServerError(action.error);
      })
      .addCase(createSalesTaxPermit.pending, (state) => {
        state.creatingTaxPermit = true;
      })
      .addCase(createSalesTaxPermit.fulfilled, (state, action) => {
        state.creatingTaxPermit = false;
        state.creatingTaxPermitError = null;
        state.createdTaxPermitId = action.payload.id;
      })
      .addCase(createSalesTaxPermit.rejected, (state, action) => {
        state.creatingTaxPermit = false;
        state.creatingTaxPermitError = parseServerError(action.error);
      })
      .addCase(createExeption.pending, (state) => {
        state.creatingEx = true;
      })
      .addCase(createExeption.fulfilled, (state, action) => {
        state.creatingEx = false;
        state.creatingExError = null;
        state.createdExId = action.payload.id;
      })
      .addCase(createExeption.rejected, (state, action) => {
        state.creatingEx = false;
        state.creatingExError = parseServerError(action.error);
      });
  },
});

export const {
  updateTaxSettingsAutopilotCountriesList,
} = taxSettingsSlice.actions

export default taxSettingsSlice.reducer;
