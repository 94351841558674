import React from "react";

const TransactionsIcon = ({ selected }) => {
  if (selected) {
    return (
      <svg
        width='23'
        height='16'
        viewBox='0 0 23 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.66146 7.64268e-07L18.3385 3.5513e-06C18.8657 -1.43301e-05 19.3205 -2.98284e-05 19.695 0.0305739C20.0904 0.062875 20.4836 0.134191 20.862 0.326985C21.4265 0.614605 21.8854 1.07355 22.173 1.63803C22.3658 2.01641 22.4371 2.40963 22.4694 2.80498C22.5 3.17954 22.5 3.6343 22.5 4.16144C22.5 4.62456 22.1246 5 21.6614 5H1.33859C0.87545 5 0.500001 4.62455 0.500001 4.16141C0.499985 3.6343 0.499971 3.17953 0.530573 2.80497C0.562874 2.40963 0.63419 2.01641 0.826984 1.63803C1.1146 1.07354 1.57355 0.614603 2.13803 0.326983C2.51641 0.134189 2.90963 0.0628734 3.30498 0.0305724C3.67953 -3.00203e-05 4.13434 -1.57618e-05 4.66146 7.64268e-07Z'
          fill='#267FD7'
        />
        <path
          d='M2.1 7C1.53995 7 1.25992 7 1.04601 7.10899C0.857848 7.20487 0.704868 7.35785 0.608994 7.54601C0.500001 7.75992 0.500001 8.03995 0.500001 8.6V11.8385C0.499985 12.3657 0.499971 12.8205 0.530573 13.195C0.562874 13.5904 0.63419 13.9836 0.826984 14.362C1.1146 14.9265 1.57355 15.3854 2.13803 15.673C2.51641 15.8658 2.90963 15.9371 3.30497 15.9694C3.67951 16 4.13421 16 4.66129 16L18.3386 16C18.8656 16 19.3205 16 19.695 15.9694C20.0904 15.9371 20.4836 15.8658 20.862 15.673C21.4265 15.3854 21.8854 14.9265 22.173 14.362C22.3658 13.9836 22.4371 13.5904 22.4694 13.195C22.5 12.8205 22.5 12.3657 22.5 11.8386V8.6C22.5 8.03995 22.5 7.75992 22.391 7.54601C22.2951 7.35785 22.1422 7.20487 21.954 7.10899C21.7401 7 21.4601 7 20.9 7H2.1Z'
          fill='#267FD7'
        />
      </svg>
    );
  }
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.6667 15.8337C16.6001 15.8337 17.0668 15.8337 17.4233 15.652C17.7369 15.4922 17.9919 15.2372 18.1517 14.9236C18.3333 14.5671 18.3333 14.1004 18.3333 13.167V6.83366C18.3333 5.90024 18.3333 5.43353 18.1517 5.07701C17.9919 4.76341 17.7369 4.50844 17.4233 4.34865C17.0668 4.16699 16.6001 4.16699 15.6667 4.16699L4.33332 4.16699C3.3999 4.16699 2.93319 4.16699 2.57667 4.34865C2.26307 4.50844 2.0081 4.7634 1.84831 5.07701C1.66666 5.43353 1.66666 5.90024 1.66666 6.83366L1.66666 13.167C1.66666 14.1004 1.66666 14.5671 1.84831 14.9236C2.0081 15.2372 2.26307 15.4922 2.57667 15.652C2.93319 15.8337 3.3999 15.8337 4.33332 15.8337L15.6667 15.8337Z'
        fill='none'
        stroke='#707070'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.4'
        d='M18.3333 8.33301H1.66666'
        stroke='#2E2E2E'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default TransactionsIcon;
