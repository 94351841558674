export const getCompanyInitials = (name) => {
  const words = name.split(" ");

  if (words.length > 1) {
    return words
      .slice(0, 2)
      .map((word) => word[0])
      .join("");
  } else if (words.length === 1) {
    return words[0].slice(0, 2);
  } else {
    return "";
  }
};

export const capitalizeFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
