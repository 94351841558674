const currencies = {
  local: {
    usd: [
      {
        type: "input",
        props: {
          id: "account_holder",
          type: "text",
          inputMode: "text",
          placeholder: "Account holder",
          label: "Account holder",
        },
      },
      {
        type: "input",
        props: {
          id: "routing_number",
          type: "text",
          inputMode: "text",
          placeholder: "Routing number",
          label: "Routing number",
        },
      },
      {
        type: "input",
        props: {
          id: "account_number",
          type: "text",
          inputMode: "text",
          placeholder: "Account Number",
          label: "Account Number",
        },
      },
      {
        type: "input",
        props: {
          id: "name",
          type: "text",
          inputMode: "text",
          placeholder: "Name of the bank",
          label: "Name of the bank",
        },
      },
      {
        type: "select",
        props: {
          id: "account_type",
          label: "Account type",
          options: [
            { value: "", label: "" },
            { value: "checking", label: "Checking" },
            { value: "saving", label: "Saving" },
          ],
        },
      },
    ],
    eur: [
      {
        type: "input",
        props: {
          id: "account_holder",
          type: "text",
          inputMode: "text",
          placeholder: "Account holder",
          label: "Account holder",
        },
      },
      {
        type: "input",
        props: {
          id: "name",
          type: "text",
          inputMode: "text",
          placeholder: "Name of the bank",
          label: "Name of the bank",
        },
      },
      {
        type: "input",
        props: {
          id: "account_number",
          type: "text",
          inputMode: "text",
          placeholder: "Account Number",
          label: "Account Number",
        },
      },
    ],
  },
  global: [
    {
      type: "input",
      props: {
        id: "account_holder",
        type: "text",
        inputMode: "text",
        placeholder: "Account holder",
        label: "Account holder",
      },
    },
    {
      type: "input",
      props: {
        id: "swift",
        type: "text",
        inputMode: "text",
        placeholder: "SWIFT",
        label: "SWIFT",
      },
    },
    {
      type: "input",
      props: {
        id: "name",
        type: "text",
        inputMode: "text",
        placeholder: "Name of the bank",
        label: "Name of the bank",
      },
    },
    {
      type: "input",
      props: {
        id: "account_number",
        type: "text",
        inputMode: "text",
        placeholder: "Account Number",
        label: "Account Number",
      },
    },
  ],
};

export { currencies };
