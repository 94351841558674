import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import { parseServerError } from "utils/errors";

export const fetchPaymentInfo = createAsyncThunk(
  "profile/fetchPaymentInfo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/dashboard/payment_info", "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

const initialState = {
  paymentInfo: [],
  fetchingPaymentInfo: false,
  fetchingPaymentInfoError: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentInfo.pending, (state) => {
        state.fetchingPaymentInfo = true;
      })
      .addCase(fetchPaymentInfo.fulfilled, (state, action) => {
        state.fetchingPaymentInfo = false;
        state.fetchingPaymentInfoError = null;
        state.paymentInfo = action.payload;
      })
      .addCase(fetchPaymentInfo.rejected, (state, action) => {
        state.fetchingPaymentInfo = false;
        state.fetchingPaymentInfoError = parseServerError(action.error);
      });
  },
});

export default profileSlice.reducer;
