import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { parseServerError } from "utils/errors";
import callApi from "helpers/callApi";

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/user", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const changeOwnPassword = createAsyncThunk(
  "user/changeOwnPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/user/password", "POST", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const editNotifications = createAsyncThunk(
  "user/editNotifications",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/user/notifications/", "POST", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchNotifications = createAsyncThunk(
  "user/fetchNotifications",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/user/notifications/", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchCompanyDefaultLanguage = createAsyncThunk(
  "user/fetchCompanyDefaultLanguage",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/company/lang", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/user/reg", "POST", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const activateResend = createAsyncThunk(
  "user/activateResend",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/user/activate/send", "POST", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "user/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/users", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/users", "POST", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const editUser = createAsyncThunk(
  "user/editUser",
  async ({ id, email, first_name, last_name }, { rejectWithValue }) => {
    try {
      const response = await callApi(`/user/${id}`, "POST", {
        email,
        first_name,
        last_name,
      });
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(`/user/${id}`, "DELETE");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const changeUserPassword = createAsyncThunk(
  "user/changeUserPassword",
  async ({ id, new_password }, { rejectWithValue }) => {
    try {
      const response = await callApi(`/user/${id}/password`, "POST", {
        new_password,
      });
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const changeCompanyInfo = createAsyncThunk(
  "user/changeCompanyInfo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/company/change/company_info`,
        "POST",
        data,
        {
          contentType: "multipart/form-data",
        }
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchCompanyDefaultCurrency = createAsyncThunk(
  "user/fetchCompanyDefaultCurrency",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/company/get_company_default_currency`,
        "GET"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const switchCompanyDefaultCurrency = createAsyncThunk(
  "user/switchCompanyDefaultCurrency",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/company/edit_company_default_currency`,
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const switchCompanyDefaultLanguage = createAsyncThunk(
  "user/switchCompanyDefaultLanguage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/company/lang", "PUT", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchUserGuide = createAsyncThunk(
  "user/fetchUserGuide",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/user/user_guide", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const skipStepUserGuide = createAsyncThunk(
  "user/skipStepUserGuide",
  async (step, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/user/user_guide_skip_step?step=${step}`,
        "GET"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const forgotComplete = createAsyncThunk(
  "user/forgotComplete",
  async ({ code, password }, { rejectWithValue }) => {
    try {
      const response = await callApi("/user/forgot", "POST", {
        code,
        password,
      });
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const forgotStart = createAsyncThunk(
  "user/forgotStart",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/user/forgot/send", "POST", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const toggleTwoFaAuth = createAsyncThunk(
  "user/toggleTwoFaAuth",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/mobile/on_off_two_factor_authentication/`,
        "GET"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getUserCompanies = createAsyncThunk(
  "user/getUserCompanies",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(`/user/list_company/`, "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const changeUserCompany = createAsyncThunk(
  "user/changeUserCompany",
  async (company_id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/user/set_company/?company_id=${company_id}`,
        "PUT"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const addUserCompany = createAsyncThunk(
  "user/addUserCompany",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/user/create_company/`, "POST", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deactivateFirstEntry = createAsyncThunk(
  "user/deactivateFirstEntry",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(`/user/first_entry`, "POST");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const unsubscribeNewsletter = createAsyncThunk(
  "user/deactivateFirstEntry",
  async (token, { rejectWithValue }) => {
    try {
      const response = await callApi(`/unsubscribe/${token}`, "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    users: [],
    is_manager: null,
    new_user_id: null,
    userGuide: {},
    notifications: {},
    userCompanies: [],
    wantIntegrateStripe: false,
    integrateStripeParams: null,
  },
  reducers: {
    addIntegrateStripeParams(state, action) {
      state.wantIntegrateStripe = true;
      state.integrateStripeParams = {
        ...action.payload,
      };
    },
    clearIntegrateStripeParams(state, action) {
      state.wantIntegrateStripe = false;
      state.integrateStripeParams = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.registering = true;
        state.registered = false;
        state.registerError = null;
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.registering = false;
        state.registered = true;
        state.registerError = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.registering = false;
        state.registered = false;
        state.registerError = parseServerError(action.payload);
      })
      .addCase(activateResend.pending, (state) => {
        state.sending = true;
        state.sent = false;
        state.resendError = null;
      })
      .addCase(activateResend.fulfilled, (state) => {
        state.sending = false;
        state.sent = true;
        state.resendError = null;
      })
      .addCase(activateResend.rejected, (state, action) => {
        state.sending = false;
        state.sent = false;
        state.resendError = parseServerError(action.payload);
      })
      .addCase(fetchUser.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.fetching = false;
        state.user = action.payload;
        state.fetchingError = null;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.fetching = false;
        state.fetchingError = parseServerError(action.payload);
      })
      .addCase(fetchUsers.pending, (state) => {
        state.fetchingUsers = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.fetchingUsers = false;
        state.users = action.payload;
        state.fetchingUsersError = null;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.fetchingUsers = false;
        state.fetchingUsersError = parseServerError(action.payload);
      })
      .addCase(createUser.pending, (state) => {
        state.creating = true;
        state.new_user_id = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.creating = false;
        state.new_user_id = action.payload.user_id;
        state.creatingError = null;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.creating = false;
        state.creatingError = parseServerError(action.payload);
      })
      .addCase(editUser.pending, (state) => {
        state.editing = true;
        state.editingError = null;
      })
      .addCase(editUser.fulfilled, (state) => {
        state.editing = false;
        state.editingError = null;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.editing = false;
        state.editingError = parseServerError(action.payload);
      })
      .addCase(deleteUser.pending, (state) => {
        state.deleting = true;
        state.deletingError = null;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.deleting = false;
        state.deletingError = null;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleting = false;
        state.deletingError = parseServerError(action.payload);
      })
      .addCase(changeUserPassword.pending, (state) => {
        state.changing = true;
        state.changingError = null;
      })
      .addCase(changeUserPassword.fulfilled, (state) => {
        state.changing = false;
        state.changingError = null;
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        state.changing = false;
        state.changingError = parseServerError(action.payload);
      })
      .addCase(forgotStart.pending, (state) => {
        state.forgotStart = true;
      })
      .addCase(forgotStart.fulfilled, (state) => {
        state.forgotStart = false;
        state.forgotStartError = null;
      })
      .addCase(forgotStart.rejected, (state, action) => {
        state.forgotStart = false;
        state.forgotStartError = parseServerError(action.payload);
      })
      .addCase(forgotComplete.pending, (state) => {
        state.forgotComplete = true;
      })
      .addCase(forgotComplete.fulfilled, (state) => {
        state.forgotComplete = false;
        state.forgotCompleteError = null;
      })
      .addCase(forgotComplete.rejected, (state, action) => {
        state.forgotComplete = false;
        state.forgotCompleteError = parseServerError(action.payload);
      })
      .addCase(changeCompanyInfo.pending, (state) => {
        state.changing = true;
        state.changingError = null;
      })
      .addCase(changeCompanyInfo.fulfilled, (state) => {
        state.changing = false;
        state.changingError = null;
      })
      .addCase(changeCompanyInfo.rejected, (state, action) => {
        state.changing = false;
        state.changingError = parseServerError(action.payload);
      })
      .addCase(fetchCompanyDefaultCurrency.pending, (state) => {
        state.fetchingCurrency = true;
      })
      .addCase(fetchCompanyDefaultCurrency.fulfilled, (state, action) => {
        state.fetchingCurrency = false;
        state.defaultCurrency = action.payload;
        state.fetchingCurrencyError = null;
      })
      .addCase(fetchCompanyDefaultCurrency.rejected, (state, action) => {
        state.fetchingCurrency = false;
        state.defaultCurrency = null;
        state.fetchingCurrencyError = parseServerError(action.payload);
      })
      .addCase(fetchCompanyDefaultLanguage.pending, (state) => {
        state.fetchingLanguage = true;
      })
      .addCase(fetchCompanyDefaultLanguage.fulfilled, (state, action) => {
        state.fetchingLanguage = false;
        state.defaultLanguage = action.payload;
        state.fetchingLanguageError = null;
      })
      .addCase(fetchCompanyDefaultLanguage.rejected, (state, action) => {
        state.fetchingLanguage = false;
        state.defaultLanguage = "en";
        state.fetchingLanguageError = parseServerError(action.payload);
      })
      .addCase(switchCompanyDefaultCurrency.pending, (state) => {
        state.switchingCurrency = true;
      })
      .addCase(switchCompanyDefaultCurrency.fulfilled, (state) => {
        state.switchingCurrency = false;
        state.switchingCurrencyError = null;
      })
      .addCase(switchCompanyDefaultCurrency.rejected, (state, action) => {
        state.switchingCurrency = false;
        state.switchingCurrencyError = parseServerError(action.payload);
      })
      .addCase(switchCompanyDefaultLanguage.pending, (state) => {
        state.switchingLanguage = true;
      })
      .addCase(switchCompanyDefaultLanguage.fulfilled, (state, action) => {
        state.defaultLanguage = action.payload;
        state.switchingLanguage = false;
        state.switchingLanguageError = null;
      })
      .addCase(switchCompanyDefaultLanguage.rejected, (state, action) => {
        state.switchingLanguage = false;
        state.switchingLanguageError = parseServerError(action.payload);
      })
      .addCase(fetchUserGuide.pending, (state) => {
        state.fetchingGuide = true;
      })
      .addCase(fetchUserGuide.fulfilled, (state, action) => {
        state.fetchingGuide = false;
        state.userGuide = action.payload;
        state.fetchingGuideError = null;
      })
      .addCase(fetchUserGuide.rejected, (state, action) => {
        state.fetchingGuide = false;
        state.fetchingGuideError = parseServerError(action.payload);
      })
      .addCase(skipStepUserGuide.pending, (state) => {
        state.skippingStep = true;
      })
      .addCase(skipStepUserGuide.fulfilled, (state) => {
        state.skippingStep = false;
        state.skippingStepError = null;
      })
      .addCase(skipStepUserGuide.rejected, (state, action) => {
        state.skippingStep = false;
        state.skippingStepError = parseServerError(action.payload);
      })
      .addCase(editNotifications.pending, (state) => {
        state.fetchingNotifications = true;
      })
      .addCase(editNotifications.fulfilled, (state, action) => {
        state.fetchingNotifications = false;
        state.notifications = action.payload;
        state.fetchingNotificationsError = null;
      })
      .addCase(editNotifications.rejected, (state, action) => {
        state.fetchingNotifications = false;
        state.fetchingNotificationsError = parseServerError(action.payload);
      })
      .addCase(fetchNotifications.pending, (state) => {
        state.fetchingNotifications = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.fetchingNotifications = false;
        state.notifications = action.payload;
        state.fetchingNotificationsError = null;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.fetchingNotifications = false;
        state.fetchingNotificationsError = parseServerError(action.payload);
      })
      .addCase(getUserCompanies.pending, (state) => {
        state.fetchingUserCompanies = true;
        state.userCompanies = [];
        state.selectedCompanyId = null;
      })
      .addCase(getUserCompanies.fulfilled, (state, action) => {
        state.fetchingUserCompanies = false;
        state.userCompanies = action.payload.companies;
        state.selectedCompanyId = action.payload.selected_company_id;
        state.fetchingUserCompaniesError = null;
      })
      .addCase(getUserCompanies.rejected, (state, action) => {
        state.fetchingUserCompanies = false;
        state.userCompanies = [];
        state.fetchingUserCompaniesError = parseServerError(action.payload);
        state.selectedCompanyId = null;
      })
      .addCase(addUserCompany.pending, (state) => {
        state.addingUserCompany = true;
        state.addingUserCompanyError = null;
      })
      .addCase(addUserCompany.fulfilled, (state) => {
        state.addingUserCompany = false;
        state.addingUserCompanyError = null;
      })
      .addCase(addUserCompany.rejected, (state, action) => {
        state.addingUserCompany = false;
        state.addingUserCompanyError = parseServerError(action.payload);
      })
      .addCase(changeOwnPassword.pending, (state) => {
        state.changing = true;
        state.changingError = null;
      })
      .addCase(changeOwnPassword.fulfilled, (state) => {
        state.changing = false;
        state.changingError = null;
      })
      .addCase(changeOwnPassword.rejected, (state, action) => {
        state.changing = false;
        state.changingError = parseServerError(action.payload);
      })
      .addCase(unsubscribeNewsletter.pending, (state) => {
        state.loadingUnsubscribe = true;
        state.unsubscribedSuccessfully = false;
        state.loadingUnsubscribeError = null;
      })
      .addCase(unsubscribeNewsletter.fulfilled, (state) => {
        state.loadingUnsubscribe = false;
        state.unsubscribedSuccessfully = true;
        state.loadingUnsubscribeError = null;
      })
      .addCase(unsubscribeNewsletter.rejected, (state, action) => {
        state.loadingUnsubscribe = false;
        state.unsubscribedSuccessfully = false;
        state.loadingUnsubscribeError = parseServerError(action.payload);
      });
  },
});

export const { addIntegrateStripeParams, clearIntegrateStripeParams } =
  userSlice.actions;

export default userSlice.reducer;
