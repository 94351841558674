import React, { Component } from "react";
import { isEmpty } from "lodash";
import { Card, DataTable, Stack, Text, TextStyle } from "@shopify/polaris";
import { formatMoney } from "utils/numbers";
import { withTranslation } from "react-i18next";

class OSSPreview extends Component {
  renderSummary = () => {
    const { sumServices, vatServices, currency } = this.props;
    const currencyIcon =
      currency.toLowerCase() === "eur" ? " \u{20AC} " : currency;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Text variant='bodyMd'>
          Sales of services: {formatMoney(sumServices)} {currencyIcon}
        </Text>
        <Text variant='bodyMd'>
          VAT due: {formatMoney(vatServices)} {currencyIcon}
        </Text>
      </div>
    );
  };

  renderRowByType = (data, country, title, type) =>
    data.map((item, ind) => {
      return [
        ind === 0 ? country : "",
        formatMoney(item.total_sum),
        `${item.vat_percent}%`,
        formatMoney(item.total_vat),
      ];
    });

  renderSumRow = (title, revenue, vat) => {
    return [
      <Text fontWeight='semibold'>{title}</Text>,
      <Text fontWeight='semibold'>{revenue}</Text>,
      "",
      <Text fontWeight='semibold'>{vat}</Text>,
    ];
  };

  columns = [
    this.props.t("createReturns.country"),
    `Revenue (${this.props.currency})`,
    "VAT rate",
    "VAT",
  ];

  getRows = () => {
    const { data, currency, sumServices, vatServices, t } = this.props;

    const rows = !isEmpty(data)
      ? data
          .map((item, index) => {
            return this.renderRowByType(item.sales_of_services, item.country);
          })
          .flat()
      : [["-", "0", "-", "0"]];

    rows.push(
      this.renderSumRow(
        "Total",
        formatMoney(sumServices),
        formatMoney(vatServices)
      )
    );

    return rows;
  };

  render() {
    const { data } = this.props;

    return (
      <div>
        {this.renderSummary()}
        <br />
        {data && (
          <Card>
            <DataTable
              columnContentTypes={["text", "text", "text", "numeric"]}
              headings={this.columns.map((label) => (
                <Text fontWeight='semibold'>{label}</Text>
              ))}
              rows={this.getRows()}
              hideScrollIndicator
              increasedTableDensity
            />
          </Card>
        )}
      </div>
    );
  }
}

export default withTranslation()(OSSPreview);
