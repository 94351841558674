import { Button, Card, Checkbox, DataTable, Inline, Layout, Spinner, Stack, Text, Tooltip } from '@shopify/polaris'
import { t } from 'i18next'
import _ from 'lodash'
import React from 'react'
import NoDataMessage from 'components/NoDataMessage'
import checkmarkIMG from 'img/checkmark.svg'
import crossIMG from 'img/cross.svg'
import settingsIMG from 'img/settings.svg'

const AutopilotCountries = ({
	countriesList,
	setChangeCountryData,
	toggleShowChangeStatusOneElModal,
	countriesCheckedList,
	handleCheckboxChange,
	fetchingAutoCountries,
	errorMessageStatus
}) => {
	const columns = [
		{header: t("taxSettings.country"), key: "country_name", align: "left"},
		{header: t("taxSettings.typeOfReport"), key: "type_of_report", align: "left"},
		{header: t("vatReg.active"), key: "active", align: "center"},
		{header: t("taxSettings.autopilot"), key: "autosubmit", align: "right"}
	]
	
	const rows = countriesList.map(country => [
		country.country.name,
		country.type_of_report.name,
		<Stack
			distribution='center'
			key={country.type_of_report.autosubmit}
			style={{margin: '0 auto'}}
		>
			<Inline
				alignY='center'
				spacing='3'
			>
				<Tooltip
					content={t("vatReg.edit")}
					preferredPosition='below'
				>
					<Button
						plain
						onClick={() => {
							setChangeCountryData(country)
							toggleShowChangeStatusOneElModal()
						}}
					>
						<img
							src={settingsIMG}
							alt='edit'
						/>
					</Button >
				</Tooltip >
				{country.type_of_report.autosubmit
					? <img
						src={checkmarkIMG}
						alt='checkmark'
					/>
					: <img
						src={crossIMG}
						alt='cross'
					/>}
			</Inline >
		</Stack >,
		<Stack
			distribution='trailing'
			key={country.country.code}
			style={{margin: '0 auto'}}
		>
			<div
				className='error-checkbox-view'
				style={{
					border: errorMessageStatus ? '2px solid red' : 'none',
				}}
			>
				<Checkbox
					checked={
						countriesCheckedList.find(item => item.code === country.country.code)?.autosubmit || false
					}
					onChange={(checked) => handleCheckboxChange(checked, country.country.code)}
				/>
			</div >
			
		</Stack >
	])
	
	return (
		<Layout.AnnotatedSection
			title={
				<Text
					variant='headingLg'
					as='p'
				>
						{t("taxSettings.countries")}
					</Text >
			}
		>
				<Card >
					{!_.isEmpty(countriesList) && !fetchingAutoCountries && (
						<DataTable
							columnContentTypes={["text", "text", "text", "text"]}
							headings={columns.map(({header, align}) => (
								<p style={{textAlign: align, width: '100%', fontWeight: 'bold'}}>
									{header}
								</p >
							))}
							rows={rows}
						/>
					)}
					
					{fetchingAutoCountries && _.isEmpty(countriesList) && (
						<Card.Section >
							<Spinner />
						</Card.Section >
					)}
					{!fetchingAutoCountries && _.isEmpty(countriesList) && (
						<NoDataMessage style={{height: "auto", padding: "1.5rem 0px"}} />
					)}
				</Card >
			{
				errorMessageStatus && (
					<p className='error-message'>
             Please select at least one country from the list.
        </p>
				)
			}
			</Layout.AnnotatedSection >
	)
}

export default AutopilotCountries