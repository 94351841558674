// thresholdsSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import { parseServerError } from "utils/errors";

export const fetchThresholdEU = createAsyncThunk(
  "thresholds/fetchThresholdEU",
  async (params = { year: new Date().getFullYear() }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/dashboard/threshold_by_countries_ec",
        "GET",
        params
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchThresholdUS = createAsyncThunk(
  "thresholds/fetchThresholdUS",
  async (params = { year: new Date().getFullYear() }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/dashboard/threshold_by_countries_us",
        "GET",
        params
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchThresholdRow = createAsyncThunk(
  "thresholds/fetchThresholdRow",
  async (params = { year: new Date().getFullYear() }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/dashboard/threshold_by_countries_row",
        "GET",
        params
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchCalculateId = createAsyncThunk(
  "thresholds/fetchCalculateId",
  async ({ code, year, month, state }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/thresolds/calculate_thresolds?country_code=${code}&year=${year}&month=${month}${
          state ? `&state=${state}` : ""
        }`,
        "POST"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchCalculateThresholds = createAsyncThunk(
  "thresholds/fetchCalculateThresholds",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/thresolds/get_thresold_calculation_results?message_id=${id}`,
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchThresholdFederal = createAsyncThunk(
  "thresholds/fetchThresholdFederal",
  async (params = { year: new Date().getFullYear() }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/dashboard/threshold_by_countries_federal",
        "GET",
        params
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchThresholdFederalCountries = createAsyncThunk(
  "thresholds/fetchThresholdFederalCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/dashboard/threshold_federal_countries",
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

const thresholdsSlice = createSlice({
  name: "thresholds",
  initialState: {
    fetchingThresholdEU: false,
    fetchingThresholdUS: false,
    fetchingThresholdRow: false,
    fetchingThresholdFederal: false,
    fetchingFederalCountries: false,
    fetchingThresholdEUError: null,
    fetchingThresholdUSError: null,
    fetchingThresholdRowError: null,
    fetchingThresholdFederalError: null,
    fetchingFederalCountriesError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchThresholdEU.pending, (state) => {
        state.fetchingThresholdEU = true;
      })
      .addCase(fetchThresholdEU.fulfilled, (state, action) => {
        state.fetchingThresholdEU = false;
        state.fetchingThresholdEUError = null;
        state.thresholdEU = action.payload;
      })
      .addCase(fetchThresholdEU.rejected, (state, action) => {
        state.fetchingThresholdEU = false;
        state.fetchingThresholdEUError = parseServerError(action.error);
      })
      .addCase(fetchThresholdUS.pending, (state) => {
        state.fetchingThresholdUS = true;
      })
      .addCase(fetchThresholdUS.fulfilled, (state, action) => {
        state.fetchingThresholdUS = false;
        state.fetchingThresholdUSError = null;
        state.thresholdUS = action.payload;
      })
      .addCase(fetchThresholdUS.rejected, (state, action) => {
        state.fetchingThresholdUS = false;
        state.fetchingThresholdUSError = parseServerError(action.error);
      })
      .addCase(fetchThresholdRow.pending, (state) => {
        state.fetchingThresholdRow = true;
      })
      .addCase(fetchThresholdRow.fulfilled, (state, action) => {
        state.fetchingThresholdRow = false;
        state.fetchingThresholdRowError = null;
        state.thresholdRow = action.payload;
      })
      .addCase(fetchThresholdRow.rejected, (state, action) => {
        state.fetchingThresholdRow = false;
        state.fetchingThresholdRowError = parseServerError(action.error);
      })
      .addCase(fetchCalculateId.pending, (state) => {
        state.fetchingCalcId = true;
      })
      .addCase(fetchCalculateId.fulfilled, (state) => {
        state.fetchingCalcId = false;
        state.fetchingCalcIdError = null;
      })
      .addCase(fetchCalculateId.rejected, (state, action) => {
        state.fetchingCalcId = false;
        state.fetchingCalcIdError = parseServerError(action.error);
      })
      .addCase(fetchCalculateThresholds.pending, (state) => {
        state.fetchingCalcThresholds = true;
      })
      .addCase(fetchCalculateThresholds.fulfilled, (state) => {
        state.fetchingCalcThresholds = false;
        state.fetchingCalcThresholdsError = null;
      })
      .addCase(fetchCalculateThresholds.rejected, (state, action) => {
        state.fetchingCalcThresholds = false;
        state.fetchingCalcThresholdsError = parseServerError(action.error);
      })
      .addCase(fetchThresholdFederal.pending, (state) => {
        state.fetchingThresholdFederal = true;
      })
      .addCase(fetchThresholdFederal.fulfilled, (state, action) => {
        state.fetchingThresholdFederal = false;
        state.fetchingThresholdFederalError = null;
        state.thresholdFederal = action.payload;
      })
      .addCase(fetchThresholdFederal.rejected, (state, action) => {
        state.fetchingThresholdFederal = false;
        state.fetchingThresholdFederalError = parseServerError(action.error);
      })
      .addCase(fetchThresholdFederalCountries.pending, (state) => {
        state.fetchingFederalCountries = true;
      })
      .addCase(fetchThresholdFederalCountries.fulfilled, (state, action) => {
        state.fetchingFederalCountries = false;
        state.fetchingFederalCountriesError = null;
        state.federalCountries = action.payload;
      })
      .addCase(fetchThresholdFederalCountries.rejected, (state, action) => {
        state.fetchingFederalCountries = false;
        state.fetchingFederalCountriesError = parseServerError(action.error);
      });
  },
});

export default thresholdsSlice.reducer;
