import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import handleErrorForThunk from "helpers/handleErrorForThunk";
import { parseServerError } from "utils/errors";

export const fetchWebsites = createAsyncThunk(
  "websites/fetchWebsites",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/websites", "GET");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const createWebsite = createAsyncThunk(
  "websites/createWebsite",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/websites", "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const resetWebsiteToken = createAsyncThunk(
  "websites/resetWebsiteToken",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await callApi(`/website/${id}/token`, "POST");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const activateWebsite = createAsyncThunk(
  "websites/activateWebsite",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await callApi(`/website/${id}/activate`, "POST");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const integrateShopify = createAsyncThunk(
  "websites/integrateShopify",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/integrations/shopify`, "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const editStore = createAsyncThunk(
  "websites/editStore",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/websites", "PUT", data);
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const cancelIntegration = createAsyncThunk(
  "websites/cancelIntegration",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(`/integrations/${id}`, "DELETE");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const integrateEtsy = createAsyncThunk(
  "websites/integrateEtsy",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/integrations/etsy", "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const integrateEbay = createAsyncThunk(
  "websites/integrateEbay",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/integrations/ebay", "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const integrateAmazon = createAsyncThunk(
  "websites/integrateAmazon",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/integrations/amazon", "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const integrateXero = createAsyncThunk(
  "websites/integrateXero",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/integrations/xero", "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const fetchWebsitesErrorList = createAsyncThunk(
  "websites/fetchWebsitesErrorList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/notify/get_system_message_active?models_name=sites&status=errors",
        "GET"
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchAmazonSPCountries = createAsyncThunk(
  "websites/fetchAmazonSPCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(`/integrations/amazon/sp_country`, "GET");
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const integrateWooCom = createAsyncThunk(
  "websites/integrateWooCom",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/integrations/woocommerce`, "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const integrateOpencart = createAsyncThunk(
  "websites/integrateOpencart",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/integrations/opencart`, "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const integrateMagento = createAsyncThunk(
  "websites/integrateMagento",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/integrations/magento1`, "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const integrateMagento2 = createAsyncThunk(
  "websites/integrateMagento2",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/integrations/magento`, "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const integrateStripe = createAsyncThunk(
  "websites/integrateStripe",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/integrations/stripe`, "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const integratePaypal = createAsyncThunk(
  "websites/integratePaypal",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/integrations/paypal`, "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

const initialState = {
  websites: [],
  activeIntegrations: [],
  errorList: [],
  lastUploaded: [],
  countriesSP: [],
};

const websitesSlice = createSlice({
  name: "websites",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsites.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchWebsites.fulfilled, (state, action) => {
        state.fetching = false;
        state.websites = action.payload;
        state.fetchingError = null;
      })
      .addCase(fetchWebsites.rejected, (state, action) => {
        state.fetching = false;
        state.fetchingError = parseServerError(action.payload);
      })
      .addCase(createWebsite.pending, (state) => {
        state.creating = true;
      })
      .addCase(createWebsite.fulfilled, (state, action) => {
        state.creating = false;
        state.creatingError = null;
        state.createdId = action.payload.id;
      })
      .addCase(createWebsite.rejected, (state, action) => {
        state.creating = false;
        state.creatingError = parseServerError(action.payload);
      })
      .addCase(resetWebsiteToken.pending, (state) => {
        state.reseting = true;
      })
      .addCase(resetWebsiteToken.fulfilled, (state) => {
        state.reseting = false;
        state.resetingError = null;
      })
      .addCase(resetWebsiteToken.rejected, (state, action) => {
        state.reseting = false;
        state.resetingError = parseServerError(action.payload);
      })
      .addCase(activateWebsite.pending, (state) => {
        state.activating = true;
      })
      .addCase(activateWebsite.fulfilled, (state) => {
        state.activating = false;
        state.activatingError = null;
      })
      .addCase(activateWebsite.rejected, (state, action) => {
        state.activating = false;
        state.activatingError = parseServerError(action.payload);
      })
      .addCase(editStore.pending, (state) => {
        state.editingStore = true;
        state.editingStoreError = null;
      })
      .addCase(editStore.fulfilled, (state) => {
        state.editingStore = false;
        state.editingStoreError = null;
      })
      .addCase(editStore.rejected, (state, action) => {
        state.editingStore = false;
        state.editingStoreError = parseServerError(action.payload);
      })
      .addCase(cancelIntegration.pending, (state) => {
        state.deleting = true;
      })
      .addCase(cancelIntegration.fulfilled, (state) => {
        state.deleting = false;
        state.deletingError = null;
      })
      .addCase(cancelIntegration.rejected, (state, action) => {
        state.deleting = false;
        state.deletingError = parseServerError(action.payload);
      })
      .addCase(integrateShopify.pending, (state) => {
        state.integratingShopify = true;
      })
      .addCase(integrateShopify.fulfilled, (state) => {
        state.integratingShopify = false;
        state.integratingShopifyError = null;
      })
      .addCase(integrateShopify.rejected, (state, action) => {
        state.integratingShopify = false;
        state.integratingShopifyError = parseServerError(action.payload);
      })
      .addCase(integrateEtsy.pending, (state) => {
        state.integratingEtsy = true;
      })
      .addCase(integrateEtsy.fulfilled, (state) => {
        state.integratingEtsy = false;
        state.integratingEtsyError = null;
      })
      .addCase(integrateEtsy.rejected, (state, action) => {
        state.integratingEtsy = false;
        state.integratingEtsyError = parseServerError(action.payload);
      })
      .addCase(integrateEbay.pending, (state) => {
        state.integratingEbay = true;
      })
      .addCase(integrateEbay.fulfilled, (state) => {
        state.integratingEbay = false;
        state.integratingEbayError = null;
      })
      .addCase(integrateEbay.rejected, (state, action) => {
        state.integratingEbay = false;
        state.integratingEbayError = parseServerError(action.payload);
      })
      .addCase(integrateAmazon.pending, (state) => {
        state.integratingAmazon = true;
      })
      .addCase(integrateAmazon.fulfilled, (state) => {
        state.integratingAmazon = false;
        state.integratingAmazonError = null;
      })
      .addCase(integrateAmazon.rejected, (state, action) => {
        state.integratingAmazon = false;
        state.integratingAmazonError = parseServerError(action.payload);
      })
      .addCase(integrateXero.pending, (state) => {
        state.integratingXero = true;
      })
      .addCase(integrateXero.fulfilled, (state) => {
        state.integratingXero = false;
        state.integratingXeroError = null;
      })
      .addCase(integrateXero.rejected, (state, action) => {
        state.integratingXero = false;
        state.integratingXeroError = parseServerError(action.payload);
      })
      .addCase(fetchAmazonSPCountries.pending, (state) => {
        state.fetchingSP = true;
      })
      .addCase(fetchAmazonSPCountries.fulfilled, (state, action) => {
        state.countriesSP = action.payload;
        state.fetchingSP = false;
        state.fetchingSPError = null;
      })
      .addCase(fetchAmazonSPCountries.rejected, (state, action) => {
        state.fetchingSP = false;
        state.fetchingSPError = parseServerError(action.payload);
      })
      .addCase(integrateWooCom.pending, (state) => {
        state.integratingWooCom = true;
      })
      .addCase(integrateWooCom.fulfilled, (state) => {
        state.integratingWooCom = false;
        state.integratingWooComError = null;
      })
      .addCase(integrateWooCom.rejected, (state, action) => {
        state.integratingWooCom = false;
        state.integratingWooComError = parseServerError(action.payload);
      })
      .addCase(integrateOpencart.pending, (state) => {
        state.integratingOpencart = true;
      })
      .addCase(integrateOpencart.fulfilled, (state) => {
        state.integratingOpencart = false;
        state.integratingOpencartError = null;
      })
      .addCase(integrateOpencart.rejected, (state, action) => {
        state.integratingOpencart = false;
        state.integratingOpencartError = parseServerError(action.payload);
      })
      .addCase(integrateMagento.pending, (state) => {
        state.integratingMagento = true;
      })
      .addCase(integrateMagento.fulfilled, (state) => {
        state.integratingMagento = false;
        state.integratingMagentoError = null;
      })
      .addCase(integrateMagento.rejected, (state, action) => {
        state.integratingMagento = false;
        state.integratingMagentoError = parseServerError(action.payload);
      })
      .addCase(integrateMagento2.pending, (state) => {
        state.integratingMagento2 = true;
      })
      .addCase(integrateMagento2.fulfilled, (state) => {
        state.integratingMagento2 = false;
        state.integratingMagento2Error = null;
      })
      .addCase(integrateMagento2.rejected, (state, action) => {
        state.integratingMagento2 = false;
        state.integratingMagento2Error = parseServerError(action.payload);
      })
      .addCase(integrateStripe.pending, (state) => {
        state.integratingStripe = true;
      })
      .addCase(integrateStripe.fulfilled, (state) => {
        state.integratingStripe = false;
        state.integratingStripeError = null;
      })
      .addCase(integrateStripe.rejected, (state, action) => {
        state.integratingStripe = false;
        state.integratingStripeError = parseServerError(action.payload);
      })
      .addCase(integratePaypal.pending, (state) => {
        state.integratingPaypal = true;
      })
      .addCase(integratePaypal.fulfilled, (state) => {
        state.integratingPaypal = false;
        state.integratingPaypalError = null;
      })
      .addCase(integratePaypal.rejected, (state, action) => {
        state.integratingPaypal = false;
        state.integratingPaypalError = parseServerError(action.payload);
      });
  },
});

export default websitesSlice.reducer;
