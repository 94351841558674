import React from "react";
import { Inline, Text, Link } from "@shopify/polaris";

export default function InlineText(props) {
  return (
    <Inline>
      <Text>{props.label}:</Text>
      {props.linkText ? (
        <Link onClick={props.onLinkNavigate}>{props.linkText}</Link>
      ) : (
        <Text color='subdued'>{props.text}</Text>
      )}
    </Inline>
  );
}
