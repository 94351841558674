import React from "react";
import ArrowCircleLeftIMG from "img/arrow-circle-left.svg";
import { Button, Inline } from "@shopify/polaris";
import "./index.scss";

const BackArrowWithComponent = ({ withComponent, onClick }) => {
  return (
    <Inline alignY='center' spacing='2'>
      <Button
        plain
        onClick={onClick}
        icon={
          <img
            src={ArrowCircleLeftIMG}
            alt='arrow back'
            className='arrow-back-fixer'
          />
        }
      />
      {withComponent}
    </Inline>
  );
};

export default BackArrowWithComponent;
