export default function handleErrorForThunk(err) {
  try {
    // Attempt to parse the error message in case it's JSON.
    const parsedError = JSON.parse(err.message);
    // If the parsed error is an object, use it; otherwise, wrap it in an object with an 'error' property.
    return typeof parsedError === "object"
      ? parsedError
      : { error: parsedError };
  } catch {
    // If parsing fails, assume the error message is a plain string and wrap it.
    return { error: err.message || "An unknown error occurred" };
  }
}
