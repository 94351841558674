import _ from "lodash";
import { email } from "utils/validation";
import moment from "moment";

const noValidate = _.noop;

const createSchemas = ({ t }) => {
  const emptyFieldErrorString = t("dac7.pleaseFillThisField");

  const validateText = (value) => (!value ? emptyFieldErrorString : false);

  const validateEmail = (value) =>
    !value ? emptyFieldErrorString : email(value);

  const merchantSchema = [
    {
      type: "input",
      props: {
        id: "merchant_name",
        type: "text",
        inputMode: "text",
        placeholder: "Merchant name",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "address",
        type: "text",
        inputMode: "text",
        placeholder: "Address",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "gross_amount",
        type: "text",
        inputMode: "text",
        placeholder: "Gross amount of transactions",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "card",
        type: "text",
        inputMode: "text",
        placeholder: "Card Not Present transactions",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "tin",
        type: "text",
        inputMode: "text",
        placeholder: "TIN",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "merchant",
        type: "text",
        inputMode: "text",
        placeholder: "Merchant category code (relevant activity)",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "number_of_payment",
        type: "text",
        inputMode: "text",
        placeholder: "Number of payment transactions",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "federal",
        type: "text",
        inputMode: "text",
        placeholder: "Federal income tax withheld",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "state",
        type: "text",
        inputMode: "text",
        placeholder: "State",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "state_no",
        type: "text",
        inputMode: "text",
        placeholder: "State identification no",
        validate: noValidate,
      },
    },
    {
      type: "input",
      props: {
        id: "state_income",
        type: "text",
        inputMode: "text",
        placeholder: "State income tax withheld",
        validate: noValidate,
      },
    },
  ];

  const tableSchema = [
    {
      property: "merchant_name",
      header: {
        label: t("dac7.name"),
      },
    },
    {
      property: "relevant_activities",
      header: {
        label: "Relevant activity",
      },
    },
    {
      property: "total_gross_amount",
      header: {
        label: t("dac7.totalGrossAmountOfTransactions"),
      },
    },
    {
      property: "total_number_of_transactions",
      header: {
        label: t("dac7.numberOfTransactions"),
      },
    },
  ];

  return {
    merchantSchema,
    tableSchema,
  };
};

export { createSchemas };
