import { Button, ButtonGroup } from '@shopify/polaris'
import Modal from 'components/Modal'

const ModalForRequestIntegration = ({
	changeName,
	toggleModal,
	toggleModalRenderModalForRequestIntegration
}) => {
	
	const formatString = (input) => {
		return input
			.split('_')
			.map(word => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}
	
	return (
		<Modal
			contentOnCenter
			title={`Request for ${formatString(changeName)} integration.`}
			iconType={""}
			description={`Do you want to order integration with ${formatString(changeName)}?`}
			visible={toggleModal}
			onClose={() => toggleModalRenderModalForRequestIntegration(false, '')}
			footer={
				<ButtonGroup fullWidth distribution='center'>
          <Button
	          primary
	          // onClick={() => toggleModalRenderModalForRequestIntegration(false)} // Закрываем модал при нажатии "No"
	          size='large'
          >
            Order Integration
          </Button>
        </ButtonGroup>
			}
		/>
	);
}

export default ModalForRequestIntegration;
