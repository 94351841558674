import React from "react";
import { Layout, Text } from "@shopify/polaris";
import DeleteCompany from "../DeleteCompany";
import Accordion from "components/Accordion";
import CancelSubscriptionBuilder from "../CancelSubscription";

import "./index.scss";

const AdvancedSettings = (props) => {
  return (
    <Layout.AnnotatedSection>
      <Layout.Section>
        <div className='advancedSettings'>
          <Accordion
            title={
              <Text variant='headingLg' as='p'>
                Advanced Settings
              </Text>
            }
            description={
              "Here you can adjust advanced settings of your account"
            }
            content={
              <div className='advancedSettings-list'>
                <DeleteCompany {...props} />
                <CancelSubscriptionBuilder />
              </div>
            }
          />
        </div>
      </Layout.Section>
    </Layout.AnnotatedSection>
  );
};

export default AdvancedSettings;
