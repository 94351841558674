import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import { parseServerError } from "utils/errors";

export const fetchUndisclosedScheme = createAsyncThunk(
  "ukSettings/fetchUndisclosedScheme",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/uk_tax_settings/undisclosed_agent_scheme",
        "GET"
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const switchUndisclosedScheme = createAsyncThunk(
  "ukSettings/switchUndisclosedScheme",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/uk_tax_settings/undisclosed_agent_scheme",
        "PUT",
        data
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

// Slice
const ukSettingsSlice = createSlice({
  name: "ukSettings",
  initialState: {
    undisclosed: false,
    fetchingUndisclosed: false,
    fetchingUndisclosedError: null,
    switching: false,
    switchingError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUndisclosedScheme.pending, (state) => {
        state.fetchingUndisclosed = true;
      })
      .addCase(fetchUndisclosedScheme.fulfilled, (state, action) => {
        state.fetchingUndisclosed = false;
        state.undisclosed = action.payload.undisclosed_agent_scheme;
        state.fetchingUndisclosedError = null;
      })
      .addCase(fetchUndisclosedScheme.rejected, (state, action) => {
        state.fetchingUndisclosed = false;
        state.fetchingUndisclosedError = parseServerError(action.payload);
      })
      .addCase(switchUndisclosedScheme.pending, (state) => {
        state.switching = true;
        state.switchingError = null;
      })
      .addCase(switchUndisclosedScheme.fulfilled, (state) => {
        state.switching = false;
        state.switchingError = null;
      })
      .addCase(switchUndisclosedScheme.rejected, (state, action) => {
        state.switching = false;
        state.switchingError = parseServerError(action.payload);
      });
  },
});

export default ukSettingsSlice.reducer;
