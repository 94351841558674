const dac7 = {
  dac7Reporting: "DAC7 reporting",
  businessName: "Business name",
  periodYear: "Period year",
  status: "Status",
  reportingPeriod: "Reporting period",
  fillDac7Report: "Fill DAC7 report",
  step1: "Step 1",
  preliminaryReport: "Preliminary report",
  merchantNotifications: "Merchant notifications",
  summary: "Summary",
  uploadTransactions: "Upload transactions",
  manualUploadMerchantData: "Manual upload merchant data",
  name: "Name",
  legalStatus: "Legal status",
  totalGrossAmountOfTransactions: "Total gross amount of transactions",
  numberOfTransactions: "Number of transactions",
  merchantEdit: "Merchant edit",
  merchantName: "Merchant name",
  userStatus: "User status",
  tin: "TIN",
  tinIssuedByOptional: "TIN issued by (optional)",
  email: "Email",
  telephoneNumber: "Telephone number (optional)",
  merchantsIbanOptional: "Merchants IBAN (optional)",
  bicOptional: "BIC (optional)",
  country: "Country",
  city: "City",
  addressLine: "Address line",
  zip: "Zip",
  birthDate: "Birth date",
  taxNumber: "VAT number (optional)",
  relevantActivity: "Relevant activity",
  legalEntity: "Legal entity",
  grossAmountOfTransactionsCoConsumptionAndSales:
    "Gross amount of transactions relating to co-consumption and sales of goods",
  totalNumberOfTransactions: "Total number of transactions",
  numberOfTransactionsCoConsumptionAndSales:
    "Number of transactions relating to co-consumption and sales of goods",
  totalAmountOfCommissionsPaidToMarketplace:
    "Total amount of commissions paid to marketplace",
  taxWithheld: "TAX withheld",
  addInfoForAnotherRelevantActivity: "Add info for another relevant activity",
  cancel: "Cancel",
  submit: "Submit",
  save: "Save",
  downloadReport: "Download report",
  edit: "Edit",
  addNewMerchant: "Add new merchant",
  merchantId: "Merchant ID",
  merchantBusinessName: "Merchant business name",
  commercialNameOrUsernameOnThePlatform:
    "Commercial name or username on the platform",
  individual: "Individual",
  add: "Add",
  upload: "Upload",
  uploadFile: "Upload file",
  sendMerchantNotificationsViaLovatPlatform:
    "I want to send merchant notifications via Lovat Platform",
  sendMerchantNotificationsMyself: "I send the merchant notifications myself",
  pleaseAddReturnEmailAddress:
    "Please add a return email address for sending notifications",
  thisAddressWillBeIndicatedAsSenderContactEmail:
    "This address will be indicated as a sender contact email in merchant notification emails",
  pleaseChooseNotificationMethod: "Please choose the notification method",
  digital: "Digital",
  paper: "Paper",
  examplesOfMerchantNotificationPdfFiles:
    "Examples of merchant notification PDF files",
  dac7Submit: "Dac7 submit",
  dac7SubmitImmovable: "Dac7 immovable property submit",
  dac7Preview: "Dac7 preview",
  dac7PreviewImmovable: "Dac7 immovable property preview",
  pleaseFillThisField: "Please fill this field",
  warning: "Warning",
  success: "Success",
  editMerchantInfo: "Edit merchant info",
  dac7ReportSuccessfullySubmitted:
    "Your Dac7 report was successfully submitted!",
  dac7ReportSuccessfullySaved: "Your Dac7 report was successfully saved!",
  merchantsWithWarnings:
    "You have some merchants with warnings, please edit them before proceeding to the next step.",
  ompFacilitators:
    "OMP facilitators obliged to send information to every merchant",
  clickObSubmit: `By clicking on the 'Submit' button, you agree to the following: When you submit this DAC7 information you are making a legal declaration that the information is true and complete. A false declaration can result in prosecution`,
  notificationConsent: "Notification Consent",
  consentToSendMerchantNotifications:
    "I consent to send merchant notifications via Lovat",
  propertyAddress: "Property address",
  propertyType: "Property type",
  numberOfRentedDays: "Number of rented days (optional)",
  landRegistrationNumber: "Land Registration Number",
  addInfoForAnotherImmovableProperty: "Add info for another immovable property",
};

export default dac7;
