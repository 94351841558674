const dac7 = {
  dac7Reporting: "Informe de DAC7",
  businessName: "Nombre comercial",
  periodYear: "Período año",
  status: "Estado",
  reportingPeriod: "Período de informe",
  fillDac7Report: "Rellene el informe de DAC7",
  step1: "Paso 1",
  preliminaryReport: "Informe preliminar",
  merchantNotifications: "Notificaciones a comerciante",
  summary: "Resumen",
  uploadTransactions: "Carga de transacciones",
  manualUploadMerchantData: "Carga manual de datos de comerciantes",
  name: "Nombre",
  legalStatus: "Estatuto jurídico",
  totalGrossAmountOfTransactions: "Importe total bruto de las transacciones",
  numberOfTransactions: "Número de transacciones",
  merchantEdit: "Editar comerciante",
  merchantName: "Nombre de comerciante",
  userStatus: "Estado de usuario",
  tin: "TIN",
  tinIssuedByOptional: "TIN (NIF) expedido (opcional)",
  email: "Correo electrónico",
  telephoneNumber: "Número de teléfono (opcional)",
  merchantsIbanOptional: "IBAN del comerciante (opcional)",
  bicOptional: "BIC (opcional)",
  country: "País",
  city: "Ciudad",
  addressLine: "Dirección",
  zip: "Código postal",
  birthDate: "Fecha de nacimiento",
  taxNumber: "Número de IVA (opcional)",
  relevantActivity: "Actividad relevante",
  legalEntity: "Entidad legal",
  grossAmountOfTransactionsCoConsumptionAndSales:
    "Importe bruto de las transacciones relacionadas con el co-consumo y las ventas de bienes",
  totalNumberOfTransactions: "Número total de transacciones",
  numberOfTransactionsCoConsumptionAndSales:
    "Número de transacciones relacionadas con el co-consumo y la venta de bienes",
  totalAmountOfCommissionsPaidToMarketplace:
    "Importe total de las comisiones pagadas al markeplace",
  taxWithheld: "IVA retenido",
  addInfoForAnotherRelevantActivity:
    "Añadir información para otra actividad relevante",
  cancel: "Cancelar",
  submit: "Enviar",
  save: "Guardar",
  downloadReport: "Descargar informe",
  edit: "Editar",
  addNewMerchant: "Agregar nuevo comerciante",
  merchantId: "ID de comerciante",
  merchantBusinessName: "Nombre comercial de comerciante",
  commercialNameOrUsernameOnThePlatform:
    "Nombre comercial o nombre de usuario en la plataforma",
  individual: "Individual",
  add: "Añadir",
  upload: "Cargar",
  uploadFile: "Cargar el archivo",
  sendMerchantNotificationsViaLovatPlatform:
    "Quiero enviar notificaciones a comerciantes a través de la Plataforma Lovat",
  sendMerchantNotificationsMyself:
    "Yo mismo envío las notificaciones a los comerciantes",
  pleaseAddReturnEmailAddress:
    "Añada una dirección de correo electrónico para el envío de notificaciones",
  thisAddressWillBeIndicatedAsSenderContactEmail:
    "Esta dirección se indicará como dirección de contacto del remitente en los correos electrónicos de notificación al comerciante",
  pleaseChooseNotificationMethod: "Elija el método de notificación",
  digital: "Digital",
  paper: "Papel",
  examplesOfMerchantNotificationPdfFiles:
    "Ejemplos de notificación a comerciantes en los archivos de PDF",
  dac7Submit: "Presentar DAC7",
  dac7SubmitImmovable: "Presentar propiedad inmueble DAC7",
  dac7Preview: "Vista previa de DAC7",
  dac7PreviewImmovable: "Vista previa de propiedad inmueble DAC7",
  success: "Éxito",
  dac7ReportSuccessfullySubmitted:
    "¡Su informe de DAC7 ha sido enviado con éxito!",
  dac7ReportSuccessfullySaved:
    "¡Su informe de DAC7 ha sido guardado con éxito!",
  warning: "Atención",
  pleaseFillThisField: "Rellene este campo, por favor",
  merchantsWithWarnings:
    "Tiene algunos comerciantes con advertencias, por favor edítelos antes de continuar con el siguiente paso.",
  ompFacilitators:
    "Los facilitadores de OMP están obligados a enviar información a todos los comerciantes",
  editMerchantInfo: "editar la información del comerciante",
  clickObSubmit: `Al hacer clic en el botón Enviar, Ud. acepta lo siguiente: Al enviar esta información de DAC7 Ud. declara que la información es verdadera y completa. Una declaración falsa puede dar lugar a acciones judiciales.`,
  notificationConsent: "Consentimiento de Notificación",
  consentToSendMerchantNotifications:
    "Consiento enviar notificaciones a comerciantes a través de Lovat",
  propertyAddress: "Dirección del inmueble",
  propertyType: "Tipo de propiedad",
  numberOfRentedDays: "Número de días alquilados (opcional)",
  landRegistrationNumber: "Número de registro de la propiedad",
  addInfoForAnotherImmovableProperty:
    "Añadir información para otra propiedad inmueble",
};

export default dac7;
