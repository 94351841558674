import React, { useState } from "react";
import { Button, ButtonGroup, DropZone, Tag } from "@shopify/polaris";
import Modal from "components/Modal";
import { uploadDocuments } from "redux/features/documents/documentsSlice";
import useAppDispatch from "hooks/useAppDispatch";
import _, { uniqueId } from "lodash";

const ScanFiles = (props) => {
  const dispatch = useAppDispatch();
  const [attachment, setAttachment] = useState({});
  const { onClose, afterScan, isOpen } = props;

  const onScanClick = () => {
    const files = new FormData();

    for (let i = 0; i < attachment.length; i++) {
      const { file } = attachment[i];

      files.append(`file_${i}`, file, file.name);
    }

    dispatch(uploadDocuments(files)).then(() => {
      afterScan();
      onClose();
    });
  };

  const onDropFile = (files) => {
    setAttachment(() => {
      return files.map((file) => ({
        id: uniqueId(),
        file,
      }));
    });
  };

  const onRemoveFile = (id) => {
    setAttachment((prev) => {
      return prev.filter((file) => file.id !== id);
    });
  };

  return (
    <Modal
      title='Scan files'
      description='Please attach files you want to scan.'
      visible={isOpen}
      onClose={onClose}
      content={
        <>
          <br />
          {_.isEmpty(attachment) ? (
            <DropZone onDrop={onDropFile} allowMultiple={false}>
              <DropZone.FileUpload actionTitle='Upload files' />
            </DropZone>
          ) : (
            attachment.map(({ id, file }) => {
              return (
                <Tag onRemove={() => onRemoveFile(id)} key={id}>
                  {file.name}
                </Tag>
              );
            })
          )}
        </>
      }
      footer={
        <>
          <ButtonGroup fullWidth>
            <Button size='large' onClick={onClose}>
              Cancel
            </Button>
            <Button
              size='large'
              onClick={onScanClick}
              loading={false}
              primary
              disabled={false}
            >
              Scan
            </Button>
          </ButtonGroup>
        </>
      }
    />
  );
};

export default ScanFiles;
