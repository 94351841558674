import React from 'react';
import Quantity from '../Quantity';
import QuantityForTwoSteps from '../Quantity/QuantityForTwoSteps';
import SubcategoryStep1 from '../Step_1';
import SubcategoryStep2 from '../Step_2';
import SubcategoryStep3 from '../Step_3';
import SubcategoryStep4 from '../Step_4';
import SubcategoryStep5 from '../Step_5';
import Summary from '../Summary';
import SummaryForTwoSteps from '../Summary/SummaryForTwoSteps';

const StepLogicHandler = ({
	stepIndex,
	eprDeclaration,
	subcategoryProps,
	handleActionDialogsOpen,
	renderSummaryStepBar,
}) => {
	const renderStep = () => {
		if (eprDeclaration?.type_of_report === 9) {
			if (eprDeclaration?.customer_country_id === 'CZE') {
				switch (stepIndex) {
					case 1:
						return (
							<SubcategoryStep1
								toNextStep={subcategoryProps.toNextStep}
								toPrevStep={() => handleActionDialogsOpen('reset', {})}
							/>
						);
					case 2:
						return <SubcategoryStep2 {...subcategoryProps} />;
					case 3:
						return <QuantityForTwoSteps {...subcategoryProps} />;
					case 4:
						return <SummaryForTwoSteps stepBar={renderSummaryStepBar} />;
					default:
						return <SubcategoryStep1 toNextStep={subcategoryProps.toNextStep} />;
				}
			} else if (eprDeclaration?.customer_country_id === 'ESP') {
				switch (stepIndex) {
					case 1:
						return (
							<SubcategoryStep1
								toNextStep={subcategoryProps.toNextStep}
								toPrevStep={() => handleActionDialogsOpen('reset', {})}
							/>
						);
					case 2:
						return <SubcategoryStep2 {...subcategoryProps} />;
					case 3:
						return <SubcategoryStep3 {...subcategoryProps} />;
					case 4:
						return <Quantity {...subcategoryProps} />;
					case 5:
						return <Summary stepBar={renderSummaryStepBar} />;
					default:
						return <SubcategoryStep1 toNextStep={subcategoryProps.toNextStep} />;
				}
			} else {
				switch (stepIndex) {
					case 1:
						return (
							<SubcategoryStep1
								toNextStep={subcategoryProps.toNextStep}
								toPrevStep={() => handleActionDialogsOpen('reset', {})}
							/>
						);
					case 2:
						return <SubcategoryStep2 {...subcategoryProps} />;
					case 3:
						return <SubcategoryStep3 {...subcategoryProps} />;
					case 4:
						return <Quantity {...subcategoryProps} />;
					case 5:
						return <Summary stepBar={renderSummaryStepBar} />;
					default:
						return <SubcategoryStep1 toNextStep={subcategoryProps.toNextStep} />;
				}
			}
		} else if (eprDeclaration?.type_of_report === 11) {
			switch (stepIndex) {
				case 1:
					return (
						<SubcategoryStep1
							toNextStep={subcategoryProps.toNextStep}
							toPrevStep={() => handleActionDialogsOpen('reset', {})}
						/>
					);
				case 2:
					return <SubcategoryStep2 {...subcategoryProps} />;
				case 3:
					return <SubcategoryStep3 {...subcategoryProps} />;
				case 4:
					return <Quantity {...subcategoryProps} />;
				case 5:
					return <Summary stepBar={renderSummaryStepBar} />;
				default:
					return <SubcategoryStep1 toNextStep={subcategoryProps.toNextStep} />;
			}
		} else if (eprDeclaration?.type_of_report === 7 && (eprDeclaration?.customer_country_id === 'CZE' || eprDeclaration?.customer_country_id === 'SVK' || eprDeclaration?.customer_country_id === 'FRA')) {
			switch (stepIndex) {
				case 1:
					return (
						<SubcategoryStep1
							toNextStep={subcategoryProps.toNextStep}
							toPrevStep={() => handleActionDialogsOpen('reset', {})}
						/>
					);
				case 2:
					return <SubcategoryStep2 {...subcategoryProps} />;
				case 3:
					return <SubcategoryStep3 {...subcategoryProps} />;
				case 4:
					return <SubcategoryStep4 {...subcategoryProps} />;
				case 5:
					return <Quantity {...subcategoryProps} />;
				case 6:
					return <Summary stepBar={renderSummaryStepBar} />;
				default:
					return <SubcategoryStep1 toNextStep={subcategoryProps.toNextStep} />;
			}
		} else {
			switch (stepIndex) {
				case 1:
					return (
						<SubcategoryStep1
							toNextStep={subcategoryProps.toNextStep}
							toPrevStep={() => handleActionDialogsOpen('reset', {})}
						/>
					);
				case 2:
					return <SubcategoryStep2 {...subcategoryProps} />;
				case 3:
					return <SubcategoryStep3 {...subcategoryProps} />;
				case 4:
					return <SubcategoryStep4 {...subcategoryProps} />;
				case 5:
					return <SubcategoryStep5 {...subcategoryProps} />;
				case 6:
					return <Quantity {...subcategoryProps} />;
				case 7:
					return <Summary stepBar={renderSummaryStepBar} />;
				default:
					return <SubcategoryStep1 toNextStep={subcategoryProps.toNextStep} />;
			}
		}
	};
	
	return <>{renderStep()}</>;
};

export default StepLogicHandler;
