export function getCurrencySymbol(c) {
  const currency = c?.toLowerCase() || '';
  switch (currency) {
    case 'eur':
      return ' \u{20AC} ';
    case 'gbp':
      return ' \u{00A3} ';
    default:
      return ' \u{0024} ';
  }
}
