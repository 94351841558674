/**
 * Splits an array into chunks of a specified size.
 *
 * @param {Array} array - The array to be chunked.
 * @param {number} chunkSize - The size of each chunk.
 * @returns {Array} An array of arrays, where each inner array represents a chunk of the original array.
 */

const chunkArray = (array, chunkSize) => {
  return Array.from(
    { length: Math.ceil(array.length / chunkSize) },
    (_, index) => array.slice(index * chunkSize, (index + 1) * chunkSize)
  );
};

export default chunkArray;
