import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import withRouter from "helpers/withRouter";
import ProfileCompany from "./Company/Company";
import ProfileUsers from "./Users/Users";
import ProfileMembership from "./Membership";
import AdvancedSettings from "./AdvancedSettings";
import BillingContact from "./BillingContact";

class CompanySettings extends Component {
  render() {
    return (
      <>
        <ProfileCompany {...this.props} />
        <BillingContact />
        <ProfileUsers {...this.props} />
        <ProfileMembership {...this.props} />
        <AdvancedSettings {...this.props} />
      </>
    );
  }
}

export default withTranslation()(withRouter(CompanySettings));
