import { RadioButton, Stack, Text, TextField } from '@shopify/polaris'
import React from 'react'
import { CancelSubscriptionVariant } from './CancelSubscriptionVariant'

const CancelSubscriptionStepTwo = ({
	changeQuestion,
	saveChangeQuestion,
	writeYourReasonValue,
}) => {
	return (
		<div className='cancelSubscriptionBuilder-description'>
        <h2 className='cancelSubscriptionBuilder-description_topTitle'>
	        These reasons can help better understand why customers choose to cancel their subscriptions and allow for service improvements.
				</h2 >
	      <Stack vertical>
		      {
			      CancelSubscriptionVariant.map((item) =>
				      <RadioButton
					      key={item.id}
					      label={
						      <div >
		            <Text
			            variant='bodyLg'
			            fontWeight='bold'
			            as='span'
		            >
		              {item.title}
		            </Text >
					      <br />
		            <Text
			            variant='bodyLg'
			            as='span'
			            color='subdued'
			            style={{marginLeft: '4px'}}
		            >
		              {item.subTitle}
		            </Text >
		          </div >
					      }
					      value={item.title}
					      checked={changeQuestion === item.id}
					      onChange={() => saveChangeQuestion(item.id, item.title)}
				      />
			      )
		      }
		      {
			      changeQuestion === 8
			      &&
			      <TextField
				      label='Write your reason'
				      value={writeYourReasonValue}
				      onChange={(value) => saveChangeQuestion(8, value)}
				      multiline={3}
			      />
		      }
        </Stack >
	      
      </div >
	)
}

export default CancelSubscriptionStepTwo