/* eslint-disable no-restricted-globals */
import moment from "moment";
import "moment-timezone";

/**
 * Retrieves data related to the user's environment, including timezone, device characteristics,
 * browser dimensions, and installed plugins.
 *
 * @returns {object} - An object containing information about the user's environment.
 * @property {string} timezone - The timezone guessed using the moment-timezone library.
 * @property {object} device - Information about the user's device.
 * @property {number} device.width - The width of the screen.
 * @property {number} device.height - The height of the screen.
 * @property {number} device.color_quality - The color quality of the screen.
 * @property {number} device.bit_depth - The bit depth of the screen.
 * @property {number} device.scale - The device pixel ratio.
 * @property {object} browser - Information about the user's browser dimensions.
 * @property {number} browser.width - The width of the browser window.
 * @property {number} browser.height - The height of the browser window.
 * @property {Array<string>} plugins - An array of plugin names installed in the browser.
 */
export function getHmrcData() {
  const data = {
    timezone: moment.tz.guess(),
    device: {
      width: screen.width,
      height: screen.height,
      color_quality: screen.colorDepth,
      bit_depth: screen.pixelDepth,
      scale: window.devicePixelRatio,
    },
    browser: {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    },
    plugins: Object.entries(navigator.plugins).map((pl) => pl[1].name),
  };

  return data;
}
