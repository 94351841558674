import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import { parseServerError } from "utils/errors";

export const fetchDueDates = createAsyncThunk(
  "dashboard/fetchDueDates",
  async (params = { limit: 25, offset: 0 }, { rejectWithValue }) => {
    try {
      const response = await callApi("/dashboard/due_dates", "GET", params);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

const initialState = {
  dueDates: {},
  fetching: false,
  fetchingDueDatesError: null,
};

const dueDatesSlice = createSlice({
  name: "dueDates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDueDates.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchDueDates.fulfilled, (state, action) => {
        state.fetching = false;
        state.fetchingDueDatesError = null;
        state.dueDates = action.payload;
      })
      .addCase(fetchDueDates.rejected, (state, action) => {
        state.fetching = false;
        state.fetchingDueDatesError = parseServerError(action.error);
      });
  },
});

export default dueDatesSlice.reducer;
