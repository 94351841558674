import React from "react";
import PropTypes from "prop-types";
import { AlphaStack, Grid, Page, Text } from "@shopify/polaris";

import LogoIcon from "img/logo.svg";
import parrotIMG from "img/authParrot.svg";

import "./index.scss";

const vatComplianceUrl = "https://vatcompliance.co/";

const AuthContainer = (props) => {
  const {
    content = null,
    title = "",
    description = "",
    actions = null,
    secondaryContent = null,
  } = props;

  return (
    <div className='loginWrap'>
      <Page>
        <div className='LoginTopBlockWrapper' style={{ marginBottom: 82 }}>
          <a href={vatComplianceUrl}>
            <img src={LogoIcon} width='105px' alt='logo' />
          </a>
        </div>

        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <AlphaStack>
              {title && (
                <Text as='h1' variant='heading3xl' fontWeight='bold'>
                  {title}
                </Text>
              )}
              {description && <Text color='subdued'>{description}</Text>}
            </AlphaStack>
            <br />
            {content}
            {actions && (
              <>
                <br />
                {actions}
              </>
            )}
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }} l>
            {secondaryContent}
          </Grid.Cell>
        </Grid>
        <img src={parrotIMG} alt='parrot' className='parrotImg' />
      </Page>
    </div>
  );
};

AuthContainer.propTypes = {
  content: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  actions: PropTypes.node,
  secondaryContent: PropTypes.node,
};

export default AuthContainer;
