import React from "react";

import "./index.scss";
import { useSelector } from "react-redux";
import { Avatar, Text } from "@shopify/polaris";
import { useClickOutside } from "hooks/useClickOutside";

import ProfileSettingsIMG from "img/user-edit.svg";
import LogoutIMG from "img/logout_userProfile.svg";
import ChangeCompanyIMG from "img/company_suit.svg";

const getUserInitials = (first_name, last_name) => {
  if (!first_name || !last_name) {
    return "";
  }

  return first_name.at(0) + last_name.at(0);
};

const UserProfile = ({
  onProfileSettingsClick,
  onLogoutClick,
  onChangeCompanyClick,
  isVisible,
  handleClose,
}) => {
  const {
    user: { email, first_name, last_name },
  } = useSelector((state) => state.user);

  const initials = getUserInitials(first_name, last_name);
  const userName = `${first_name} ${last_name}`;

  const userProfileRef = useClickOutside(handleClose);

  if (!isVisible) {
    return null;
  }

  return (
    <div className='userProfile' ref={userProfileRef}>
      <div className='userProfile-Wrapper'>
        <div className='userProfile-header'>
          <Avatar initials={initials} />
          <div>
            <Text variant='bodyLg' fontWeight='medium'>
              {userName}
            </Text>
            <Text color='subdued' variant='bodyMd'>
              {email}
            </Text>
          </div>
        </div>
        <div className='userProfile-content'>
          <div
            className='userProfile-content-option'
            onClick={onProfileSettingsClick}
          >
            <img src={ProfileSettingsIMG} alt='profile settings' />
            <Text variant='bodyMd'>Profile settings</Text>
          </div>
          <div
            className='userProfile-content-option'
            onClick={onChangeCompanyClick}
          >
            <img src={ChangeCompanyIMG} alt='change company' />
            <Text variant='bodyMd'>Change company</Text>
          </div>
          <div className='userProfile-content-option' onClick={onLogoutClick}>
            <img src={LogoutIMG} alt='log out' />
            <Text variant='bodyMd'>Log out</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
