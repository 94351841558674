import {
  Button,
  Checkbox,
  FormLayout,
  Stack,
  TextStyle,
} from "@shopify/polaris";
import _ from "lodash";
import Select from "react-select";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";

class TopMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: "",
      filterActive: false,
      filterTrial: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props;
    if (!prevProps.selected && selected) {
      this.updateSelected();
    }
  }

  updateSelected = () => {
    const { selected } = this.props;
    this.setState({
      selected,
    });
  };

  handleSelectChange = (value) => {
    this.setState({ selected: value });
  };

  render() {
    const { title, loadingButtonClick } = this.props;
    const { selected, filterActive, filterTrial } = this.state;
    let options = [];

    if (this.props.options) {
      let filteredOptions = [...this.props.options];
      if (filterActive && filterTrial) {
        filteredOptions = _.filter(
          filteredOptions,
          (item) => item.status === "active" || item.status === "trial"
        );
      } else if (filterActive) {
        filteredOptions = _.filter(
          filteredOptions,
          (item) => item.status === "active"
        );
      } else if (filterTrial) {
        filteredOptions = _.filter(
          filteredOptions,
          (item) => item.status === "trial"
        );
      } else {
        filteredOptions = filteredOptions;
      }
      options = filteredOptions.map((company) => {
        return {
          label: company.name,
          value: company.id,
        };
      });
    }

    const menuOptions = [
      {
        label: "",
        value: "",
      },
      ...options,
    ];

    return (
      <div
        style={{
          padding: "10px",
          backgroundColor: "#ffffff",
          borderBottom: "1px solid #dfe3e8",
        }}
      >
        <div className='Polaris-Page'>
          <Stack alignment='center' distribution='equalSpacing'>
            <p>
              <TextStyle variation='strong'>{title}</TextStyle>
            </p>
            <Stack alignment='center'>
              <Checkbox
                label='Active'
                checked={filterActive}
                onChange={() =>
                  this.setState((prevState) => ({
                    filterActive: !prevState.filterActive,
                    selected: "",
                  }))
                }
              />
              <Checkbox
                label='Trial'
                checked={filterTrial}
                onChange={() =>
                  this.setState((prevState) => ({
                    filterTrial: !prevState.filterTrial,
                    selected: "",
                  }))
                }
              />
            </Stack>
            <Stack alignment='center' distribution='trailing'>
              <div style={{ minWidth: "150px" }}>
                <Select
                  id='companyId'
                  value={
                    menuOptions?.find(({ value }) => value === selected) || ""
                  }
                  onChange={({ value }) => {
                    this.setState({ selected: value });
                  }}
                  options={menuOptions}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
              </div>
              <Button
                primary
                disabled={!selected}
                onClick={() => this.props.changeCompanyName(selected)}
                loading={loadingButtonClick}
              >
                Activate
              </Button>
            </Stack>
          </Stack>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(TopMenu))
);
