import moment from "moment";

export function formatDate(d) {
  if (!d) return ""; // Защита от null/undefined
  return moment(d, ["DD.MM.YYYY", moment.ISO_8601]).format("DD.MM.YYYY");
}

export function formatMonth(d) {
  return moment(d).format("MMM - YYYY");
}

export function formatTime(d) {
  return moment(d).format("HH:mm");
}

export function formatDateForServer(d) {
  return moment(d).format("YYYY-MM-DD");
}
