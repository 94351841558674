import React from "react";
import { Stack } from "@shopify/polaris";
import classNames from "classnames";
import "./index.scss";

const SaveBar = (props) => (
  <div className={classNames("SaveBar", { fullWidth: props.fullWidth })}>
    <div className='SaveBarWrapper'>
      <Stack alignment='center' distribution='equalSpacing'>
        {props.children}
      </Stack>
    </div>
  </div>
);

export default SaveBar;
