import React from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import formatRow from "utils/formatRow";
import { Card, DataTable, Text } from "@shopify/polaris";

class SalesListPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { preview, currency, t } = this.props;

    const columns = [
      {
        property: "country",
        header: {
          label: t("createReturns.country"),
        },
      },
      {
        property: "vat_number",
        header: {
          label: t("createReturns.vatNumber"),
        },
      },
      {
        property: "service",
        header: {
          label: t("createReturns.salesType"),
        },
      },
      {
        property: "sum",
        header: {
          label: `${t("createReturns.total")} (${currency})`,
        },
      },
    ];

    const sortedRows = formatRow(preview, columns);

    return (
      <Card>
        <DataTable
          columnContentTypes={["text", "text", "text", "numeric"]}
          headings={columns.map(({ header }) => (
            <Text fontWeight='semibold'>{header.label}</Text>
          ))}
          rows={sortedRows}
          hideScrollIndicator
          increasedTableDensity
        />
      </Card>
    );
  }
}
export default withTranslation()(SalesListPreview);
