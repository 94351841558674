import classNames from "classnames";
import React, { useState } from "react";
import "./platform.scss";
import { platformArr } from "containers/Stores/platformList";

const Platforms = (props) => {
	const [extendedListPlatform, setExtendedListPlatform] = useState(false);
	
	const handleTogglePlatformList = () => setExtendedListPlatform(!extendedListPlatform);
	
	// Показываем только первые 4 элемента, если список не расширен
	const platformsToDisplay = extendedListPlatform ? platformArr : platformArr.slice(0, 4);
	
	return (
		<div className="platformItemsWrap" style={{ ...props.styles }}>
      {platformsToDisplay.map((plItem) => (
	      <div
		      key={plItem.name}
		      className={classNames("platformItem", {
			      active: props.platform === plItem.name,
		      })}
		      style={{ padding: "0 3px" }}
		      onClick={() => {
			      if (
				      [
					      "shopify_plus",
					      "back_market",
					      "zalando",
					      "big_commerce",
					      "allegro",
					      "walmart",
					      "kaufland",
					      "acumatica",
					      "ecwid",
					      "quick_books",
					      "square_space",
					      "recurly",
					      "netsuite_oracle",
				      ].includes(plItem.name)
			      ) {
				      props.toggleModalRenderModalForRequestIntegration(true, plItem.name);
			      }
			      props.handleChangePlatform(plItem.name);
		      }}
	      >
          <img src={plItem.img} alt={plItem.name} />
        </div>
      ))}
			<button
				onClick={handleTogglePlatformList}
				className='platformItemsWrap-button'
			>
        {extendedListPlatform ? "Hide" : "Show all"}
				{
					!extendedListPlatform
						?
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
				  <path
					  d="M12 16L6 10H18L12 16Z"
					  fill="rgba(140, 145, 150, 1)"
				  />
				</svg >
						:
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
					  <path
						  d="M12 8L6 14H18L12 8Z"
						  fill="rgba(140, 145, 150, 1)"
					  />
					</svg >
				}
      </button >
    </div >
	);
};

export default Platforms;