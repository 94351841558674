/**
 * Formats row data for use in the DataTable component.
 * This function maps each item in the data array to an array of values
 * based on the column definitions. It uses the formatters defined in the
 * columns array to format the data as needed.
 *
 * @param {Array} data - The array of data objects to be formatted.
 * @param {Array} columns - The array of column definitions.
 * @returns {Array} - An array of arrays, where each inner array represents a row of formatted data.
 */
export default function formatRow(data, columns) {
  return data?.map((item, index) =>
    columns?.map(
      (col) =>
        col.cell?.formatters[0](item[col.property], { rowData: item, index }) ||
        item[col.property]
    )
  );
}

export const formatRow2 = (data, columns) => {
  return data?.map((item) =>
    columns?.map((col) => {
      const value = item[col.property];
      if (typeof value === 'string') {
        return value;
      }

      if (Array.isArray(value)) {
        return value.join(', ');
      }

      if (value && (value.$$typeof || value instanceof HTMLElement)) {
        return value;
      }

      if (value && typeof value === 'object') {
        return value.name || value.units || (value.quantity ? String(value.quantity) : JSON.stringify(value));
      }

      return col.cell?.formatters[0](value, { rowData: item }) || value;
    })
  );
};


