import React from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "hooks/useAppDispatch";
import { selectCategory2 } from "redux/features/declarations/declarationsSlice";
import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Text,
  DataTable,
  Inline, Box,
} from "@shopify/polaris";
import SaveBar from "components/SaveBar/SaveBar";
import { t } from "i18next";
import formatRow from "utils/formatRow";

const SubcategoryStep2 = ({ toNextStep, toPrevStep }) => {
  const dispatch = useAppDispatch();
  const {
    eprDeclaration: { subcategories_data },
    eprSubcategories: { selectedCategory2, selectedCategory1 },
  } = useSelector((state) => state.declarations);

  const handleChange = (checked, category, subCategory) => {
    dispatch(
      selectCategory2({
        checked,
        category,
        subCategory,
      })
    );
  };

  const columns = [
    {
      header: {
        label: "Subcategory 1",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[0];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 2",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            const category = rowData[0];
            const checkboxes = subcategories_data.subcategory_2[category];
            return (
              <Box fullWidth style={{ display: 'flex', flexDirection: 'column', gap: '16px', whiteSpace: 'normal' }}>
                {checkboxes?.map((checkbox) => (
                  <Checkbox
                    key={checkbox + category}
                    id={checkbox + category}
                    value={checkbox}
                    label={checkbox}
                    checked={selectedCategory2.some(
                      (c) => c[0] === category && c[1] === checkbox
                    )}
                    onChange={(checked) =>
                      handleChange(checked, category, checkbox)
                    }
                  />
                ))}
              </Box>
            );
          },
        ],
      },
    },
  ];

  const rows = formatRow(
    selectedCategory1.map((c) => [c]),
    columns
  );

  return (
    <>
      <Card sectioned>
        <DataTable
          columnContentTypes={["text", "text"]}
          headings={columns.map(({ header }) => (
            <Text fontWeight='semibold'>{header.label}</Text>
          ))}
          rows={rows}
        />
      </Card>

      <SaveBar>
        <ButtonGroup>
          <Button onClick={toPrevStep}>{t("eprReporting.back")}</Button>
          <Button primary onClick={toNextStep}>
            {t("orderReg.next")}
          </Button>
        </ButtonGroup>
      </SaveBar>
    </>
  );
};

export default SubcategoryStep2;
