import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import Modal from "components/Modal/Modal";
import {
  Page,
  Layout,
  Text,
  ResourceItem,
  Avatar,
  ResourceList,
  Card,
  Tooltip,
  Filters,
  Button,
  ChoiceList,
  Spinner,
  Inline,
  ButtonGroup,
} from "@shopify/polaris";
import { useSelector } from "react-redux";
import { downloadMessageFile } from "redux/features/messenger/messengerSlice";
import {
  deleteMessage,
  fetchMessages,
  setMessageMarked,
} from "redux/features/messages/messagesSlice";

import DownloadIMG from "img/download.svg";
import ClipIMG from "img/clip.svg";
import DeleteIMG from "img/delete.svg";

import "./index.scss";
import { isTaxAgentSelector } from "redux/selectors";
import useAppDispatch from "hooks/useAppDispatch";

const MarkerIcon = ({ active }) => {
  return (
    <svg
      width='16'
      height='21'
      viewBox='0 0 16 21'
      fill='none'
      stroke={active ? "#276BD2" : "#999"}
      strokeWidth='2'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 20.5714L8 14.8571L0 20.5714V2.28571C0 1.6795 0.240816 1.09812 0.66947 0.66947C1.09812 0.240816 1.67951 0 2.28571 0H13.7143C14.3205 0 14.9019 0.240816 15.3305 0.66947C15.7592 1.09812 16 1.6795 16 2.28571V20.5714Z'
        fill={active ? "#276BD2" : "none"}
      />
    </svg>
  );
};

const Messages = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { messages, fetchingMessages, deletingMessage } = useSelector(
    (state) => state.messages
  );
  const IS_TAX_AGENT = isTaxAgentSelector(user);
  const [queryValue, setQueryValue] = useState(undefined);
  const [selectedFilter, setSelectedFilter] = useState(["all"]);
  const [actionDialogs, setActionDialogs] = useState({
    delete: { open: false },
  });

  useEffect(() => {
    dispatch(fetchMessages());
  }, []);

  const handleQueryValueRemove = useCallback(
    () => setQueryValue(undefined),
    []
  );

  const handleChangeSelectedFilter = useCallback(
    (value) => setSelectedFilter(value),
    []
  );

  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  const filterMessages = (msgs) => {
    let filtered = msgs;

    if (queryValue) {
      filtered = filtered.filter(
        ({ subject, sender, text }) =>
          subject.toLowerCase().indexOf(queryValue.toLowerCase()) > -1 ||
          sender.toLowerCase().indexOf(queryValue.toLowerCase()) > -1 ||
          text.toLowerCase().indexOf(queryValue.toLowerCase()) > -1
      );
    }

    if (selectedFilter[0] === "new") {
      filtered = filtered.filter(({ read }) => !read);
    }

    if (selectedFilter[0] === "marked") {
      filtered = filtered.filter(({ marked }) => marked);
    }

    return filtered;
  };

  const handleActionDialogsOpen = (name, data = {}) => {
    setActionDialogs((state) => {
      return {
        ...state,
        cellData: data,
        [name]: {
          ...state[name],
          open: true,
        },
      };
    });
  };

  const handleActionDialogsClose = (name) => {
    setActionDialogs((state) => {
      return {
        ...state,
        cellData: {},
        [name]: {
          ...state[name],
          open: false,
        },
      };
    });
  };

  const downloadFile = (data) => {
    dispatch(downloadMessageFile(data)).then((toDownload) => {
      const from = data.indexOf("/") + 1;
      const to = data.lastIndexOf(".");
      const fileName = data.substring(from, to);
      const a = document.createElement("a");
      a.setAttribute("href", toDownload.link);
      a.setAttribute("download", fileName); // data - filename
      a.style.display = "none";

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(toDownload.link);
      document.body.removeChild(a);
    });
  };

  const doDeleteMessage = (id) => {
    dispatch(deleteMessage(id))
      .then(() => {
        dispatch(fetchMessages());
      })
      .then(() => {
        handleActionDialogsClose("delete");
      });
  };

  const filters = [
    {
      key: "filter",
      label: t("messages.filter"),
      filter: (
        <ChoiceList
          choices={[
            { label: t("messages.all"), value: "all" },
            { label: t("messages.new"), value: "new" },
            { label: t("messages.marked"), value: "marked" },
          ]}
          selected={selectedFilter}
          onChange={handleChangeSelectedFilter}
        />
      ),
      shortcut: true,
    },
  ];

  const filterControl = (
    <Filters
      queryValue={queryValue}
      queryPlaceholder={t("messages.findMessages")}
      filters={filters}
      onQueryChange={setQueryValue}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearAll}
    />
  );

  const emptyState = (
    <>
      {messages.length === 0 ? (
        <NoDataMessage
          title={t("messages.noMessages")}
          description={t("messages.youWillBeInformed")}
          style={{ height: "auto", justifyContent: "start", padding: "1rem" }}
        />
      ) : (
        <NoDataMessage
          title={t("messages.nothingFound")}
          description={t("messages.tryToChange")}
          style={{ height: "auto", justifyContent: "start", padding: "1rem" }}
        />
      )}
    </>
  );

  const renderDeleteMessageDialog = () => {
    const onClose = () => handleActionDialogsClose("delete");

    return (
      <Modal
        title={t("messages.deleteMessage")}
        visible={actionDialogs.delete.open}
        iconType={"danger"}
        description={
          <p style={{ maxWidth: "450px" }}>
            {t("messages.doYouWantToDel")}{" "}
            {actionDialogs.cellData && `"${actionDialogs.cellData.subject}"`}?
          </p>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose} size='large'>
              {t("messages.cancel")}
            </Button>
            <Button
              primary
              onClick={() => doDeleteMessage(actionDialogs.cellData.id)}
              destructive
              size='large'
              loading={deletingMessage}
            >
              {t("messages.delete")}
            </Button>
          </ButtonGroup>
        }
        onClose={onClose}
      />
    );
  };

  return (
    <Page
      fullWidth
      title={
        <Text variant='heading3xl' as='span'>
          {t("messages.messages")}
        </Text>
      }
      subtitle={
        <Text variant='bodyLg' as='span' color='subdued'>
          {t("messages.description")}
        </Text>
      }
      primaryAction={
        user.is_manager && (
          <Button primary onClick={() => navigate("/messages/add")}>
            {t("messages.addMessage")}
          </Button>
        )
      }
    >
      <Layout>
        <Layout.Section>
          <Card>
            {fetchingMessages ? (
              <div style={{ padding: "1rem" }}>
                <Spinner />
              </div>
            ) : (
              <ResourceList
                resourceName={{ singular: "customer", plural: "customers" }}
                filterControl={filterControl}
                emptyState={emptyState}
                items={filterMessages(messages)}
                renderItem={(item) => {
                  const {
                    id,
                    url,
                    sender,
                    subject,
                    text,
                    files,
                    read,
                    marked,
                    sent_at,
                    company_name,
                  } = item;
                  const media = (
                    <Avatar customer size='medium' name={subject} />
                  );
                  const managerActions = [
                    {
                      content: (
                        <Tooltip content='Delete'>
                          <img src={DeleteIMG} alt='delete' />
                        </Tooltip>
                      ),
                      onAction: (e) => {
                        e.preventDefault();
                        handleActionDialogsOpen("delete", {
                          id,
                          sender,
                          subject,
                        });
                      },
                    },
                  ];
                  const userActions = [
                    files?.length > 0 && {
                      content: (
                        <Tooltip content={t("messages.downloadAttached")}>
                          <img src={DownloadIMG} alt='download' />
                        </Tooltip>
                      ),
                      onAction: () => {
                        files.forEach((file) => downloadFile(file));
                      },
                    },
                    {
                      content: (
                        <Tooltip content={t("messages.addToMarkers")}>
                          <div>
                            <MarkerIcon active={marked} />
                          </div>
                        </Tooltip>
                      ),
                      onAction: () => {
                        dispatch(setMessageMarked(id));
                      },
                    },
                  ];

                  return (
                    <ResourceItem
                      id={id}
                      url={url}
                      media={media}
                      accessibilityLabel={`${t(
                        "messages.viewDetails"
                      )} ${subject}`}
                      shortcutActions={
                        user.is_manager ? managerActions : userActions
                      }
                      persistActions
                      onClick={() => navigate(`/messages/${id}`)}
                    >
                      <Inline alignY='center' spacing='2'>
                        {!read && (
                          <div className='messages__message__unread-point' />
                        )}
                        <Text
                          variant='headingMd'
                          fontWeight={read ? "regular" : "semibold"}
                        >
                          {sender} - {subject}
                        </Text>
                      </Inline>

                      <Text variant='subdued'>
                        <span className='messages__message__text'>{text}</span>
                      </Text>
                      <Inline alignY='center'>
                        {files &&
                          files.map((f, index) => (
                            <div
                              key={`${index}-file`}
                              className='messages__message__file'
                            >
                              <img
                                className='Button__Prefix'
                                src={ClipIMG}
                                alt='icon save'
                              />
                              <a
                                className='Polaris-Link'
                                onClick={() => downloadFile(f)}
                              >
                                {f.slice(14)}
                              </a>
                            </div>
                          ))}
                      </Inline>
                      <div style={{ paddingTop: "0.5rem" }}>
                        <Text variant='bodySm' color='subdued'>
                          {moment(sent_at).format("DD/MM/YYYY HH:mm")}
                        </Text>
                      </div>
                      {IS_TAX_AGENT && (
                        <>
                          <br />

                          <Text color='subdued'>
                            This is a message for the client: {company_name}
                          </Text>
                        </>
                      )}
                    </ResourceItem>
                  );
                }}
              />
            )}
          </Card>
        </Layout.Section>
      </Layout>
      <br />
      {renderDeleteMessageDialog()}
    </Page>
  );
};

export default Messages;
