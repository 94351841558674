export function setCookie(name, value, options) {
  const opts = options || {};

  let expires = opts.expires;

  if (typeof expires === 'number' && expires) {
    const date = new Date();
    date.setTime(date.getTime() + expires * 1000);
    expires = opts.expires = date;
  }
  if (expires && expires.toUTCString) {
    opts.expires = expires.toUTCString();
  }

  const val = encodeURIComponent(value);

  let updatedCookie = name + '=' + val;

  for (const propName in opts) {
    if (opts.hasOwnProperty(propName)) {
      updatedCookie += '; ' + propName;
      const propValue = opts[propName];
      if (propValue !== true) {
        updatedCookie += '=' + propValue;
      }
    }
  }

  document.cookie = updatedCookie;
}

export function deleteCookie(name) {
  setCookie(name, '', { expires: -1, path: '/' });
}

export function getCookie(name) {
  var matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}
