import React from 'react'

const PreviewEprInfo = ({data, arr}) => {
	
	const formatKey = (key) => {
		return key
			.replace(/_kg$/, '')
			.replace(/_/g, ' ')
			.replace(/\b\w/g, char => char.toUpperCase());
	};
	
	return (
		<>
			<div className='epr-modal_info'>
	        <h3 >{`Country: ${data?.customer_country?.name}`}</h3 >
	        <h4 >{`Currency: ${data?.currency_id}`}</h4 >
	        <h5 >{`Period: ${data?.period} ${data?.period_type} ${data?.period_year}`}</h5 >
	        <h5 >{`Total amount: ${data?.total_sum}`}</h5 >
        </div >
        <div className='modal__content epr-modal_wrapper'>
	       <table
		       className='Polaris-DataTable__Table table-bordered'
		       style={{width: '100%', borderCollapse: 'collapse'}}
	       >
				  <thead >
				    <tr >
				      <th
					      className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'
					      style={{
						      border: '1px solid #ddd',
						      padding: '8px',
						      backgroundColor: '#f2f2f2',
						      fontWeight: 'bold',
						      textAlign: 'left',
						      width: '150px',
					      }}
				      >
				        Name
				      </th >
				      <th
					      className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'
					      style={{
						      border: '1px solid #ddd',
						      padding: '8px',
						      backgroundColor: '#f2f2f2',
						      fontWeight: 'bold',
						      textAlign: `${arr.length > 0 ? 'left' : 'right'}`,
					      }}
				      >
					      Value
				      </th >
				    </tr >
				  </thead >
					  <tbody >
					    {arr.length > 0 ? arr?.map((item, index) => (
							    <React.Fragment key={index}>
					          <tr >
					            <th
						            className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'
						            style={{
							            border: '1px solid #ddd',
							            padding: '8px',
							            backgroundColor: '#f2f2f2',
							            fontWeight: 'bold',
							            textAlign: 'left',
							            width: '150px',
						            }}
					            >
					              Category
					            </th >
					            <td
						            className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
						            style={{border: '1px solid #ddd', padding: '8px', textAlign: 'left'}}
					            >
					              {item?.subcategory_1}
					            </td >
					          </tr >
					          <tr >
					            <th
						            className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'
						            style={{
							            border: '1px solid #ddd',
							            padding: '8px',
							            backgroundColor: '#f2f2f2',
							            fontWeight: 'bold',
							            textAlign: 'left',
							            width: '150px',
						            }}
					            >
					              Subcategory 1
					            </th >
					            <td
						            className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
						            style={{border: '1px solid #ddd', padding: '8px', textAlign: 'left'}}
					            >
					              {item?.subcategory_2}
					            </td >
					          </tr >
								    {item?.subcategory_3 && (
									    <tr >
					              <th
						              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'
						              style={{
							              border: '1px solid #ddd',
							              padding: '8px',
							              backgroundColor: '#f2f2f2',
							              fontWeight: 'bold',
							              textAlign: 'left',
							              width: '150px',
						              }}
					              >
					                Subcategory 2
					              </th >
					              <td
						              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
						              style={{border: '1px solid #ddd', padding: '8px', textAlign: 'left'}}
					              >
					                {item?.subcategory_3?.name
						                ?
						                ` ${item?.subcategory_3.name} (${item?.subcategory_3.units})`
						                :
						                ` ${item?.subcategory_3}`
					                }
					              </td >
					            </tr >
								    )}
								    {item?.subcategory_4 && (
									    <tr >
					              <th
						              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'
						              style={{
							              border: '1px solid #ddd',
							              padding: '8px',
							              backgroundColor: '#f2f2f2',
							              fontWeight: 'bold',
							              textAlign: 'left',
							              width: '150px',
						              }}
					              >
					                Subcategory 3
					              </th >
					              <td
						              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
						              style={{border: '1px solid #ddd', padding: '8px', textAlign: 'left'}}
					              >
					                {item?.subcategory_4?.name
						                ?
						                ` ${item?.subcategory_4.name} (${item?.subcategory_4.units})`
						                :
						                ` ${item?.subcategory_4}`
					                }
					              </td >
					            </tr >
								    )}
								    {item?.recyclateOfPrimary && (
									    <tr >
					              <th
						              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'
						              style={{
							              border: '1px solid #ddd',
							              padding: '8px',
							              backgroundColor: '#f2f2f2',
							              fontWeight: 'bold',
							              textAlign: 'left',
							              width: '150px',
						              }}
					              >
					                Recyclate
					              </th >
					              <td
						              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
						              style={{border: '1px solid #ddd', padding: '8px', textAlign: 'left'}}
					              >
					                {item?.recyclateOfPrimary}
					              </td >
					            </tr >
								    )}
								    <tr style={{borderBottom: '3px solid #ddd'}}>
					            <th
						            className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'
						            style={{
							            border: '1px solid #ddd',
							            padding: '8px',
							            backgroundColor: '#f2f2f2',
							            fontWeight: 'bold',
							            textAlign: 'left',
							            width: '150px',
						            }}
					            >
					              Quantity
					            </th >
					            <td
						            className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
						            style={{border: '1px solid #ddd', padding: '8px', textAlign: 'left'}}
					            >
					              {item?.quantity}
					            </td >
					          </tr >
					        </React.Fragment >
						    ))
						    :
						    <>
					        {Object?.entries(data?.data).map(([key, value]) => (
						        <React.Fragment key={key}>
							       <tr key={key}>
								       <td className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'
						            style={{
							            border: '1px solid #ddd',
							            padding: '8px',
							            backgroundColor: '#f2f2f2',
							            fontWeight: 'bold',
							            textAlign: 'left',
							            width: '150px',
						            }}>{formatKey(key)}</td >
								       <td className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'
						            style={{
							            border: '1px solid #ddd',
							            padding: '8px',
							            fontWeight: 'bold',
							            textAlign: 'right',
							            width: '150px',
						            }}>{value} {key.includes('_kg') ? 'kg' : ''}</td ></tr >
						        </React.Fragment >
					        ))}
						    </>
					    }
					  </tbody >
					</table >

        </div >
		</>
	)
}

export default PreviewEprInfo