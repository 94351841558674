import React, { Component } from "react";
import InjectedCheckoutForm from "./CheckoutForm";
import { Elements, StripeProvider } from "react-stripe-elements";
import { withTranslation } from "react-i18next";
import withRouter from "helpers/withRouter";
import { connect } from "react-redux";

class StripePayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stripe: null,
    };
  }

  componentDidMount() {
    const stripeKey = this.props.user.stripe_publishable_key;

    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(stripeKey),
      });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({
          stripe: window.Stripe(stripeKey),
        });
      });
    }
  }

  render() {
    const { stripe } = this.state;

    return (
      <StripeProvider stripe={stripe}>
        <Elements>
          <InjectedCheckoutForm {...this.props} />
        </Elements>
      </StripeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(StripePayment))
);
