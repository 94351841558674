import { Button } from "@shopify/polaris";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigation } from "react-router-dom";
import Modal from "components/Modal";
import withRouter from "helpers/withRouter";

const RenderCreatedReturnDialog = ({
  state,
  declarationType,
  handleActionDialogsClose,
  navigate,
}) => {
  const { actionDialogs, addingResult } = state;
  const { t } = useTranslation();

  const onClose = () => {
    handleActionDialogsClose("created");
    navigate("/vat-returns");
  };

  return (
    actionDialogs.cellData &&
    addingResult && (
      <Modal
        title={addingResult.message ? "Warning" : "Success"}
        iconType={addingResult.message ? "warning" : "success"}
        description={
          !addingResult.message
            ? `${declarationType} was ${
                actionDialogs.cellData.finalType === "submit"
                  ? "created"
                  : "saved"
              }`
            : ""
        }
        visible={actionDialogs.created.open}
        onClose={onClose}
        content={
          addingResult.message && (
            <p>
              {addingResult.message} (
              {
                <Link className='Polaris-Link' to='/subscription'>
                  {t("createReturns.changeSubPlan")}
                </Link>
              }
              )
            </p>
          )
        }
        footer={
          <Button primary size='large' onClick={onClose}>
            &nbsp;&nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp;&nbsp;
          </Button>
        }
      />
    )
  );
};

export default withRouter(RenderCreatedReturnDialog);
