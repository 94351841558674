import acumatica from 'img/acumatica.png'
import allegro from 'img/allegro.png'
import amazonIMG from 'img/amazon_fulfillment_logo.png'
import appStore from 'img/app-store.png'
import backMarket from 'img/back-market.png'
import bigCommerce from 'img/big-commerce.png'
import cdiscount from 'img/c-discount.png'
import chargebee from 'img/chargebee.png'
import ebayIMG from 'img/ebay.png'
import ecwid from 'img/ecwid.png'
import etsyIMG from 'img/etsy.png'
import googlePlay from 'img/google-play.png'
import kaufland from 'img/kaufland.png'
import magento2IMG from 'img/magento2.png'
import magentoIMG from 'img/magento_logo.svg'
import opencartLogoIMG from 'img/opencart.png'
import netsuiteOracle from 'img/oracleNetsuite.png'
import paypalIMG from 'img/paypal_logo.png'
import quickBooks from 'img/quick-books.png'
import recurly from 'img/recurly.png'
import shopifyPlus from 'img/shopify-plus.png'
import shopifyIMG from 'img/shopify.png'
import squareSpace from 'img/squarespace.png'
import stripeIMG from 'img/stripe_logo_blue.png'
import walmart from 'img/walmart.png'
import woocommerceIMG from 'img/woocommerce.png'
import xeroLogoIMG from 'img/xero.png'
import zalando from 'img/zalando.png'

export const platformArr = [
	{
		id: "2pl",
		name: "amazon",
		img: amazonIMG,
	},
	{
		id: "1pl",
		name: "shopify",
		img: shopifyIMG,
	},
	{
		id: "7pl",
		name: "stripe",
		img: stripeIMG,
	},
	{
		id: "9pl",
		name: "paypal",
		img: paypalIMG,
	},
	{
		id: "11pl",
		name: "xero",
		img: xeroLogoIMG,
	},
	{
		id: "8pl",
		name: "magento1",
		img: magentoIMG,
	},
	{
		id: "5pl",
		name: "magento",
		img: magento2IMG,
	},
	{
		id: "10pl",
		name: "opencart",
		img: opencartLogoIMG,
	},
	{
		id: "6pl",
		name: "woocommerce",
		img: woocommerceIMG,
	},
	{
		id: "3pl",
		name: "ebay",
		img: ebayIMG,
	},
	{
		id: "4pl",
		name: "etsy",
		img: etsyIMG,
	},
	{
		id: "25pl",
		name: "google_play",
		img: googlePlay,
	},
	{
		id: "12pl",
		name: "app_store",
		img: appStore,
	},
	{
		id: "13pl",
		name: "shopify_plus",
		img: shopifyPlus,
	},
	{
		id: "14pl",
		name: "back_market",
		img: backMarket,
	},
	{
		id: "15pl",
		name: "zalando",
		img: zalando,
	},
	{
		id: "16pl",
		name: "big_commerce",
		img: bigCommerce,
	},
	{
		id: "17pl",
		name: "allegro",
		img: allegro,
	},
	{
		id: "18pl",
		name: "walmart",
		img: walmart,
	},
	{
		id: "19pl",
		name: "kaufland",
		img: kaufland,
	},
	{
		id: "20pl",
		name: "acumatica",
		img: acumatica,
	},
	{
		id: "21pl",
		name: "ecwid",
		img: ecwid,
	},
	{
		id: "22pl",
		name: "quick_books",
		img: quickBooks,
	},
	{
		id: "23pl",
		name: "square_space",
		img: squareSpace,
	},
	{
		id: "24pl",
		name: "recurly",
		img: recurly,
	},
	{
		id: "26pl",
		name: "netsuite_oracle",
		img: netsuiteOracle,
	},
	{
		id: "27pl",
		name: "Chargebee",
		img: chargebee,
	},
	{
		id: "28pl",
		name: "Cdiscount",
		img: cdiscount,
	},
];