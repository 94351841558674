/**
 * Hook for managing a set of action dialogs with their open state and cell data.
 *
 * @param {Array<string|Array<string, boolean>>} dialogNames - Array of dialog names. Each item can be a string representing the dialog name, or an array where the first element is the name and the second element (optional) is the default state for 'open'.
 * @returns {{
 *   actionDialogs: Object.<string, { open: boolean, cellData: Object }>,
 *   handleActionDialogsToggle: (name: string, data?: Object) => void,
 *   handleActionDialogsOpen: (name: string, data?: Object) => void,
 *   handleActionDialogsClose: (name: string) => void,
 *   editActionDialogCellData: (name: string, newData: Object) => void
 * }}
 */

import { useState } from "react";

const useActionDialogs = (dialogNames) => {
  const initialState = Object.fromEntries(
    dialogNames.map((dialog) => {
      const [name, defaultOpen = false] = Array.isArray(dialog)
        ? dialog
        : [dialog];
      return [name, { open: defaultOpen, cellData: {} }];
    })
  );

  const [actionDialogs, setActionDialogs] = useState(initialState);

  const handleActionDialogsToggle = (name, data = {}) => {
    setActionDialogs((prevState) => ({
      ...prevState,
      [name]: { open: !prevState[name].open, cellData: data },
    }));
  };

  const handleActionDialogsOpen = (name, data = {}) => {
    setActionDialogs((prevState) => ({
      ...prevState,
      [name]: { open: true, cellData: data },
    }));
  };

  const handleActionDialogsClose = (name) => {
    setActionDialogs((prevState) => ({
      ...prevState,
      [name]: { open: false, cellData: {} },
    }));
  };

  const editActionDialogCellData = (name, newData) => {
    setActionDialogs((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], cellData: newData },
    }));
  };

  return {
    actionDialogs,
    handleActionDialogsToggle,
    handleActionDialogsOpen,
    handleActionDialogsClose,
    editActionDialogCellData,
  };
};

export default useActionDialogs;
