import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import { parseServerError } from "utils/errors";

export const openNewDialog = createAsyncThunk(
  "messenger/openNewDialog",
  async (
    { title, theme, companyId = -1, status = -1, data },
    { rejectWithValue }
  ) => {
    try {
      const response = await callApi(
        `/chat/dialogs?case_group=${theme}&short_description=${title}&company_id=${companyId}&status=${status}`,
        "POST",
        data,
        { contentType: "multipart/form-data" }
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const json = await response.json();
      return json;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchDialog = createAsyncThunk(
  "messenger/fetchDialog",
  async (
    params = { unread: false, dialog_id_in_url: true },
    { rejectWithValue }
  ) => {
    try {
      const response = await callApi("/chat/dialogs", "GET", params);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const json = await response.json();
      return json;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const setUnreadToRead = createAsyncThunk(
  "messenger/setUnreadToRead",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/chat/message/unread_to_read?dialog_id=${id}`,
        "POST"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const json = await response.json();
      return json;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const downloadMessageFile = createAsyncThunk(
  "messenger/downloadMessageFile",
  async (name, { rejectWithValue }) => {
    try {
      const response = await callApi(`/chat/download?file=${name}`, "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      const blob = await response.blob();
      const res = {
        link: window.URL.createObjectURL(blob),
      };
      return res;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const sendMessengerMessage = createAsyncThunk(
  "messenger/sendMessengerMessage",
  async ({ dialogId, text, data }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/chat/message?dialog_id=${dialogId}`,
        "POST",
        data,
        { contentType: "multipart/form-data" }
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const json = await response.json();
      return json;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchHistoryDialogs = createAsyncThunk(
  "messenger/fetchHistoryDialogs",
  async (
    params = { unread: false, dialog_id_in_url: false },
    { rejectWithValue }
  ) => {
    try {
      const response = await callApi("/chat/dialogs", "GET", params);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const json = await response.json();
      return json;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const resetSystemMessage = createAsyncThunk(
  "messenger/resetSystemMessage",
  async ({ modelsName, id }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/chat/set_status_read?models_name=${modelsName}&message_id=${id}`,
        "POST"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const json = await response.json();
      return json;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchTaxAuditMassages = createAsyncThunk(
  "messenger/fetchTaxAuditMassages",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/chat/status?models_name=tax_audit",
        "GET"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const json = await response.json();
      return json;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchNewMessagesCount = createAsyncThunk(
  "messenger/fetchNewMessagesCount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/chat/get_count_messages", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      const json = await response.json();
      return json;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchMerchSettingsMassages = createAsyncThunk(
  "messenger/fetchMerchSettingsMassages",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/chat/status?models_name=merchant_settings",
        "GET"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const json = await response.json();
      return json;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
const initialState = {
  messageId: 0,
  dialog: {},
  historyDialogs: [],
  historyNewMessagesCount: [],
  messagesCount: 0,
  taxAuditMessages: [],
  merchSettingsMessages: [],
  openingDialog: false,
  openingDialogError: null,
  sending: false,
  sendingError: null,
  downloadingFile: false,
  downloadingFileError: null,
  fetchingHistory: false,
  fetchingHistoryError: null,
  fetchingDialog: false,
  fetchingDialogError: null,
  fetchingNewMessages: false,
  fetchingNewMessagesError: null,
  settingRead: false,
  settingReadError: null,
  settingReadSystem: false,
  settingReadSystemError: null,
  fetchingTaxAuditMessages: false,
  fetchingTaxAuditMessagesError: null,
  fetchingMerchSettingsMessages: false,
  fetchingMerchSettingsMessagesError: null,
};

const messengerSlice = createSlice({
  name: "messenger",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(openNewDialog.pending, (state) => {
        state.openingDialog = true;
      })
      .addCase(openNewDialog.fulfilled, (state) => {
        state.openingDialog = false;
        state.openingDialogError = null;
      })
      .addCase(openNewDialog.rejected, (state, action) => {
        state.openingDialog = false;
        state.openingDialogError = parseServerError(action.error);
      })
      .addCase(sendMessengerMessage.pending, (state) => {
        state.sending = true;
      })
      .addCase(sendMessengerMessage.fulfilled, (state) => {
        state.sending = false;
        state.sendingError = null;
      })
      .addCase(sendMessengerMessage.rejected, (state, action) => {
        state.sending = false;
        state.sendingError = parseServerError(action.error);
      })
      .addCase(downloadMessageFile.pending, (state) => {
        state.downloadingFile = true;
      })
      .addCase(downloadMessageFile.fulfilled, (state, action) => {
        state.downloadingFile = false;
        state.downloadingFileError = null;
        state.toDownloadFile = action.payload;
      })
      .addCase(downloadMessageFile.rejected, (state, action) => {
        state.downloadingFile = false;
        state.downloadingFileError = parseServerError(action.error);
      })
      .addCase(fetchHistoryDialogs.pending, (state) => {
        state.fetchingHistory = true;
      })
      .addCase(fetchHistoryDialogs.fulfilled, (state, action) => {
        state.fetchingHistory = false;
        state.fetchingHistoryError = null;
        state.historyDialogs = action.payload;
      })
      .addCase(fetchHistoryDialogs.rejected, (state, action) => {
        state.fetchingHistory = false;
        state.fetchingHistoryError = parseServerError(action.error);
      })
      .addCase(fetchDialog.pending, (state) => {
        state.fetchingDialog = true;
      })
      .addCase(fetchDialog.fulfilled, (state, action) => {
        state.fetchingDialog = false;
        state.fetchingDialogError = null;
        state.dialog = action.payload;
      })
      .addCase(fetchDialog.rejected, (state, action) => {
        state.fetchingDialog = false;
        state.fetchingDialogError = parseServerError(action.error);
      })
      .addCase(fetchNewMessagesCount.pending, (state) => {
        state.fetchingNewMessages = true;
      })
      .addCase(fetchNewMessagesCount.fulfilled, (state, action) => {
        state.fetchingNewMessages = false;
        state.fetchingNewMessagesError = null;
        state.historyNewMessagesCount = action.payload;
      })
      .addCase(fetchNewMessagesCount.rejected, (state, action) => {
        state.fetchingNewMessages = false;
        state.fetchingNewMessagesError = parseServerError(action.error);
      })
      .addCase(setUnreadToRead.pending, (state) => {
        state.settingRead = true;
      })
      .addCase(setUnreadToRead.fulfilled, (state) => {
        state.settingRead = false;
        state.settingReadError = null;
      })
      .addCase(setUnreadToRead.rejected, (state, action) => {
        state.settingRead = false;
        state.settingReadError = parseServerError(action.error);
      })
      .addCase(resetSystemMessage.pending, (state) => {
        state.settingReadSystem = true;
      })
      .addCase(resetSystemMessage.fulfilled, (state) => {
        state.settingReadSystem = false;
        state.settingReadSystemError = null;
      })
      .addCase(resetSystemMessage.rejected, (state, action) => {
        state.settingReadSystem = false;
        state.settingReadSystemError = parseServerError(action.error);
      })
      .addCase(fetchTaxAuditMassages.pending, (state) => {
        state.fetchingTaxAuditMessages = true;
      })
      .addCase(fetchTaxAuditMassages.fulfilled, (state, action) => {
        state.fetchingTaxAuditMessages = false;
        state.fetchingTaxAuditMessagesError = null;
        state.taxAuditMessages = action.payload;
      })
      .addCase(fetchTaxAuditMassages.rejected, (state, action) => {
        state.fetchingTaxAuditMessages = false;
        state.fetchingTaxAuditMessagesError = parseServerError(action.error);
      })
      .addCase(fetchMerchSettingsMassages.pending, (state) => {
        state.fetchingMerchSettingsMessages = true;
      })
      .addCase(fetchMerchSettingsMassages.fulfilled, (state, action) => {
        state.fetchingMerchSettingsMessages = false;
        state.fetchingMerchSettingsMessagesError = null;
        state.merchSettingsMessages = action.payload;
      })
      .addCase(fetchMerchSettingsMassages.rejected, (state, action) => {
        state.fetchingMerchSettingsMessages = false;
        state.fetchingMerchSettingsMessagesError = parseServerError(
          action.error
        );
      });
  },
});

export default messengerSlice.reducer;
