function queryParams(source) {
  const array = [];

  for (const key in source) {
    if (source[key] !== null && source[key] !== undefined) {
      array.push(
        encodeURIComponent(key) + "=" + encodeURIComponent(source[key])
      );
    }
  }

  return array.join("&");
}
export default async function callApi(
  endpoint,
  method = "GET",
  payload = {},
  params
) {
  const token = localStorage.getItem("api_key");
  const url = process.env.REACT_APP_API_URL || "";
  const apiHeaders =
    token && endpoint !== "/user/login" ? { "api-key": token } : {};
  const { extraHeaders = {}, contentType = "" } = params ?? {};

  let fullUrl = `${url}/api/1${endpoint}`;
  if (method === "GET" && Object.keys(payload).length) {
    fullUrl += `?${queryParams(payload)}`;
  }

  const body =
    contentType === "multipart/form-data" ? payload : JSON.stringify(payload);

  const options = {
    method,
    headers: {
      Accept: "application/json",
      ...(contentType ? {} : { "Content-Type": "application/json" }),
      ...apiHeaders,
      ...extraHeaders,
    },
    body: method !== "GET" ? body : null,
  };

  return fetch(fullUrl, options)
    .then((response) => response)
    .catch((err) => {
      console.error("API call error:", err);
    });
}
