import { Badge } from '@shopify/polaris'

export const getInvoiceTypeColumns = (invoiceType, commonColumns, query, documents, getBadgeStatus, renderActions, getCurrencySymbol, formatMoney) => {
	switch (invoiceType) {
		case 'invoice':
			return [
				...commonColumns,
				{ property: "company", header: { label: "To" } },
				{ property: "inv_number", header: { label: "Invoice Number" } },
				{ property: "invoice_type", header: { label: "Invoice Type" } },
				{
					property: "tax_number",
					header: { label: `${query?.type === 'invoice-sales-tax' ? 'Customer Tax Number' : 'Customer VAT Number'}` },
					cell: {
						formatters: [(value, { rowData }) => rowData.tax_number || "-"],
					},
				},
				{
					property: "supplier_country",
					header: { label: "Customer Country" },
					cell: {
						formatters: [(value, { rowData }) => rowData.country?.name || "Unknown Country"],
					},
				},
				{
					property: "total",
					header: { label: "Total" },
					cell: {
						formatters: [(value, { rowData }) => `${getCurrencySymbol(rowData.currency_id)} ${formatMoney(value)}`],
					},
				},
				{
					property: "status",
					header: { label: "Status" },
					cell: {
						formatters: [(value) => <Badge status={getBadgeStatus(value)}>{value}</Badge>],
					},
				},
				{
					property: "actions",
					header: { label: "" },
					cell: {
						formatters: [(value, { rowData }) => renderActions(rowData)],
					},
				},
			];
		
		case 'bill':
			return [
				...commonColumns,
				{ property: "company", header: { label: "From" } },
				{ property: "inv_number", header: { label: "Invoice Number" } },
				{ property: "invoice_type", header: { label: "Invoice Type" } },
				{
					property: "tax_number",
					header: { label: `${query?.type === 'invoice-sales-tax' ? 'Supplier Tax Number' : 'Supplier VAT Number'}` },
					cell: {
						formatters: [(value, { rowData }) => rowData.tax_number || "-"],
					},
				},
				{
					property: "supplier_country",
					header: { label: "Supplier Country" },
					cell: {
						formatters: [(value, { rowData }) => rowData.country?.name || "Unknown Country"],
					},
				},
				{
					property: "total",
					header: { label: "Total" },
					cell: {
						formatters: [(value, { rowData }) => `${getCurrencySymbol(rowData.currency_id)} ${formatMoney(value)}`],
					},
				},
				{
					property: "status",
					header: { label: "Status" },
					cell: {
						formatters: [(value) => <Badge status={getBadgeStatus(value)}>{value}</Badge>],
					},
				},
				{
					property: "actions",
					header: { label: "" },
					cell: {
						formatters: [(value, { rowData }) => renderActions(rowData)],
					},
				},
			];
		
		default:
			return [
				...commonColumns,
				{
					property: "company",
					header: { label: "Company" },
					cell: {
						formatters: [(value, { rowData }) => rowData?.data?.sender_info.company],
					},
				},
				{ property: "inv_number", header: { label: "Invoice Number" } },
				{ property: "invoice_type", header: { label: "Invoice Type" } },
				{
					property: "tax_number",
					header: { label: `${query?.type === 'invoice-sales-tax' ? 'Tax Number' : 'VAT Number'}` },
					cell: {
						formatters: [(value, { rowData }) => rowData.tax_number || "-"],
					},
				},
				{
					property: "supplier_country",
					header: { label: "Country" },
					cell: {
						formatters: [(value, { rowData }) => rowData.country?.name || "Unknown Country"],
					},
				},
				{
					property: "total",
					header: { label: "Total" },
					cell: {
						formatters: [(value, { rowData }) => `${getCurrencySymbol(rowData.currency_id)} ${formatMoney(value)}`],
					},
				},
				{
					property: "status",
					header: { label: "Status" },
					cell: {
						formatters: [(value) => <Badge status={getBadgeStatus(value)}>{value}</Badge>],
					},
				},
				{
					property: "actions",
					header: { label: "" },
					cell: {
						formatters: [(value, { rowData }) => renderActions(rowData)],
					},
				},
			];
	}
};
