import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import AuthContainer from "../../AuthContainer";
import PageHelmet from "components/PageHelmet";
import _ from "lodash";
import { Banner, Text } from "@shopify/polaris";
import { fetchUser } from "redux/features/user/userSlice";
import { createCustomDispatch } from "helpers/customDispatch";
import { activateUser, logout } from "redux/features/auth/authSlice";

class Activation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activationError: false,
    };
  }

  componentDidMount() {
    if (this.props.params.code) {
      this.props
        .activateUser({ code: this.props.params.code })
        .then(() => {
          this.props.navigate("/dashboard");
        })
        .catch((error) => {
          this.setState({ activationError: true });
        });
    } else {
      this.setState({ activationError: true });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <PageHelmet title='Activation' />
        <AuthContainer
          title={t("activation.userActivation")}
          content={
            <>
              {!this.state.activationError ? (
                <Text>{t("activation.activationInProcess")}</Text>
              ) : (
                <Banner title={t("activation.linkInvalid")} status='critical' />
              )}
            </>
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  apiKey: state.user.api_key,
  activationSuccessful: state.user.activationSuccessful,
  activationError: state.user.activationError,
  refererLang: state.user.refererLang,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    logout: () => dispatch(logout()),
    activateUser: (params) => dispatch(activateUser(params)),
    fetchUser: () => dispatch(fetchUser()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Activation))
);
