import React from "react";

const HelpCenterIcon = ({ selected }) => {
  if (selected) {
    return (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.0007 0.833496C4.93804 0.833496 0.833984 4.93755 0.833984 10.0002C0.833984 15.0628 4.93804 19.1668 10.0007 19.1668C15.0633 19.1668 19.1673 15.0628 19.1673 10.0002C19.1673 4.93755 15.0633 0.833496 10.0007 0.833496ZM9.08951 6.89285C9.42884 6.69342 9.82781 6.62052 10.2157 6.68706C10.6037 6.7536 10.9555 6.95529 11.209 7.2564C11.4625 7.55752 11.6012 7.93862 11.6006 8.33222L11.6006 8.33346C11.6006 8.72441 11.2964 9.13198 10.7217 9.51509C10.4598 9.68974 10.1919 9.82428 9.98616 9.9157C9.88449 9.96089 9.80093 9.99425 9.74474 10.0157C9.7198 10.0252 9.69476 10.0343 9.66958 10.0432C9.23352 10.1891 8.99797 10.6607 9.14341 11.097C9.28895 11.5336 9.76088 11.7696 10.1975 11.624L10.3381 11.5731C10.4186 11.5425 10.5303 11.4977 10.6631 11.4387C10.9261 11.3218 11.2832 11.1439 11.6462 10.9018C12.3214 10.4517 13.2669 9.60952 13.2673 8.33422C13.2684 7.5472 12.9909 6.78517 12.4841 6.18307C11.9771 5.58084 11.2734 5.17747 10.4975 5.04438C9.72164 4.9113 8.92371 5.0571 8.24504 5.45596C7.56637 5.85482 7.05076 6.481 6.78953 7.22359C6.63681 7.65775 6.86495 8.13351 7.29911 8.28624C7.73327 8.43897 8.20903 8.21082 8.36176 7.77667C8.49237 7.40537 8.75018 7.09228 9.08951 6.89285ZM10.0006 13.3335C9.54041 13.3335 9.16731 13.7066 9.16731 14.1668C9.16731 14.627 9.54041 15.0001 10.0006 15.0001H10.009C10.4692 15.0001 10.8423 14.627 10.8423 14.1668C10.8423 13.7066 10.4692 13.3335 10.009 13.3335H10.0006Z'
          fill='#267FD7'
        />
      </svg>
    );
  }
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6786_87435)'>
        <path
          opacity='0.12'
          d='M9.99984 18.3337C14.6022 18.3337 18.3332 14.6027 18.3332 10.0003C18.3332 5.39795 14.6022 1.66699 9.99984 1.66699C5.39746 1.66699 1.6665 5.39795 1.6665 10.0003C1.6665 14.6027 5.39746 18.3337 9.99984 18.3337Z'
          fill='#858585'
        />
        <path
          d='M7.57484 7.50033C7.77076 6.94338 8.15746 6.47375 8.66647 6.1746C9.17547 5.87546 9.77392 5.76611 10.3558 5.86592C10.9377 5.96573 11.4655 6.26826 11.8457 6.71993C12.226 7.1716 12.4341 7.74326 12.4332 8.33366C12.4332 10.0003 9.93317 10.8337 9.93317 10.8337M9.99984 14.167H10.0082M18.3332 10.0003C18.3332 14.6027 14.6022 18.3337 9.99984 18.3337C5.39746 18.3337 1.6665 14.6027 1.6665 10.0003C1.6665 5.39795 5.39746 1.66699 9.99984 1.66699C14.6022 1.66699 18.3332 5.39795 18.3332 10.0003Z'
          stroke='#858585'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_6786_87435'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HelpCenterIcon;
