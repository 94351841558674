import { useState } from "react";

const useSelectedRows = (documents) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedRows(documents.map((doc) => doc.id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  return { selectedRows, handleSelectAll, handleRowSelect, setSelectedRows };
};

export default useSelectedRows;
