import { Button, ButtonGroup } from '@shopify/polaris'
import Modal from 'components/Modal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useAppDispatch from 'hooks/useAppDispatch'
import { useSelector } from 'react-redux'
import { deleteVatInvoiceBulk, fetchDocuments } from 'redux/features/documents/documentsSlice'

const DeleteInvoiceBulkModal = ({isOpen, onClose, data, query}) => {
	const {t} = useTranslation()
	const {deleteVatInvoiceBulk: isDeleting} = useSelector((state) => state.documents)
	const dispatch = useAppDispatch()
	
	const deleteBulkHandler = async () => {
		const tehIdArr = data?.map((item) => item.id)
		if (tehIdArr.length > 0) {
			try {
				const res = await dispatch(deleteVatInvoiceBulk(tehIdArr))
				console.log('res',res)
				if (res.status === 200) {
					onClose()
					dispatch(fetchDocuments(query))
				}
			} catch (e) {
				console.error(e)
			}
		}
	}
	
	return (
		<Modal
			title={t("vatReturns.delete")}
			description={
				<div
					style={{
						margin: "0 auto",
						padding: "20px",
						maxWidth: "600px",
						backgroundColor: "#f9f9f9",
						borderRadius: "8px",
						boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
					}}
				>
				  <p
					  style={{
						  fontSize: "18px",
						  fontWeight: "bold",
						  marginBottom: "10px",
						  textAlign: "center"
					  }}
				  >
				    {`Do you want to delete these invoices?`}
				  </p >
				  <ul
					  style={{
						  textAlign: "left",
						  paddingLeft: "20px",
						  fontSize: "16px",
						  lineHeight: "1.5",
						  maxHeight: 250,
						  overflowY: 'auto'
					  }}
				  >
				    {
					    data.length > 0 && data?.map(item => (
						    <li
							    key={item?.inv_number}
							    style={{
								    marginBottom: "5px",
								    display: "flex",
								    alignItems: "center"
							    }}
						    >
							    <span
								    style={{
									    // display: "inline-block",
									    width: "16px",
									    height: "16px",
									    marginRight: "8px",
									    backgroundColor: "rgba(216, 44, 13, 1)",
									    borderRadius: "50%",
									    display: "flex",
									    justifyContent: "center",
									    alignItems: "center",
									    color: "#fff",
									    fontSize: "12px",
									    fontWeight: "bold"
								    }}
							    >
			            ✓
			          </span >
							    {`${item?.company} | ${item?.inv_number}`}
			        </li >
					    ))
				    }
				  </ul >
				</div >
			}
			contentOnCenter
			iconType='danger'
			onClose={() => onClose("delete")}
			visible={isOpen}
			footer={
				<ButtonGroup fullWidth>
            <Button
	            size='large'
	            onClick={() => onClose("delete")}
            >
              {t("vatReturns.cancel")}
            </Button >
            <Button
	            size='large'
	            destructive
	            loading={isDeleting}
	            onClick={deleteBulkHandler}
            >
              {t("vatReturns.delete")}
            </Button >
          </ButtonGroup >
			}
		/>
	)
}

export default DeleteInvoiceBulkModal