import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import handleErrorForThunk from "helpers/handleErrorForThunk";
import { parseServerError } from "utils/errors";

export const clearDeleteInvoiceErrors = createAsyncThunk(
  "manager/clearDeleteInvoiceErrors",
  async () => {}
);

export const fetchListCompany = createAsyncThunk(
  "manager/fetchListCompany",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/manager/list_company`, "GET", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchListCompanySupport = createAsyncThunk(
  "manager/fetchListCompanySupport",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/manager/list_company`, "GET", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const editTaxReturnStatus = createAsyncThunk(
  "manager/editTaxReturnStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/manager/sales_tax_returns/change_status`,
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const sendTaxReturnFile = createAsyncThunk(
  "manager/sendTaxReturnFile",
  async ({ id, file }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/manager/sales_tax_returns/add_files?id=${id}`,
        "POST",
        file,
        { contentType: "multipart/form-data" }
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchListStoreStatus = createAsyncThunk(
  "manager/fetchListStoreStatus",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(`/manager/websites/status`, "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const editStoreStatus = createAsyncThunk(
  "manager/editStoreStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/manager/websites/status`, "PUT", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const editSalesTaxPermit = createAsyncThunk(
  "manager/editSalesTaxPermit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/manager/sales_tax_permit/edit`,
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const editActivateVatReg = createAsyncThunk(
  "manager/editActivateVatReg",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/manager/vat_registration/activate`,
        "PUT",
        data
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchBanksList = createAsyncThunk(
  "manager/fetchBanksList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/payment/get_banks_list", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteStore = createAsyncThunk(
  "manager/deleteStore",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi("/manager/websites/", "DELETE", { id });
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const changeEprStatus = createAsyncThunk(
  "manager/changeEprStatus",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/manager/epr_reports/change_status",
        "POST",
        params
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const editActivateEpr = createAsyncThunk(
  "manager/editActivateEpr",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/manager/epr_registration/activate`,
        "PUT",
        data
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getDataForEditEpr = createAsyncThunk(
  "manager/getDataForEditEpr",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/manager/epr_registration/data_for_edit`,
        "GET"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const editEprRegistration = createAsyncThunk(
  "manager/editEprRegistration",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/manager/epr_registration/edit`,
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const sendEprFile = createAsyncThunk(
  "manager/sendEprFile",
  async ({ id, file }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/manager/epr_reports/add_files?id=${id}`,
        "POST",
        file,
        { contentType: "multipart/form-data" }
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const setCompany = createAsyncThunk(
  "manager/setCompany",
  async ({ company_id }, { rejectWithValue }) => {
    try {
      const response = await callApi(`/manager/set_company`, "POST", {
        company_id,
      });

      if (response.status === 401) {
        window.location.href = '/login'
      }
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const sendVatReturnFile = createAsyncThunk(
  "manager/sendVatReturnFile",
  async ({ id, file }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/manager/vat_returns/add_files?id=${id}`,
        "POST",
        file,
        { contentType: "multipart/form-data" }
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const editVatReturnStatus = createAsyncThunk(
  "manager/editVatReturnStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/manager/vat_returns/change_status`,
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createPayment = createAsyncThunk(
  "manager/createPayment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/manager/billing_history/add_payments",
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error("Failed to create payment");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editInvoice = createAsyncThunk(
  "manager/editInvoice",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/manager/billing_history/change_invoice",
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error("Failed to edit invoice");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const sendInvoiceFile = createAsyncThunk(
  "manager/sendInvoiceFile",
  async ({ id, file }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await callApi(
        `/manager/billing_history/upload_file_invoice?id=${id}`,
        "POST",
        formData,
        { contentType: "multipart/form-data" }
      );
      if (!response.ok) {
        throw new Error("Failed to send invoice file");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  "manager/deleteInvoice",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/manager/billing_history/delete_invoice",
        "DELETE",
        data
      );
      if (!response.ok) {
        throw new Error("Failed to delete invoice");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editDialogStatus = createAsyncThunk(
  "manager/editDialogStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/manager/chat/change_dialogue_status",
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error("Failed to edit dialog status");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchPendingInvoices = createAsyncThunk(
  "manager/fetchPendingInvoices",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/manager/get_pending_invoice", "GET");
      if (!response.ok) {
        throw new Error("Failed to fetch pending invoices");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editRegNumberManagerArea = createAsyncThunk(
  "manager/editRegNumberManagerArea",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/manager/vat_registration/edit",
        "POST",
        params
      );
      if (!response.ok) {
        throw new Error("Failed to edit VAT registration number");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchListForChargeInvoice = createAsyncThunk(
  "manager/fetchListForChargeInvoice",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/subscription/list_for_order_charge_invoice",
        "GET"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch list for charge invoice");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createChargeInvoice = createAsyncThunk(
  "manager/createChargeInvoice",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/payment/charge_invoice_manager_order_subscription",
        "POST",
        data
      );
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!!!!");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(handleErrorForThunk(error));
    }
  }
);

export const editExemption = createAsyncThunk(
  "manager/editExemption",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/manager/exemption/edit", "POST", data);
      if (!response.ok) {
        throw new Error("Failed to edit exemption");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editWaiver = createAsyncThunk(
  "manager/editWaiver",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/manager/waiver/edit", "POST", data);
      if (!response.ok) {
        throw new Error("Failed to edit waiver");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadEprFile = createAsyncThunk(
  "manager/uploadEprFile",
  async ({ id, file }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await callApi(
        `/manager/epr_reports/add_files?id=${id}`,
        "POST",
        formData,
        { contentType: "multipart/form-data" }
      );
      if (!response.ok) {
        throw new Error("Failed to upload EPR file");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const managerSlice = createSlice({
  name: "manager",
  initialState: {
    listCompany: {},
    listCompanySupport: {},
    listForChargeInvoice: [],
    banksList: [],
    pendingInvoices: [],
    company: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListCompany.pending, (state) => {
        state.fetchingListCompany = true;
      })
      .addCase(fetchListCompany.fulfilled, (state, action) => {
        state.fetchingListCompany = false;
        state.listCompany = action.payload;
        state.fetchingListCompanyError = null;
      })
      .addCase(fetchListCompany.rejected, (state, action) => {
        state.fetchingListCompany = false;
        state.fetchingListCompanyError = parseServerError(action.error);
      })
      .addCase(fetchListCompanySupport.pending, (state) => {
        state.fetchingListCompanySupport = true;
      })
      .addCase(fetchListCompanySupport.fulfilled, (state, action) => {
        state.fetchingListCompanySupport = false;
        state.listCompanySupport = action.payload;
        state.fetchingListCompanySupportError = null;
      })
      .addCase(fetchListCompanySupport.rejected, (state, action) => {
        state.fetchingListCompanySupport = false;
        state.fetchingListCompanySupportError = parseServerError(action.error);
      })
      .addCase(fetchListStoreStatus.pending, (state) => {
        state.fetchingListStoreStatus = true;
      })
      .addCase(fetchListStoreStatus.fulfilled, (state, action) => {
        state.fetchingListStoreStatus = false;
        state.listStoreStatus = action.payload;
        state.fetchingListStoreStatusError = null;
      })
      .addCase(fetchListStoreStatus.rejected, (state, action) => {
        state.fetchingListStoreStatus = false;
        state.fetchingListStoreStatusError = parseServerError(action.error);
      })
      .addCase(createPayment.pending, (state) => {
        state.creatingPayment = true;
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.creatingPayment = false;
        state.new_payment_id = action.payload.payment_id;
        state.creatingPaymentError = null;
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.creatingPayment = false;
        state.creatingPaymentError = parseServerError(action.error);
      })
      .addCase(editInvoice.pending, (state) => {
        state.editingInvoice = true;
      })
      .addCase(editInvoice.fulfilled, (state) => {
        state.editingInvoice = false;
        state.editingInvoiceError = null;
      })
      .addCase(editInvoice.rejected, (state, action) => {
        state.editingInvoice = false;
        state.editingInvoiceError = parseServerError(action.error);
      })
      .addCase(sendInvoiceFile.pending, (state) => {
        state.sendingInvFile = true;
      })
      .addCase(sendInvoiceFile.fulfilled, (state) => {
        state.sendingInvFile = false;
        state.sendingInvFileError = null;
      })
      .addCase(sendInvoiceFile.rejected, (state, action) => {
        state.sendingInvFile = false;
        state.sendingInvFileError = parseServerError(action.error);
      })
      .addCase(deleteInvoice.pending, (state) => {
        state.deletingInvoice = true;
      })
      .addCase(deleteInvoice.fulfilled, (state) => {
        state.deletingInvoice = false;
        state.deletingInvoiceError = null;
      })
      .addCase(deleteInvoice.rejected, (state, action) => {
        state.deletingInvoice = false;
        state.deletingInvoiceError = parseServerError(action.error);
      })
      .addCase(clearDeleteInvoiceErrors.fulfilled, (state) => {
        state.deletingInvoiceError = null;
      })
      .addCase(editDialogStatus.pending, (state) => {
        state.editingDialogStatus = true;
      })
      .addCase(editDialogStatus.fulfilled, (state) => {
        state.editingDialogStatus = false;
        state.editingDialogStatusError = null;
      })
      .addCase(editDialogStatus.rejected, (state, action) => {
        state.editingDialogStatus = false;
        state.editingDialogStatusError = parseServerError(action.error);
      })
      .addCase(editVatReturnStatus.pending, (state) => {
        state.editingVatReturnStatus = true;
      })
      .addCase(editVatReturnStatus.fulfilled, (state) => {
        state.editingVatReturnStatus = false;
        state.editingVatReturnStatusError = null;
      })
      .addCase(editVatReturnStatus.rejected, (state, action) => {
        state.editingVatReturnStatus = false;
        state.editingVatReturnStatusError = parseServerError(action.error);
      })
      .addCase(sendVatReturnFile.pending, (state) => {
        state.sendingVatFile = true;
      })
      .addCase(sendVatReturnFile.fulfilled, (state) => {
        state.sendingVatFile = false;
        state.sendingVatFileError = null;
      })
      .addCase(sendVatReturnFile.rejected, (state, action) => {
        state.sendingVatFile = false;
        state.sendingVatFileError = parseServerError(action.error);
      })
      .addCase(editStoreStatus.pending, (state) => {
        state.editingStoreStatus = true;
      })
      .addCase(editStoreStatus.fulfilled, (state) => {
        state.editingStoreStatus = false;
        state.editingStoreStatusError = null;
      })
      .addCase(editStoreStatus.rejected, (state, action) => {
        state.editingStoreStatus = false;
        state.editingStoreStatusError = parseServerError(action.error);
      })
      .addCase(deleteStore.pending, (state) => {
        state.deletingStore = true;
      })
      .addCase(deleteStore.fulfilled, (state) => {
        state.deletingStore = false;
        state.deletingStoreError = null;
      })
      .addCase(deleteStore.rejected, (state, action) => {
        state.deletingStore = false;
        state.deletingStoreError = parseServerError(action.error);
      })
      .addCase(editActivateVatReg.pending, (state) => {
        state.editingActivateVatReg = true;
      })
      .addCase(editActivateVatReg.fulfilled, (state) => {
        state.editingActivateVatReg = false;
        state.editingActivateVatRegError = null;
      })
      .addCase(editActivateVatReg.rejected, (state, action) => {
        state.editingActivateVatReg = false;
        state.editingActivateVatRegError = parseServerError(action.error);
      })
      .addCase(editRegNumberManagerArea.pending, (state) => {
        state.editingVatNum = true;
      })
      .addCase(editRegNumberManagerArea.fulfilled, (state) => {
        state.editingVatNum = false;
        state.editingVatNumError = null;
      })
      .addCase(editRegNumberManagerArea.rejected, (state, action) => {
        state.editingVatNum = false;
        state.editingVatNumError = parseServerError(action.error);
      })
      .addCase(fetchPendingInvoices.pending, (state) => {
        state.fetchingInvs = true;
      })
      .addCase(fetchPendingInvoices.fulfilled, (state, action) => {
        state.fetchingInvs = false;
        state.fetchingInvsError = null;
        state.pendingInvoices = action.payload;
      })
      .addCase(fetchPendingInvoices.rejected, (state, action) => {
        state.fetchingInvs = false;
        state.fetchingInvsError = parseServerError(action.error);
      })
      .addCase(fetchBanksList.pending, (state) => {
        state.fetchingBanksList = true;
      })
      .addCase(fetchBanksList.fulfilled, (state, action) => {
        state.fetchingBanksList = false;
        state.fetchingBanksListError = null;
        state.banksList = action.payload;
      })
      .addCase(fetchBanksList.rejected, (state, action) => {
        state.fetchingBanksList = false;
        state.fetchingBanksListError = parseServerError(action.error);
      })
      .addCase(fetchListForChargeInvoice.pending, (state) => {
        state.fetchingListForChargeInv = true;
      })
      .addCase(fetchListForChargeInvoice.fulfilled, (state, action) => {
        state.fetchingListForChargeInv = false;
        state.fetchingListForChargeInvError = null;
        state.listForChargeInvoice = action.payload;
      })
      .addCase(fetchListForChargeInvoice.rejected, (state, action) => {
        state.fetchingListForChargeInv = false;
        state.fetchingListForChargeInvError = parseServerError(action.error);
      })
      .addCase(createChargeInvoice.pending, (state) => {
        state.creatingInv = true;
      })
      .addCase(createChargeInvoice.fulfilled, (state, action) => {
        state.creatingInv = false;
        state.creatingInvError = null;
        state.result = action.payload;
      })
      .addCase(createChargeInvoice.rejected, (state, action) => {
        state.creatingInv = false;
        state.creatingInvError = parseServerError(action.error);
      })
      .addCase(editSalesTaxPermit.pending, (state) => {
        state.editingTaxPermit = true;
      })
      .addCase(editSalesTaxPermit.fulfilled, (state) => {
        state.editingTaxPermit = false;
        state.editingTaxPermitError = null;
      })
      .addCase(editSalesTaxPermit.rejected, (state, action) => {
        state.editingTaxPermit = false;
        state.editingTaxPermitError = parseServerError(action.error);
      })
      .addCase(editExemption.pending, (state) => {
        state.editingEx = true;
      })
      .addCase(editExemption.fulfilled, (state) => {
        state.editingEx = false;
        state.editingExError = null;
      })
      .addCase(editExemption.rejected, (state, action) => {
        state.editingEx = false;
        state.editingExError = parseServerError(action.error);
      })
      .addCase(editWaiver.pending, (state) => {
        state.editingWaiver = true;
      })
      .addCase(editWaiver.fulfilled, (state) => {
        state.editingWaiver = false;
        state.editingWaiverError = null;
      })
      .addCase(editWaiver.rejected, (state, action) => {
        state.editingWaiver = false;
        state.editingWaiverError = parseServerError(action.error);
      })
      .addCase(editTaxReturnStatus.pending, (state) => {
        state.editingTaxReturnStatus = true;
      })
      .addCase(editTaxReturnStatus.fulfilled, (state) => {
        state.editingTaxReturnStatus = false;
        state.editingTaxReturnStatusError = null;
      })
      .addCase(editTaxReturnStatus.rejected, (state, action) => {
        state.editingTaxReturnStatus = false;
        state.editingTaxReturnStatusError = parseServerError(action.error);
      })
      .addCase(sendTaxReturnFile.pending, (state) => {
        state.sendingTaxFile = true;
      })
      .addCase(sendTaxReturnFile.fulfilled, (state) => {
        state.sendingTaxFile = false;
        state.sendingTaxFileError = null;
      })
      .addCase(sendTaxReturnFile.rejected, (state, action) => {
        state.sendingTaxFile = false;
        state.sendingTaxFileError = parseServerError(action.error);
      })
      .addCase(changeEprStatus.pending, (state) => {
        state.editingEprStatus = true;
      })
      .addCase(changeEprStatus.fulfilled, (state) => {
        state.editingEprStatus = false;
      })
      .addCase(changeEprStatus.rejected, (state) => {
        state.editingEprStatus = false;
      })
      .addCase(getDataForEditEpr.pending, (state) => {
        state.fetchingDataForEditEpr = true;
      })
      .addCase(getDataForEditEpr.fulfilled, (state, action) => {
        state.fetchingDataForEditEpr = false;
        state.data_for_edit = action.payload;
        state.fetchingDataForEditError = null;
      })
      .addCase(getDataForEditEpr.rejected, (state, action) => {
        state.fetchingDataForEditEpr = false;
        state.data_for_edit = null;
        state.fetchingDataForEditError = parseServerError(action.error);
      })
      .addCase(uploadEprFile.pending, (state) => {
        state.uploadingEprFile = true;
      })
      .addCase(uploadEprFile.fulfilled, (state) => {
        state.uploadingEprFile = false;
        state.uploadingEprFileError = null;
      })
      .addCase(uploadEprFile.rejected, (state, action) => {
        state.uploadingEprFile = false;
        state.uploadingEprFileError = parseServerError(action.error);
      })
      .addCase(setCompany.pending, (state) => {
        state.loadingSetCompany = true;
      })
      .addCase(setCompany.fulfilled, (state, action) => {
        state.loadingSetCompany = false;
        state.loadingSetCompanyError = null;
        state.company = action.payload.company_id;
      })
      .addCase(setCompany.rejected, (state, action) => {
        state.loadingSetCompany = false;
        state.loadingSetCompanyError = parseServerError(action.error);
      });
  },
});

export default managerSlice.reducer;
