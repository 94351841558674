import { FormLayout, Select } from "@shopify/polaris";
import React from "react";
import { useSelector } from "react-redux";

const BusinessType = (props) => {
  const { data, setData } = props;
  const { exemptionData } = useSelector((state) => state.documents);
  const typesOfBusinesses =
    exemptionData.types_of_business?.map((type) => ({
      value: type,
      label: type,
    })) ?? [];

  return (
    <FormLayout>
      <FormLayout.Group>
        <Select
          options={typesOfBusinesses}
          label='Business Type'
          value={data.businessType}
          onChange={(type) =>
            setData((prev) => ({ ...prev, businessType: type }))
          }
        />
        <br />
      </FormLayout.Group>
    </FormLayout>
  );
};

export default BusinessType;
