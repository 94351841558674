import React, { useEffect } from "react";
import PageHelmet from "components/PageHelmet";
import { Page, Layout, Text, Tabs, Banner, AlphaStack } from "@shopify/polaris";
import _ from "lodash";
import { DocumentType } from "containers/DocumentTemplate/common/constants";
import ExemptionTable from './ExemptionCertificates/ExemptionTable';
import useFetchDocumentsMessages from "./hooks/useFetchDocumentsMessages";
import useBannerActions from "./hooks/useBannerActions";
import useTabs from "./hooks/useTabs";
import Invoices from "./tabs/Invoices";

const Documents = () => {
  const { messages } = useFetchDocumentsMessages();
  const { getBannerStatus, onBannerDismiss } = useBannerActions();
  const { tabs, selectedTab, handleTabSelect } = useTabs();
  
  useEffect(() => {
    const savedTab = sessionStorage.getItem('selectedTab');
    if (savedTab !== null) {
      handleTabSelect(Number(savedTab));
    }
  }, [handleTabSelect]);
  
  const handleTabSelectWithStorage = (tab) => {
    handleTabSelect(tab);
    sessionStorage.setItem('selectedTab', tab);
  };
  
  return (
    <Page
      separator
      fullWidth
      title={
        <Text variant='heading3xl' as='span'>
          Documents
        </Text>
      }
      subtitle={
        <Text variant='bodyLg' as='span' color='subdued'>
          Simplify your financial documentation with our ready-made templates.
          Choose a template, input your data, and generate your document in
          seconds.
        </Text>
      }
    >
      <PageHelmet title={"Documents"} />

      <Layout>
        {!_.isEmpty(messages) && (
          <Layout.Section>
            <AlphaStack fullWidth spacing='4'>
              {messages.map((message, index) => (
                <Banner
                  key={`${index}banner`}
                  onDismiss={() => onBannerDismiss(message)}
                  status={getBannerStatus(message.status)}
                >
                  {message.message}
                </Banner>
              ))}
            </AlphaStack>
          </Layout.Section>
        )}
        
        <Layout.Section>
          <Tabs
            tabs={tabs}
            selected={selectedTab}
            onSelect={(tab) => handleTabSelectWithStorage(tab)} // Используем обновленную функцию
          />
        </Layout.Section>
        
        {selectedTab === 0 && (
          <Invoices documentType={DocumentType.InvoiceVAT} />
        )}
        {selectedTab === 1 && (
          <Invoices documentType={DocumentType.InvoiceSalesTax} />
        )}
        {selectedTab === 2 && <ExemptionTable />}
      </Layout>
    </Page>
  );
};

export default Documents;
