import { Button, Modal, Stack } from '@shopify/polaris'
import './customElement.css'
import addIMG from "img/add-white.svg"
import backIMG from "img/arrow-left-white.svg"
import { useEffect, useState } from 'react'
import CreateNewTransaction from './contentModalStep2/CreateNewTransaction'
import ListTransactions from './contentModalStep2/ListTransactions'

const ModalWindowForTransactionsBuilder = ({handleChange, active, data, changeVatStr}) => {
	const [toggle, setToggle] = useState(false);
	const [dateRange, setDateRange] = useState({date_end: null, date_start: null});
	
	const setDateRangeHandler = (value) => {
		setDateRange(value)
	}

	const toggleModalContentHandler = () => {
		setToggle(!toggle)
	}
	useEffect(() => {
		if (!active) {
			setToggle(false)
		}
	}, [active]);

	return (
		<Modal
			open={active}
			onClose={handleChange}
			title={
				<Stack distribution='equalSpacing'>
          <div >{!toggle ? 'Transactions List' : 'Add Transaction'}</div >
          <Stack spacing='tight'>
		          <Button
			          primary
			          onClick={toggleModalContentHandler}
		          >
              <span style={{display: 'flex', alignItems: 'center'}}>
                <img
	                src={!toggle ? addIMG : backIMG}
	                alt='add icon'
	                style={{marginRight: '8px', width: '16px', height: '16px'}}
                />
	              {!toggle ? 'Add Transaction Form' : 'Back to Transactions List'}
              </span >
            </Button >

          </Stack >
        </Stack >
			}

			size='lg'
			style={{padding: '0'}}
		>
			{
				toggle
					?
					<div className='transaction-create'>
						<CreateNewTransaction
							closeHandler={toggleModalContentHandler}
							data={data}
							changeVatStr={changeVatStr}
							dateRange={dateRange}
						/>
					</div >
					:
					<ListTransactions
						active={active}
						changeVatStr={changeVatStr}
						data={data}
						setDateRangeHandler={setDateRangeHandler}
					/>
			}
    </Modal >
	);
}

export default ModalWindowForTransactionsBuilder