import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { parseServerError } from "utils/errors";
import callApi from "helpers/callApi";
import handleErrorForThunk from "helpers/handleErrorForThunk";

export const fetchMerch = createAsyncThunk(
  "merchSettings/fetchMerch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/merchant_settings/", "GET");
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const createMerch = createAsyncThunk(
  "merchSettings/createMerch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/merchant_settings/", "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const deleteMerch = createAsyncThunk(
  "merchSettings/deleteMerch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(`/merchant_settings/?id=${id}`, "DELETE");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const editMerch = createAsyncThunk(
  "merchSettings/editMerch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/merchant_settings/", "PUT", data);
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const downloadMerchFile = createAsyncThunk(
  "merchSettings/downloadMerchFile",
  async (file, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/merchant_settings/import",
        "POST",
        file,
        { contentType: "multipart/form-data" }
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

const merchSettingsSlice = createSlice({
  name: "merchSettings",
  initialState: {
    merchants: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerch.pending, (state) => {
        state.fetchingMerch = true;
      })
      .addCase(fetchMerch.fulfilled, (state, action) => {
        state.fetchingMerch = false;
        state.merchants = action.payload;
        state.fetchingMerchError = null;
      })
      .addCase(fetchMerch.rejected, (state, action) => {
        state.fetchingMerch = false;
        state.fetchingMerchError = parseServerError(action.payload);
      })
      .addCase(createMerch.pending, (state) => {
        state.creatingMerch = true;
      })
      .addCase(createMerch.fulfilled, (state) => {
        state.creatingMerch = false;
        state.creatingMerchError = null;
      })
      .addCase(createMerch.rejected, (state, action) => {
        state.creatingMerch = false;
        state.creatingMerchError = parseServerError(action.payload);
      })
      .addCase(deleteMerch.pending, (state) => {
        state.deletingMerch = true;
        state.deletingMerchError = null;
      })
      .addCase(deleteMerch.fulfilled, (state) => {
        state.deletingMerch = false;
        state.deletingMerchError = null;
      })
      .addCase(deleteMerch.rejected, (state, action) => {
        state.deletingMerch = false;
        state.deletingMerchError = parseServerError(action.payload);
      })
      .addCase(editMerch.pending, (state) => {
        state.editing = true;
        state.editingError = null;
      })
      .addCase(editMerch.fulfilled, (state) => {
        state.editing = false;
        state.editingError = null;
      })
      .addCase(editMerch.rejected, (state, action) => {
        state.editing = false;
        state.editingError = parseServerError(action.payload);
      })
      .addCase(downloadMerchFile.pending, (state) => {
        state.downloadingMerch = true;
      })
      .addCase(downloadMerchFile.fulfilled, (state) => {
        state.downloadingMerch = false;
        state.downloadingMerchError = null;
      })
      .addCase(downloadMerchFile.rejected, (state, action) => {
        state.downloadingMerch = false;
        state.downloadingMerchError = parseServerError(action.payload);
      });
  },
});

export default merchSettingsSlice.reducer;
