import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  fetchUser,
  fetchUsers,
  fetchCompanyDefaultCurrency,
  fetchCompanyDefaultLanguage,
  fetchUserGuide,
} from "redux/features/user/userSlice";
import { fetchCurrentPlan } from "redux/features/subscription/subscriptionSlice";
import {
  fetchAllCountries,
  fetchTypesServicesPvd,
  fetchServicesPvd,
} from "redux/features/other/otherSlice";
import { fetchPaymentInfo } from "redux/features/profile/profileSlice";
import { Button, Page, Text, AlphaStack, Tabs } from "@shopify/polaris";
import withRouter from "helpers/withRouter";

import logoutIMG from "img/logout.svg";
import CompanySettings from "./CompanySettings/CompanySettings";
import PersonalSettings from "./PersonalSettings/PersonalSettings";
import ReferralProgram from "./ReferralProgram/ReferralProgram";
import PageHelmet from "components/PageHelmet";
import LogoutModal from "components/LogoutModal/LogoutModal";
import { createCustomDispatch } from "helpers/customDispatch";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,

      actionDialogs: {
        logout: { open: false },
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.company !== this.props.company) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.fetchUserGuide();
    this.props.fetchAllCountries();
    this.props.fetchUsers();
    this.props.fetchCurrentPlan();
    this.props.fetchCompanyDefaultCurrency();
    this.props.fetchCompanyDefaultLanguage();
    this.props.fetchPaymentInfo();
    this.props.fetchTypesServicesPvd();
    this.props.fetchServicesPvd();
  }

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  renderLogoutDialog() {
    const { actionDialogs } = this.state;

    return (
      <LogoutModal
        visible={actionDialogs.logout.open}
        onClose={() => this.handleActionDialogsClose("logout")}
      />
    );
  }

  afterCreatingUser = () => {
    this.props.fetchUsers();
  };

  afterEditingUser = () => {
    this.props.fetchUsers();
  };

  afterDeletingUser = () => {
    this.props.fetchUsers();
  };

  styles = {
    underlineStyle: {
      borderColor: "#E0AD05",
    },
    activeColor: {
      color: "#E0AD05",
    },
    floatingLabelFocusStyle: {
      color: "#747474",
    },
  };

  render() {
    const { t, user } = this.props;
    const { selected } = this.state;

    const tabs = [
      {
        id: "companySettings",
        content: t("profile.companySettings"),
        accessibilityLabel: t("profile.companySettings"),
        panelID: "companySettings",
      },
      {
        id: "personalSettings",
        content: t("profile.personalSettingsLabel"),
        accessibilityLabel: t("profile.personalSettingsLabel"),
        panelID: "personalSettings",
      },
      {
        id: "referralProgram",
        content: t("profile.referralProgram"),
        accessibilityLabel: t("profile.referralProgram"),
        panelID: "referralProgram",
      },
    ];

    return (
      <Page
        fullWidth
        title={
          <Text variant='heading3xl' as='span'>
            {user.company ? user.company.name : ""}
          </Text>
        }
        subtitle={
          <AlphaStack spacing='05'>
            <Text variant='bodyLg' as='span' color='subdued'>
              {t("profile.hereYouCanEditYourCompany")}
            </Text>

            <a
              className='Polaris-Link'
              href='/files/Privacy Policy of website vatcompliance.co.pdf'
              download
              style={{ textDecoration: "none" }}
            >
              {t("profile.pp")}
            </a>
          </AlphaStack>
        }
        primaryAction={
          <Button
            plain
            icon={
              <img src={logoutIMG} alt='icon logout' style={{ marginTop: 4 }} />
            }
            onClick={() => this.handleActionDialogsOpen("logout")}
          >
            <Text color='subdued'>{t("profile.logOut")}</Text>
          </Button>
        }
      >
        <PageHelmet title={"Profile"} />

        <Tabs
          tabs={tabs}
          selected={selected}
          onSelect={(v) => this.setState({ selected: v })}
        />

        {selected === 0 && <CompanySettings {...this.props} />}
        {selected === 1 && <PersonalSettings {...this.props} />}
        {selected === 2 && <ReferralProgram {...this.props} />}

        <br />

        {this.renderLogoutDialog()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.other.countriesAll,
  user: state.user.user,
  userGuide: state.user.userGuide,
  users: state.user.users,
  currentPlan: state.subscription.currentPlan,
  defaultCurrency: state.user.defaultCurrency,
  defaultLanguage: state.user.defaultLanguage,
  typesServicesPvd: state.other.typesServicesPvd,
  servicesPvd: state.other.servicesPvd,
  company: state.manager.company,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchUser: () => dispatch(fetchUser()),
    fetchUsers: () => dispatch(fetchUsers()),
    fetchUserGuide: () => dispatch(fetchUserGuide()),
    fetchAllCountries: () => dispatch(fetchAllCountries()),
    fetchCurrentPlan: () => dispatch(fetchCurrentPlan()),
    fetchCompanyDefaultCurrency: () => dispatch(fetchCompanyDefaultCurrency()),
    fetchCompanyDefaultLanguage: () => dispatch(fetchCompanyDefaultLanguage()),
    fetchTypesServicesPvd: () => dispatch(fetchTypesServicesPvd()),
    fetchPaymentInfo: (params) => dispatch(fetchPaymentInfo(params)),
    fetchServicesPvd: () => dispatch(fetchServicesPvd()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile))
);
