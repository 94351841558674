import moment from "moment";

const getDefaultValue = () => {
  return {
    value: "",
    label: "",
  };
};

/**
 * Generates an array of years based on the specified options.
 *
 * @param {object} options - Options for generating years.
 * @param {number} [options.yearsBefore=3] - The number of years before the current year.
 * @param {number} [options.yearsAfter=1] - The number of years after the current year.
 * @param {boolean} [options.showDefaultValue=true] - Whether to include a default value at the beginning of the array.
 * @param {boolean} [options.reverseOrder=false] - Whether to reverse the order of the generated years.
 *
 * @returns {Array} - An array of objects representing years.
 * @property {string} value - The value of the year.
 * @property {number} label - The label of the year.
 */
export const getYears = (options = {}) => {
  const {
    yearsBefore = 3,
    yearsAfter = 1,
    showDefaultValue = true,
    reverseOrder = false,
  } = options;

  const currentYear = moment().year();

  const years = Array.from(
    { length: yearsBefore + yearsAfter + 1 }, // +1 for the current year
    (_, index) => {
      const yearOffset = index - yearsBefore;
      const yearValue = (currentYear + yearOffset).toString();
      const yearLabel = currentYear + yearOffset;

      return { value: yearValue, label: yearLabel };
    }
  );

  if (showDefaultValue) {
    years.unshift(getDefaultValue());
  }

  if (reverseOrder) {
    return years.reverse();
  }

  return years;
};
