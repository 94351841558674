import React from "react";
import RCPagination from "rc-pagination";
import { ReactComponent as DoubleArrow } from "img/doubleChevron.svg";
import "./pagination.scss";

const Pagination = (props) => {
  return (
    <RCPagination
      {...props}
      className='customPagination'
      prevIcon={<DoubleArrow />}
      nextIcon={<DoubleArrow />}
      showTotal={(total, [from, to]) => `${from} - ${to} of ${total}`}
      showTitle={false}
    />
  );
};

export default Pagination;
