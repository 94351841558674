import { Card } from '@shopify/polaris'
import classNames from 'classnames'
import cx from 'classnames'
import React from 'react'
import ReactModal from 'react-modal'
import closeIMG from 'img/close.svg'
import './modalStyle.css'
import PreviewEprInfo from './PreviewEPRInfo'

const PreviewEpr = ({title, visible, onClose, data}) => {
	const LiquidData = data?.data ? data?.data : data
	const arrayOfObjects = Object.keys(LiquidData)
			.filter(key => !isNaN(key))
			.map(key => data[key]);
	return (
		<ReactModal
			closeTimeoutMS={100}
			isOpen={visible}
			onRequestClose={onClose}
			className='reactModal'
			overlayClassName='reactModalOverlay'
		>
      <Card >
        <div
	        className={cx("modal")}
	        style={{maxHeight: '70vh', overflow: 'auto'}}
        >
          <div className='modal__header'>
	          <div
		          className={classNames("modal__title")}
		          style={{textTransform: 'uppercase'}}
		          dangerouslySetInnerHTML={{__html: title}}
	          />
		          <img
			          src={closeIMG}
			          alt='close'
			          onClick={onClose}
			          className='modal__close'
		          />
          </div >
	        <PreviewEprInfo data={data} arr={arrayOfObjects}/>
        </div >
      </Card >
    </ReactModal >
	)
}

export default PreviewEpr