import React from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "hooks/useAppDispatch";
import { selectCategory3 } from "redux/features/declarations/declarationsSlice";
import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Text,
  DataTable,
  Inline, Box,
} from "@shopify/polaris";
import SaveBar from "components/SaveBar/SaveBar";
import { t } from "i18next";
import formatRow from "utils/formatRow";

const SubcategoryStep3 = ({ toNextStep, toPrevStep }) => {
  const dispatch = useAppDispatch();
  const {
    eprDeclaration: { subcategories_data },
    eprSubcategories: { selectedCategory3, selectedCategory2 },
  } = useSelector((state) => state.declarations);
  
  // console.log('step3, selectedCategory2', selectedCategory2)
  // console.log('step3, selectedCategory3', selectedCategory3)
  
  const handleChange = (checked, category, subCategory, subSubCategory) => {
    if (checked) {
      dispatch(
        selectCategory3({
          checked: true,
          category,
          subCategory,
          subSubCategory
        })
      );
    } else {
      dispatch(
        selectCategory3({
          checked: false,
          category,
          subCategory,
          subSubCategory
        })
      );
    }
  };

  const columns = [
    {
      header: {
        label: "Subcategory 1",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[0];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 2",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{rowData[1]}</div>;
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 3",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            const [category, subCategory] = rowData;
            const checkboxes = subcategories_data.subcategory_3[subCategory];
            return (
              <Box fullWidth style={{ display: 'flex', flexDirection: 'column', gap: '16px', whiteSpace: 'normal' }}>
                {
                  checkboxes !== undefined
                    ?
                    checkboxes && checkboxes?.map((checkbox) => {
                      const id = checkbox + category + subCategory
                      return (
                        <Checkbox
                          key={id}
                          id={id}
                          value={checkbox}
                          label={checkbox}
                          checked={selectedCategory3.some(
                            (c) =>
                              c[0] === category &&
                              c[1] === subCategory &&
                              c[2] === checkbox
                          )}
                          onChange={(checked) =>
                            handleChange(checked, category, subCategory, checkbox)
                          }
                        />
                      );
                    })
                    :
                    subcategories_data?.subcategory_3?.map((checkbox) => {
                      const id = `${checkbox.name}-${checkbox.units || ''}-${category}-${subCategory}`;
                      
                      return (
                        <Checkbox
                          key={id}
                          id={id}
                          value={checkbox.name}
                          label={`${checkbox.name} (${checkbox.units || ''})`}
                          checked={selectedCategory3.some(
                            (c) =>
                              c[0] === category &&
                              c[1] === subCategory &&
                              c[2].name === checkbox.name &&
                              c[2].units === (checkbox.units || '')
                          )}
                          onChange={(newCheckedValue) =>{
                            handleChange(newCheckedValue, category, subCategory, checkbox)
                          }}
                        />
                      );
                    })
                }
              </Box>
            );
          },
        ],
      },
    },
  ];

  const rows = formatRow(selectedCategory2, columns);

  return (
    <>
      <Card sectioned>
        <DataTable
          columnContentTypes={["text", "text", "text"]}
          headings={columns.map(({ header }) => (
            <Text fontWeight='semibold'>{header.label}</Text>
          ))}
          rows={rows}
        />
      </Card>

      <SaveBar>
        <ButtonGroup>
          <Button onClick={toPrevStep}>{t("eprReporting.back")}</Button>
          <Button primary onClick={toNextStep}>
            {t("orderReg.next")}
          </Button>
        </ButtonGroup>
      </SaveBar>
    </>
  );
};

export default SubcategoryStep3;
