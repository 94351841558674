import React from "react";

function Video(props) {
  return (
    <div style={{ width: "32%" }}>
      <div style={{ display: "flex", marginBottom: "15px" }}>
        <div
          style={{
            width: "100%",
            borderRadius: "5px",
            overflow: "hidden",
            boxShadow: "0 5px 8px -6px black",
          }}
        >
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              paddingTop: "30px",
              height: "0",
              overflow: "hidden",
            }}
          >
            <iframe
              src={props.iframeSrc}
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                width: "100%",
                height: "100%",
              }}
              frameBorder='0'
              allowFullScreen
              title='video'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Video;
