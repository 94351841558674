import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import { parseServerError } from "utils/errors";

export const fetchEprSubscriptionPlans = createAsyncThunk(
  "subscription/fetchEprSubscriptionPlans",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/subscription/epr_subscriprion_plans",
        "GET"
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCurrentPlan = createAsyncThunk(
  "subscription/deleteCurrentPlan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/subscription/cancel", "POST", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSandboxData = createAsyncThunk(
  "subscription/deleteSandboxData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/sandbox/dell_data", "POST", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const switchSandboxMode = createAsyncThunk(
  "subscription/switchSandboxMode",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/sandbox/on_off", "POST");
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const switchCalculateThresholds = createAsyncThunk(
  "subscription/switchCalculateThresholds",
  async (value, { rejectWithValue }) => {
    try {
      const response = await callApi("/company/calculate_threshold", "PUT", {
        calculate_threshold: value,
      });
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const switchCalculateRealTime = createAsyncThunk(
  "subscription/switchCalculateRealTime",
  async (value, { rejectWithValue }) => {
    try {
      const response = await callApi("/exemption/real_time_exemptions", "PUT", {
        real_time_exemptions: value,
      });
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const switchCalculateThresholdsNexus = createAsyncThunk(
  "subscription/switchCalculateThresholdsNexus",
  async (value, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/sales_tax_permit/calculate_threshold_nexus",
        "PUT",
        {
          calculate_threshold_nexus: value,
        }
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCurrentPlan = createAsyncThunk(
  "subscription/fetchCurrentPlan",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/subscription/current", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchBillingHistory = createAsyncThunk(
  "subscription/fetchBillingHistory",
  async (params = { limit: 50, offset: 0 }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/subscription/billing_history",
        "GET",
        params
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const downloadInvoiceFile = createAsyncThunk(
  "subscription/downloadInvoiceFile",
  async (params = {}, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/subscription/billing_history/download",
        "GET",
        params,
        {
          responseType: "blob",
        }
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const blob = await response.blob();
      const link = window.URL.createObjectURL(blob);
      return { link };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSubscriptionPlans = createAsyncThunk(
  "subscription/fetchSubscriptionPlans",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/subscription/tariff_plan", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCalculateSubscription = createAsyncThunk(
  "subscription/fetchCalculateSubscription",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/subscription/subscription_calculate",
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCalculateAddons = createAsyncThunk(
  "subscription/fetchCalculateAddons",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/subscription/addons_calculate",
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCalculateChargeInvoice = createAsyncThunk(
  "subscription/fetchCalculateChargeInvoice",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/subscription/charge_invoice_calculate",
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    currentPlan: null,
    subscriptionPlans: [],
    eprSubscriptionPlans: null,
    billingHistory: {},
    downloadingInvoice: false,
    downloadingInvoiceError: null,
    fetchingCurrentPlan: false,
    fetchingCurrentPlanError: null,
    fetchingSubscriptionPlans: false,
    fetchingSubscriptionPlansError: null,
    fetchingEprSubscriptionPlans: false,
    fetchingEprSubscriptionPlansError: null,
    fetchingBillingHistory: false,
    fetchingBillingHistoryError: null,
    deletingCurrentPlan: false,
    deletingCurrentPlanError: null,
    switchingSandboxMode: false,
    switchingSandboxModeError: null,
    deletingSandboxData: false,
    deletingSandboxDataError: null,
    switchingCalculateThresholds: false,
    switchingCalculateThresholdsError: null,
    switchingCalculateThresholdsNexus: false,
    switchingCalculateThresholdsNexusError: null,
    switchingCalculateRealTime: false,
    switchingCalculateRealTimeError: null,
    calculatingSubscription: false,
    calculatingSubscriptionError: null,
    calculatingAddons: false,
    calculatingAddonsError: null,
    calculatingChargeInvoice: false,
    calculatingChargeInvoiceError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionPlans.pending, (state) => {
        state.fetchingSubPlans = true;
      })
      .addCase(fetchSubscriptionPlans.fulfilled, (state, action) => {
        state.fetchingSubPlans = false;
        state.fetchingSubPlansError = null;
        state.subscriptionPlans = action.payload;
      })
      .addCase(fetchSubscriptionPlans.rejected, (state, action) => {
        state.fetchingSubPlans = false;
        state.subscriptionPlans = [];
        state.fetchingSubPlansError = parseServerError(action.payload);
      })
      .addCase(fetchCurrentPlan.pending, (state) => {
        state.fetchingCurrentPlan = true;
      })
      .addCase(fetchCurrentPlan.fulfilled, (state, action) => {
        state.fetchingCurrentPlan = false;
        state.fetchingCurrentPlanError = null;
        state.currentPlan = action.payload;
      })
      .addCase(fetchCurrentPlan.rejected, (state, action) => {
        state.fetchingCurrentPlan = false;
        state.fetchingCurrentPlanError = parseServerError(action.payload);
      })
      .addCase(deleteCurrentPlan.pending, (state) => {
        state.deletingPlan = true;
      })
      .addCase(deleteCurrentPlan.fulfilled, (state) => {
        state.deletingPlan = false;
        state.deletingPlanError = null;
      })
      .addCase(deleteCurrentPlan.rejected, (state, action) => {
        state.deletingPlan = false;
        state.deletingPlanError = parseServerError(action.payload);
      })
      .addCase(switchSandboxMode.pending, (state) => {
        state.switching = true;
      })
      .addCase(switchSandboxMode.fulfilled, (state) => {
        state.switching = false;
        state.switchingError = null;
      })
      .addCase(switchSandboxMode.rejected, (state, action) => {
        state.switching = false;
        state.switchingError = parseServerError(action.payload);
      })
      .addCase(deleteSandboxData.pending, (state) => {
        state.deleting = true;
      })
      .addCase(deleteSandboxData.fulfilled, (state) => {
        state.deleting = false;
        state.deletingError = null;
      })
      .addCase(deleteSandboxData.rejected, (state, action) => {
        state.deleting = false;
        state.deletingError = parseServerError(action.payload);
      })
      .addCase(fetchBillingHistory.pending, (state) => {
        state.fetchingBillingHistory = true;
      })
      .addCase(fetchBillingHistory.fulfilled, (state, action) => {
        state.fetchingBillingHistory = false;
        state.billingHistory = action.payload;
      })
      .addCase(fetchBillingHistory.rejected, (state, action) => {
        state.fetchingBillingHistory = false;
        state.fetchingBillingHistoryError = parseServerError(action.payload);
      })
      .addCase(downloadInvoiceFile.pending, (state) => {
        state.downloadingInvFile = true;
      })
      .addCase(downloadInvoiceFile.fulfilled, (state, action) => {
        state.downloadingInvFile = false;
        state.downloadingInvFileError = null;
        state.toDownloadInvFile = action.payload;
      })
      .addCase(downloadInvoiceFile.rejected, (state, action) => {
        state.downloadingInvFile = false;
        state.downloadingInvFileError = parseServerError(action.payload);
      })
      .addCase(switchCalculateThresholds.pending, (state) => {
        state.switchingThresholds = true;
      })
      .addCase(switchCalculateThresholds.fulfilled, (state) => {
        state.switchingThresholds = false;
        state.switchingThresholdsError = null;
      })
      .addCase(switchCalculateThresholds.rejected, (state, action) => {
        state.switchingThresholds = false;
        state.switchingThresholdsError = parseServerError(action.payload);
      })
      .addCase(switchCalculateThresholdsNexus.pending, (state) => {
        state.switchingThresholdsNexus = true;
      })
      .addCase(switchCalculateThresholdsNexus.fulfilled, (state) => {
        state.switchingThresholdsNexus = false;
        state.switchingThresholdsNexusError = null;
      })
      .addCase(switchCalculateThresholdsNexus.rejected, (state, action) => {
        state.switchingThresholdsNexus = false;
        state.switchingThresholdsNexusError = parseServerError(action.payload);
      })
      .addCase(switchCalculateRealTime.pending, (state) => {
        state.switchingRealTime = true;
      })
      .addCase(switchCalculateRealTime.fulfilled, (state) => {
        state.switchingRealTime = false;
        state.switchingRealTimeError = null;
      })
      .addCase(switchCalculateRealTime.rejected, (state, action) => {
        state.switchingRealTime = false;
        state.switchingRealTimeError = parseServerError(action.payload);
      })
      .addCase(fetchCalculateSubscription.pending, (state) => {
        state.fetchingCalc = true;
      })
      .addCase(fetchCalculateSubscription.fulfilled, (state, action) => {
        state.fetchingCalc = false;
        state.fetchingCalcError = null;
        state.calculateSubscription = action.payload;
      })
      .addCase(fetchCalculateSubscription.rejected, (state, action) => {
        state.fetchingCalc = false;
        state.calculateSubscription = null;
        state.fetchingCalcError = parseServerError(action.payload);
      })
      .addCase(fetchCalculateAddons.pending, (state) => {
        state.fetchingCalcAddons = true;
      })
      .addCase(fetchCalculateAddons.fulfilled, (state, action) => {
        state.fetchingCalcAddons = false;
        state.fetchingCalcAddonsError = null;
        state.calculateAddons = action.payload;
      })
      .addCase(fetchCalculateAddons.rejected, (state, action) => {
        state.fetchingCalcAddons = false;
        state.calculateAddons = null;
        state.fetchingCalcAddonsError = parseServerError(action.payload);
      })
      .addCase(fetchCalculateChargeInvoice.pending, (state) => {
        state.fetchingCalcInvoice = true;
      })
      .addCase(fetchCalculateChargeInvoice.fulfilled, (state, action) => {
        state.fetchingCalcInvoice = false;
        state.fetchingCalcInvoiceError = null;
        state.calculateInvoice = action.payload;
      })
      .addCase(fetchCalculateChargeInvoice.rejected, (state, action) => {
        state.fetchingCalcInvoice = false;
        state.calculateInvoice = null;
        state.fetchingCalcInvoiceError = parseServerError(action.payload);
      })
      .addCase(fetchEprSubscriptionPlans.pending, (state) => {
        state.fetchingEprSubscriptionPlans = true;
        state.fetchingEprSubscriptionPlansError = null;
      })
      .addCase(fetchEprSubscriptionPlans.fulfilled, (state, action) => {
        state.eprSubscriptionPlans = action.payload;
        state.fetchingEprSubscriptionPlans = false;
        state.fetchingEprSubscriptionPlansError = null;
      })
      .addCase(fetchEprSubscriptionPlans.rejected, (state, action) => {
        state.fetchingEprSubscriptionPlans = false;
        state.fetchingEprSubscriptionPlansError = parseServerError(
          action.payload
        );
      });
  },
});

export default subscriptionSlice.reducer;
