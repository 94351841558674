const epr = {
  eprReport: "EPR report",
  country: "Country",
  reportType: "Report type",
  year: "Year",
  reportsEPR: "Reports EPR - Informes EPR",
  eprReporting: "EPR reporting",
  fillEPRHere: "Here you can fill your EPR reports",
  fillEPRReport: "Fill EPR report",
  periodStart: "Period start",
  periodEnd: "Period end",
  totalSum: "Total amount",
  addEPRReportToSeeData: "Add EPR report to see the data",
  addEPRReport: "Add EPR report",
  returnParameters: "Return parameters",
  editPackagingData: "Edit packaging data",
  summary: "Summary",
  name: "Name",
  glass: "Glass",
  paperPaperboardCardboard: "Paper/paperboard/cardboard",
  ferrousMetals: "Ferrous metals",
  aluminium: "Aluminium",
  plastic: "Plastic",
  beverageCartonPackaging: "Beverage carton packaging",
  otherCompositesPackaging: "Other Composites packaging",
  otherMaterials: "Other materials",
  paper: "Paper",
  steel: "Steel",
  wood: "Wood",
  numberOfScu: "Number of CSU",
  other: "Other",
  submit: "Submit",
  save: "Save",
  downloadReport: "Download report",
  back: "Back",
  warning: "Warning",
  furnitureEcoparticipations: "Furniture ecoparticipations",
  revenueExcludingVAT: "Revenue excluding VAT",
  code: "Code",
  tarifHT: "Tarif HT",
  tarifTTC: "Tarif TTC",
  codeEcopart: "Code Ecopart",
  number: "Number",
  summ: "Amount",
  category: "Category",
  product: "Product",
  material: "Material",
  feature: "Characteristic",
  addRecord: "Add Record",
  success: "Success",
  edit: "Edit",
  kg: "Kg",
  eprReportSuccessfullySubmitted: "EPR Report was successfully submitted!",
  eprReportSuccessfullyCreated: "EPR Report was successfully created!",
  cancel: "Cancel",
  delete: "Delete",
  areYouSureToDelete: "Are you sure you want to delete this item?",
  period: "Period",
  uploadTransactions: "Upload Transactions",
  submitDisclaimer:
    "By clicking on the Submit button you agree to the following: When you submit this EPR information you are making a legal declaration that the information is true and complete. A false declaration can result in prosecution.",
  transactionsUploadInProgress: "Transactions upload is still in progress!",
  confirmMoveForwardWithUnfinishedUpload:
    "I confirm that I want to move forward with EPR report filing with transactions upload process unfinished.",
  transactionsUploadInProgressMessage:
    "Transactions upload is in progress. It may take from 5 to 30 mins for it to finish depending on the amount of your transactions.",
  noActiveIntegration:
    'No active integration. Please complete an integration in "Stores" section to be able to upload your transactions.',
  yes: "Yes",
  no: "No",
  productTypologies: "Product typologies",
  productTypology: "Product typology",
  editTextileData: "Edit textile data",
  clothing: "Clothing",
  householdLinen: "Household linen",
  footwear: "Footwear",
  selectDeclarationMethod: "Available declaration methods",
  detailedDeclaration: "Detailed declaration",
  fourTypesOfReportingParameters:
    "4 types of reporting parameters for each item",
  volumesDeclaredPerProductLine: "Volumes declared per product line",
  standardScale: "Standard scale",
  simplifiedDeclaration: "Simplified declaration",
  twoStepsDeclaration: "2 steps declaration",
  volumesDeclaredPerProductFamily:
    "Volumes declared per product family (Clothing, Household linen, Footwear)",
  pricePerItemIncreased: "Price per item is increased",
  revenue: "Revenue",
  tax: "Tax",
  fee: "Fee",
  productDescription: "Product description",
  uploadPaymentMemo: "Upload payment memo file",
};

export default epr;
