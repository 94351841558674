import { useState, useCallback } from "react";
import propTypes from "prop-types";
import { Collapsible, Text } from "@shopify/polaris";

import "./index.scss";

const PlusIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 1V15M1 8H15'
      stroke='#2E2E2E'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const MinusIcon = () => (
  <svg
    width='16'
    height='2'
    viewBox='0 0 16 2'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1 1H15'
      stroke='#2E2E2E'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default function Accordion({
  title = "",
  description,
  content = null,
  styles = { header: {}, content: {}, wrapper: {} },
  renderCustomHeader = null,
}) {
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => setOpen((open) => !open), []);

  const Header = () => {
    return (
      <>
        <Text variant='bodyLg'>{title}</Text>
        {open ? <MinusIcon /> : <PlusIcon />}
      </>
    );
  };

  return (
    <div className='accordion' style={styles?.wrapper || {}}>
      <div onClick={handleToggle} className='accordion__wrapper'>
        <div className='accordion__header' style={styles?.header || {}}>
          {renderCustomHeader ? (
            renderCustomHeader(open, handleToggle)
          ) : (
            <Header />
          )}
        </div>
        {description && (
          <div className='accordion__description'>
            <Text variant='bodyMd' color='subdued'>
              {description}
            </Text>
          </div>
        )}
      </div>

      <Collapsible
        open={open}
        id='basic-collapsible'
        transition={{ duration: "300ms", timingFunction: "ease-in-out" }}
        expandOnPrint
      >
        <div className='accordion__content' style={styles?.content || {}}>
          {content}
        </div>
      </Collapsible>
    </div>
  );
}

Accordion.propTypes = {
  title: propTypes.string,
  content: propTypes.node,
};
