import { FormLayout, Select } from "@shopify/polaris";
import React from "react";
import { useSelector } from "react-redux";

const ReasonForExemption = (props) => {
  const { data, setData } = props;
  const { exemptionData } = useSelector((state) => state.documents);
  const reasonsForExemption =
    exemptionData.reasons_for_exemption?.map((reason) => ({
      value: reason,
      label: reason,
    })) ?? [];

  return (
    <FormLayout>
      <FormLayout.Group>
        <Select
          options={reasonsForExemption}
          label='Reason for exemption'
          value={data.reasonForExemption}
          onChange={(reason) =>
            setData((prev) => ({ ...prev, reasonForExemption: reason }))
          }
        />
        <br />
      </FormLayout.Group>
    </FormLayout>
  );
};

export default ReasonForExemption;
