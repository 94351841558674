/*
    The useAppDispatch hook wraps useDispatch
    to provide a custom dispatch function that handles async actions,
    returning the unwrapped result or throwing an error.
*/

// eslint-disable-next-line no-restricted-imports
import { useDispatch } from "react-redux";
import { createCustomDispatch } from "helpers/customDispatch";

const useAppDispatch = () => {
  const dispatch = useDispatch();

  return createCustomDispatch(dispatch);
};

export default useAppDispatch;
