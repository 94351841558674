export const isTaxAgentSelector = (user) => {
  return (
    (user && user.company && user.company.id === user.tax_agent_company_id) ||
    (user.is_manager && user.company.is_tax_agent)
  );
};

export function getIsTaxAgentMainStatus(state) {
  return (
    state.user.user.company &&
    state.user.user.is_tax_agent &&
    state.user.user.company.id === state.user.user.tax_agent_company_id
  );
}

export function isUserTaxAgentOrManager(user) {
  return (
    (user &&
      user.company &&
      user.is_tax_agent &&
      user.company.id === user.tax_agent_company_id) ||
    (user && user.company && user.is_manager && user.company.is_tax_agent)
  );
}

export function getIsManager(state) {
  return state.user.user.is_manager;
}

export function getIsOMP(state) {
  return state.user.user.is_omp;
}
