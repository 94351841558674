import { Card, DataTable, Text } from "@shopify/polaris";
import React from "react";
import { useSelector } from "react-redux";
import formatRow from "utils/formatRow";

const SummaryForTwoSteps = ({stepBar}) => {
	const {
		eprSubcategories: {selectedCategory5, quantityArr, unitsDefault},
	} = useSelector((state) => state.declarations);
	
	// console.log('summary step, selectedCategory5', selectedCategory5)
	// console.log('summary step, quantityArr', quantityArr)
	
	const columns = [
		{
			header: {
				label: "Subcategory 1",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => {
						return selectedCategory5.length === 0 ? rowData.category : rowData[0];
					},
				],
			},
		},
		{
			header: {
				label: "Subcategory 2",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => {
						return <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{selectedCategory5.length === 0 ? rowData.subCategory : rowData[1]}</div>
					},
				],
			},
		},

		{
			header: {
				label: "Quantity",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => {
						return selectedCategory5.length === 0 ? `${rowData?.quantity} ${rowData?.units || unitsDefault}` : `${rowData[5]} ${unitsDefault}`;
					}
				],
			},
		},
	];

	const rows = formatRow(quantityArr, columns);
	return (
		<>
      <Card sectioned>
        <DataTable
	        columnContentTypes={["text", "text", "text", "text", "text", "text"]}
	        headings={columns.map(({header}) => (
		        <Text fontWeight='semibold'>{header.label}</Text >
	        ))}
	        rows={rows}
        />
      </Card >
			
			{stepBar()}
    </>
	);
};

export default SummaryForTwoSteps;
