import React, { Component } from 'react';
import GoogleRecaptcha from 'react-google-recaptcha';

export default class ReCAPTCHA extends Component {
  constructor() {
    super();
    this.state = {
      grecaptcha: null,
    };
  }

  componentDidMount() {
    fetch('https://www.google.com/recaptcha/api.js', () => {
      window.grecaptcha.ready(() =>
        this.setState({ grecaptcha: window.grecaptcha }));
    });
  }


  render() {
    const props = {...this.props, grecaptcha: this.state.grecaptcha};
    return <GoogleRecaptcha {...props}/>;
  }
}
