import _ from 'lodash';

export function parseServerError(serverErr) {
  let error = null;
  try {
    if (_.isObject(serverErr)) {
      error = serverErr.error || serverErr.errors;
    } else {
      const errorObj = JSON.parse(serverErr);
      error = errorObj.errors.__all__[0];
    }
  } catch (err) {
    error = serverErr;
  }
  return error;
}
