import { uniqueId } from "lodash";

export const createEmptyLineItem = () => {
  return { rowIndex: uniqueId(), tax: true };
};

export const calculateSubtotals = (line_items) => {
  return (
    line_items.reduce((acc, { quantity, unit_price }) => {
      return acc + Number(quantity) * Number(unit_price);
    }, 0) || 0
  );
};

export const calculateTotalTaxAmount = (line_items, taxId, taxes) => {
  const currentTax = taxes.find(({ id }) => taxId === String(id));

  if (!currentTax) {
    return 0;
  }

  const taxableAmount =
    line_items.reduce((acc, { quantity, unit_price, tax: isTaxable }) => {
      return isTaxable ? acc + Number(quantity) * Number(unit_price) : acc;
    }, 0) || 0;

  return (taxableAmount * currentTax.rate) / 100;
};

export const buildAddress = (user) => {
  const { postcode, locality, street, housenum, apartment } = user.company.info;
  const { country } = user.company;

  if (
    [postcode, locality, street, housenum, country, apartment].some(
      (component) => component === undefined
    )
  ) {
    return false;
  }

  const address =
    `${postcode} ${country} ${locality} ${street} ${housenum} ${apartment}`.trim();

  return address;
};
