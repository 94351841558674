import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import handleErrorForThunk from "helpers/handleErrorForThunk";
import { parseServerError } from "utils/errors";
import isEqual from '../../../containers/EPRReporting/Subcategories/Step_3/helper'


const initialState = {
  declarations: { items: [] },
  declarationsParams: {},
  declarationsTransactions: [],
  returnPreview: {},
  liabilitiesAndObligations: [],
  liabilities: [],
  obligations: [],
  typesOfReport: [],
  checkedNumbers: {
    correct: [],
    incorrect: [],
    pending: [],
  },
  declarationsSalesTax: { items: [] },
  taxPermits: [],
  filtersEPR: {},
  listEPR: [],
  eprData: [],
  eprDeclaration: {},
  eprCategories: [],
  eprSubcategories: {
    selectedCategory1: [],
    selectedCategory2: [],
    selectedCategory3: [],
    selectedCategory4: [],
    selectedCategory5: [],
    quantityArr: [],
    unitsDefault: ''
  },
  eprFurniture: {
    categories: null,
    products: null,
    materials: null,
    features: null,
  },
  eprCountries: [],
  declarationsEpr: { items: [] },
  dac7: null,
  user_status: [],
  dac7Reports: [],
  dac7ReportsImmovable: [],
  dac7Preview: [],
  relevant_activities: [],
  dac7_files_examples: [],
  furnitureChoice: {},
  statuses: [],
  report1099: null,
  dataVatReturn: {},
  creatingVatReturn: {},
  errorFinishSubscription: ''
};

export const create1099Report = createAsyncThunk(
  "reports/create1099Report",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/reports/1099`, "POST", data);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchEprStatus = createAsyncThunk(
  "reports/fetchEprStatus",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(`/update/status_epr`, "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateEpr = createAsyncThunk(
  "reports/updateEpr",
  async ({ period, period_type, year }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/update_epr_by_date?period=${period}&period_type=${period_type}&year=${year}`,
        "POST"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteEPRReport = createAsyncThunk(
  "reports/deleteEPRReport",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/returns/declarations/EPR_report?report_id=${id}`,
        "DELETE"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const uploadMerchantsFile = createAsyncThunk(
  "reports/uploadMerchantsFile",
  async (file, { rejectWithValue }) => {
    try {
      const response = await callApi("/reports/upload_merchant", "POST", file, {
        contentType: "multipart/form-data",
      });
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const addMerchantDac7 = createAsyncThunk(
  "reports/addMerchantDac7",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/reports/merchant`, "POST", data);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const submitDac7Report = createAsyncThunk(
  "reports/submitDac7Report",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(`/reports/dac_7/submit?id=${id}`, "PUT");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getDac7Preview = createAsyncThunk(
  "reports/getDac7Preview",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(`/reports/dac_7/view?id=${id}`, "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const changeDac7Status = createAsyncThunk(
  "reports/changeDac7Status",
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/reports/dac_7/status?id=${id}&status=${status}`,
        "PUT"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteDac7Report = createAsyncThunk(
  "reports/deleteDac7Report",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(`/reports/dac_7?id=${id}`, "DELETE");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const downloadDac7File = createAsyncThunk(
  "reports/downloadDac7File",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/reports/dac_7/download?id=${id}`,
        "GET",
        {},
        {
          responseType: "blob",
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      return { link: url };
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const sendDac7Report = createAsyncThunk(
  "reports/sendDac7Report",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/reports/dac_7`, "POST", data);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchDac7Reports = createAsyncThunk(
  "reports/fetchDac7Reports",
  async (data = { is_immovable: false }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/reports/dac_7?is_immovable=${data.is_immovable}`,
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const editMerchantInfo = createAsyncThunk(
  "reports/editMerchantInfo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/reports/merchant_info`, "PUT", data);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createDac7Report = createAsyncThunk(
  "reports/createDac7Report",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/reports/dac_7`, "POST", data);
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText ?? "Unknown error occurred");
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const downloadEprFile = createAsyncThunk(
  "reports/downloadEprFile",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/epr_reporting/epr_report_download/${id}`,
        "GET",
        {},
        {
          responseType: "blob",
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      return { link: url };
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const submitEprReport = createAsyncThunk(
  "reports/submitEprReport",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(`/epr_reporting/send_epr_report`, "POST", {
        id,
      });
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchPreviewEpr = createAsyncThunk(
  "reports/fetchPreviewEpr",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/epr_reporting/preview/${id}`,
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchEprDeclarations = createAsyncThunk(
  "epr/fetchEprDeclarations",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi(`/epr_reporting`, "GET", params);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchEprCountries = createAsyncThunk(
  "epr/fetchEprCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(`/subscription/epr_countries`, "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const editTransaction = createAsyncThunk(
  "epr/editTransaction",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/returns/declarations/edit_transaction`,
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchEprFurnitureChoice = createAsyncThunk(
  "epr/fetchEprFurnitureChoice",
  async (code, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/epr_calc/get_epr_furniture_choice?code=${code}`,
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchEprFurnitureFeatures = createAsyncThunk(
  "epr/fetchEprFurnitureFeatures",
  async (
    { country_code, category_code, product_code, material_code },
    { rejectWithValue }
  ) => {
    try {
      const response = await callApi(
        `/epr_calc/get_epr_furniture_features?category_code=${category_code}&product_code=${product_code}&matereal_code=${material_code}&country_code=${country_code}`,
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchEprFurnitureMaterials = createAsyncThunk(
  "epr/fetchEprFurnitureMaterials",
  async (
    { country_code, category_code, product_code },
    { rejectWithValue }
  ) => {
    try {
      const response = await callApi(
        `/epr_calc/get_epr_furniture_matereals?category_code=${category_code}&product_code=${product_code}&country_code=${country_code}`,
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchEprFurnitureProducts = createAsyncThunk(
  "epr/fetchEprFurnitureProducts",
  async ({ country_code, category_code }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/epr_calc/get_epr_furniture_products?category_code=${category_code}&country_code=${country_code}`,
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchEprFurnitureCategories = createAsyncThunk(
  "epr/fetchEprFurnitureCategories",
  async (country_code, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/epr_calc/get_epr_furniture_categories?country_code=${country_code}`,
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const downloadMemoFile = createAsyncThunk(
  "epr/downloadMemoFile",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/returns/declarations/download_memo/${id}`,
        "GET",
        {},
        {
          responseType: "blob",
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      return { link: url };
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchSalesTaxPermits = createAsyncThunk(
  "epr/fetchSalesTaxPermits",
  async (active = false, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/sales_tax_permit?is_active=${active}`,
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const createDeclarationSalesTax = createAsyncThunk(
  "epr/createDeclarationSalesTax",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/returns/sales_tax_returns`,
        "POST",
        params
      );
      if (!response.ok) {
        const json = await response.json();
        
        if (response.status === 403) {
          return rejectWithValue(json.error);
        } else {
          throw new Error(json.error || 'Unknown error');
        }
      }
      
      return await response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getSalesTaxPreview = createAsyncThunk(
  "salesTax/getSalesTaxPreview",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/returns/sales_tax_returns/preview_sales_tax_return?sales_tax_return_id=${id}`,
        "POST"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchDeclarationsSalesTax = createAsyncThunk(
  "salesTax/fetchDeclarationsSalesTax",
  async (params = { limit: 25, offset: 0 }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/returns/sales_tax_returns",
        "GET",
        params
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const submitSalesTax = createAsyncThunk(
  "salesTax/submitSalesTax",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/returns/sales_tax_returns/send",
        "POST",
        { id }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const downloadSchedulesFile = createAsyncThunk(
  "salesTax/downloadSchedulesFile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/returns/sales_tax_returns/download_schedules`,
        "POST",
        data,
        {
          responseType: "blob",
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      return { link: url };
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteSalesTax = createAsyncThunk(
  "salesTax/deleteSalesTax",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/returns/sales_tax_returns/delete_sales_tax_return?sales_tax_return_id=${id}`,
        "DELETE"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const downloadDeclarationFile = createAsyncThunk(
  "salesTax/downloadDeclarationFile",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/returns/declarations/download/${id}`,
        "GET",
        {},
        {
          responseType: "blob",
        }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      return { link: url };
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createEprFurnitureEcoCode = createAsyncThunk(
  "epr/createEprFurnitureEcoCode",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/epr_reporting/post_epr_furniture_eco_code`,
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const checkVatNumbers = createAsyncThunk(
  "epr/checkVatNumbers",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/returns/declarations/check_vat_number`,
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchTypesOfReport = createAsyncThunk(
  "declarations/fetchTypesOfReport",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/returns/declarations/types_of_report",
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createDeclaration = createAsyncThunk(
  "declarations/createDeclaration",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi(`/returns/declarations`, "POST", params);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json()
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createEprDeclaration = createAsyncThunk(
  "epr/createEprDeclaration",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/epr_reporting/create_epr_declaration`,
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteDeclaration = createAsyncThunk(
  "declarations/deleteDeclaration",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/returns/declarations?declaration_id=${id}`,
        "DELETE"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getReturnPreview = createAsyncThunk(
  "declarations/getReturnPreview",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/returns/declarations/preview/${id}`,
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const submitDeclaration = createAsyncThunk(
  "declarations/submitDeclaration",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/returns/declarations/send_declaration`,
        "POST",
        data
      );
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText ?? "Unknown error occurred");
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchListEPR = createAsyncThunk(
  "epr/fetchListEPR",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/epr_settings?is_active=false", "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchFiltersEPR = createAsyncThunk(
  "epr/fetchFiltersEPR",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/subscription/epr_categories", "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createSystemOperator = createAsyncThunk(
  "operators/createSystemOperator",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/returns/declarations/epr_operator",
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getObligations = createAsyncThunk(
  "obligations/getObligations",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/returns/declarations/obligations",
        "POST",
        { ...data }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getLiabilities = createAsyncThunk(
  "liabilities/getLiabilities",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/returns/declarations/liabilities",
        "POST",
        { ...data }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchDeclarations = createAsyncThunk(
  "declarations/fetchDeclarations",
  async (params = { limit: 25, offset: 0 }, { rejectWithValue }) => {
    try {
      const response = await callApi("/returns/declarations", "GET", params);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createEPRSettings = createAsyncThunk(
  "epr/createEPRSettings",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/epr_settings/add", "POST", data);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchEPRCategories = createAsyncThunk(
  "epr/fetchEPRCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/subscription/epr_categories", "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchEPROperators = createAsyncThunk(
  "epr/fetchEPROperators",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/epr_settings/epr_operators", "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const sendEprPaymentMemo = createAsyncThunk(
  "epr/sendEprPaymentMemo",
  async ({ id, file }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/manager/epr_reports/add_payment_memo?id=${id}`,
        "POST",
        file,
        {
          contentType: "multipart/form-data",
        }
      );

      if (!response.ok) {
        throw new Error(response.status);
      }

      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getDeclarationTransaction = createAsyncThunk(
  "epr/getDeclarationTransaction",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/returns/declarations/transaction",
        "GET",
        data
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);




const declarationsSlice = createSlice({
  name: "declarations",
  initialState,
  reducers: {
    selectCategory1: (state, action) => {
      const { value, checked } = action.payload;
      if (checked) {
        state.eprSubcategories.selectedCategory1.push(value);
      } else {
        state.eprSubcategories.selectedCategory1 =
          state.eprSubcategories.selectedCategory1.filter(
            (item) => item !== value
          );
      }
    },
    selectCategory2: (state, action) => {
      const { checked, category, subCategory } = action.payload;

      if (checked) {
        state.eprSubcategories.selectedCategory2.push([category, subCategory]);
      } else {
        const predicateCb = (item) => item[1] !== subCategory;

        state.eprSubcategories.selectedCategory2 =
          state.eprSubcategories.selectedCategory2.filter(predicateCb);
      }
    },
    
    selectCategory3: (state, action) => {
      const { checked, category, subCategory, subSubCategory } = action.payload;
      
      const newItem = [category, subCategory, subSubCategory];
      
      if (checked) {
        const exists = state.eprSubcategories.selectedCategory3.some(item =>
          item[0] === category &&
          item[1] === subCategory &&
          isEqual(item[2], subSubCategory)
        );
        
        if (!exists) {
          state.eprSubcategories.selectedCategory3.push(newItem);
        }
      } else {
        state.eprSubcategories.selectedCategory3 = state.eprSubcategories.selectedCategory3.filter(item =>
          !(item[0] === category && item[1] === subCategory && isEqual(item[2], subSubCategory))
        );
      }
    },
    
    selectCategory4: (state, action) => {
      const {
        checked,
        category,
        subCategory,
        subSubCategory,
        subSubSubCategory,
      } = action.payload;
      
      const newItem = [category, subCategory, subSubCategory, subSubSubCategory];
      
      if (checked) {
        const exists = state.eprSubcategories.selectedCategory4.some(item =>
          item[0] === category &&
          item[1] === subCategory &&
          isEqual(item[2], subSubCategory) &&
          isEqual(item[3], subSubSubCategory)
        );
        
        if (!exists) {
          state.eprSubcategories.selectedCategory4.push(newItem);
        }
      } else {
        state.eprSubcategories.selectedCategory4 = state.eprSubcategories.selectedCategory4.filter(item =>
          !(item[0] === category &&
            item[1] === subCategory &&
            isEqual(item[2], subSubCategory) &&
            isEqual(item[3], subSubSubCategory))
        );
      }
    },
    
    selectCategory5: (state, action) => {
      const {
        checked,
        category,
        subCategory,
        subSubCategory,
        subSubSubCategory,
        subSubSubSubCategory,
      } = action.payload;
   
      const newItem = [category, subCategory, subSubCategory, subSubSubCategory, subSubSubSubCategory];
      
      if (checked) {
        const exists = state.eprSubcategories.selectedCategory5.some(item =>
          item[0] === category &&
          item[1] === subCategory &&
          isEqual(item[2], subSubCategory) &&
          isEqual(item[3], subSubSubCategory) &&
          item[4] === subSubSubSubCategory
        );
        
        if (!exists) {
          state.eprSubcategories.selectedCategory5.push(newItem);
        }
      } else {
        state.eprSubcategories.selectedCategory5 = state.eprSubcategories.selectedCategory5.filter(item =>
          !(item[0] === category &&
            item[1] === subCategory &&
            isEqual(item[2], subSubCategory) &&
            isEqual(item[3], subSubSubCategory) &&
            item[4] === subSubSubSubCategory)
        );
      }
    },
    
    setQuantity: (state, action) => {
      const { quantity, category, subCategory, subSubCategory, subSubSubCategory, recyclateOfPrimary } = action.payload;
      
      const newObj = {
        id: state.eprSubcategories.quantityArr.length + 1,
        quantity: quantity,
        category: category,
        subCategory: subCategory,
        subSubCategory: subSubCategory,
        subSubSubCategory: subSubSubCategory,
        recyclateOfPrimary: recyclateOfPrimary
      };
      
      const foundIndexEEE = state.eprSubcategories.quantityArr.findIndex(item =>
        item.category === category &&
        item.subCategory === subCategory &&
        (subSubCategory?.name ? (subSubCategory?.name === item.subSubCategory?.name &&
          subSubCategory?.units === item.subSubCategory?.units)  : subSubCategory === item.subSubCategory) &&
        (subSubSubCategory?.name ? (subSubSubCategory?.name === item.subSubSubCategory?.name &&
          subSubSubCategory?.units === item.subSubSubCategory?.units)  : subSubSubCategory === item.subSubSubCategory)
      );
      
      const foundIndexAll = state.eprSubcategories.selectedCategory5.findIndex(item =>
        item[0] === category &&
        item[1] === subCategory &&
        item[2] === subSubCategory &&
        item[3].name === subSubSubCategory.name &&
        item[3].units === subSubSubCategory.units &&
        item[4] === recyclateOfPrimary
      );
      
      const trueFoundIndex = recyclateOfPrimary ? foundIndexAll : foundIndexEEE
      // console.log('trueFoundIndex', trueFoundIndex)
      if (trueFoundIndex !== -1) {
        recyclateOfPrimary
          ?
          state.eprSubcategories.selectedCategory5[trueFoundIndex][5] = quantity
          :
          state.eprSubcategories.quantityArr[trueFoundIndex].quantity = quantity
      } else {
        !recyclateOfPrimary &&
          state.eprSubcategories.quantityArr.push(newObj)
      }
    },
    
    setQuantityForTwoSteps(state, action) {
      const { quantity, category, subCategory } = action.payload;
      
      const newObj = {
        id: state.eprSubcategories.quantityArr.length + 1,
        quantity: quantity,
        category: category,
        subCategory: subCategory,
      };
      
      const foundIndexEEE = state.eprSubcategories.quantityArr.findIndex(item =>
        item.category === category &&
        item.subCategory === subCategory
      );
      
      const foundIndexAll = state.eprSubcategories.selectedCategory5.findIndex(item =>
        item[0] === category &&
        item[1] === subCategory
      );
      
      const trueFoundIndex = foundIndexEEE
      // const trueFoundIndex = recyclateOfPrimary ? foundIndexAll : foundIndexEEE
      // console.log('trueFoundIndex', trueFoundIndex)
      if (trueFoundIndex !== -1) {
          state.eprSubcategories.quantityArr[trueFoundIndex].quantity = quantity
      } else {
        state.eprSubcategories.quantityArr.push(newObj)
      }
    },
    
    setVatReturnsData: (state, action) => {
      state.dataVatReturn = action.payload;
    },
    setResetData: (state, action) => {
      state.eprSubcategories.quantityArr = action.payload.quantityArr
      state.eprSubcategories.selectedCategory5 = action.payload.selectedCategory5
      state.eprSubcategories.selectedCategory4 = action.payload.selectedCategory4
      state.eprSubcategories.selectedCategory3 = action.payload.selectedCategory3
      state.eprSubcategories.selectedCategory2 = action.payload.selectedCategory2
      state.eprSubcategories.selectedCategory1 = action.payload.selectedCategory1
    },
    setResetEprDeclaration: (state, action) => {
      state.eprDeclaration = action.payload
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchDeclarations.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchDeclarations.fulfilled, (state, action) => {
        state.fetching = false;
        state.fetchingError = null;
        state.declarations = { ...action.payload };
      })
      .addCase(fetchDeclarations.rejected, (state, action) => {
        state.fetching = false;
        state.fetchingError = parseServerError(action.error);
      })
      .addCase(downloadDeclarationFile.pending, (state) => {
        state.downloading = true;
      })
      .addCase(downloadDeclarationFile.fulfilled, (state, action) => {
        state.downloading = false;
        state.downloadingError = null;
        state.toDownload = { ...action.payload };
      })
      .addCase(downloadDeclarationFile.rejected, (state, action) => {
        state.downloading = false;
        state.downloadingError = parseServerError(action.error);
      })
      .addCase(downloadMemoFile.pending, (state) => {
        state.downloadingMemo = true;
      })
      .addCase(downloadMemoFile.fulfilled, (state, action) => {
        state.downloadingMemo = false;
        state.downloadingMemoError = null;
        state.toDownloadMemo = { ...action.payload };
      })
      .addCase(downloadMemoFile.rejected, (state, action) => {
        state.downloadingMemo = false;
        state.downloadingMemoError = parseServerError(action.error);
      })
      .addCase(createDeclaration.pending, (state) => {
        state.creating = true;
      })
      .addCase(createDeclaration.fulfilled, (state, action) => {
        state.creating = false;
        state.creatingError = null;
        state.creatingVatReturn = action.payload
      })
      .addCase(createDeclaration.rejected, (state, action) => {
        state.creating = false;
        state.creatingError = parseServerError(action.error);
      })
      .addCase(submitDeclaration.pending, (state) => {
        state.submitting = true;
      })
      .addCase(submitDeclaration.fulfilled, (state) => {
        state.submitting = false;
        state.submittingError = null;
      })
      .addCase(submitDeclaration.rejected, (state, action) => {
        state.submitting = false;
        state.submittingError = parseServerError(action.payload.error);
      })
      .addCase(getReturnPreview.pending, (state) => {
        state.getting = true;
      })
      .addCase(getReturnPreview.fulfilled, (state, action) => {
        state.getting = false;
        state.gettingError = null;
        state.returnPreview = { ...action.payload };
      })
      .addCase(getReturnPreview.rejected, (state, action) => {
        state.getting = false;
        state.gettingError = parseServerError(action.error);
      })
      .addCase(getLiabilities.pending, (state) => {
        state.ordering = true;
      })
      .addCase(getLiabilities.fulfilled, (state, action) => {
        state.ordering = false;
        state.orderingError = null;
        state.liabilities = { ...action.payload };
      })
      .addCase(getLiabilities.rejected, (state, action) => {
        state.ordering = false;
        state.orderingError = parseServerError(action.error);
      })
      .addCase(getObligations.pending, (state) => {
        state.ordering = true;
      })
      .addCase(getObligations.fulfilled, (state, action) => {
        state.ordering = false;
        state.orderingError = null;
        state.liabilitiesAndObligations = { ...action.payload };
      })
      .addCase(getObligations.rejected, (state, action) => {
        state.ordering = false;
        state.orderingError = parseServerError(action.error);
      })
      .addCase(fetchTypesOfReport.pending, (state) => {
        state.fetchingTypes = true;
      })
      .addCase(fetchTypesOfReport.fulfilled, (state, action) => {
        state.fetchingTypes = false;
        state.fetchingTypesError = null;
        state.typesOfReport = action.payload;
      })
      .addCase(fetchTypesOfReport.rejected, (state, action) => {
        state.fetchingTypes = false;
        state.fetchingTypesError = parseServerError(action.error);
      })
      .addCase(deleteDeclaration.pending, (state) => {
        state.deleting = true;
        state.deletingError = null;
      })
      .addCase(deleteDeclaration.fulfilled, (state) => {
        state.deleting = false;
        state.deletingError = null;
      })
      .addCase(deleteDeclaration.rejected, (state, action) => {
        state.deleting = false;
        state.deletingError = parseServerError(action.error);
      })
      .addCase(checkVatNumbers.pending, (state) => {
        state.checking = true;
      })
      .addCase(checkVatNumbers.fulfilled, (state, action) => {
        state.checking = false;
        state.checkingError = null;
        state.checkedNumbers = { ...action.payload };
      })
      .addCase(checkVatNumbers.rejected, (state, action) => {
        state.checking = false;
        state.checkingError = parseServerError(action.error);
      })
      .addCase(fetchDeclarationsSalesTax.pending, (state) => {
        state.fetchingSalesTax = true;
      })
      .addCase(fetchDeclarationsSalesTax.fulfilled, (state, action) => {
        state.fetchingSalesTax = false;
        state.fetchingSalesTaxError = null;
        state.declarationsSalesTax = { ...action.payload };
      })
      .addCase(fetchDeclarationsSalesTax.rejected, (state, action) => {
        state.fetchingSalesTax = false;
        state.fetchingSalesTaxError = parseServerError(action.error);
      })
      .addCase(createDeclarationSalesTax.pending, (state) => {
        state.creatingSalesTax = true;
      })
      .addCase(createDeclarationSalesTax.fulfilled, (state, action) => {
        state.creatingSalesTax = false;
        state.creatingSalesTaxError = null;
      })
      .addCase(createDeclarationSalesTax.rejected, (state, action) => {
        state.creatingSalesTax = false;
        const serverError = action.error || 'Subscription period not active.'
        // state.creatingSalesTaxError = parseServerError(`Subscription period not active.`);
        state.creatingSalesTaxError = parseServerError(serverError);
      })
      .addCase(deleteSalesTax.pending, (state) => {
        state.deletingTax = true;
        state.deletingTaxError = null;
      })
      .addCase(deleteSalesTax.fulfilled, (state) => {
        state.deletingTax = false;
        state.deletingTaxError = null;
      })
      .addCase(deleteSalesTax.rejected, (state, action) => {
        state.deletingTax = false;
        state.deletingTaxError = parseServerError(action.error);
      })
      .addCase(downloadSchedulesFile.pending, (state) => {
        state.downloadingSched = true;
      })
      .addCase(downloadSchedulesFile.fulfilled, (state, action) => {
        state.downloadingSched = false;
        state.downloadingSchedError = null;
        state.toDownloadSched = { ...action.payload };
      })
      .addCase(downloadSchedulesFile.rejected, (state, action) => {
        state.downloadingSched = false;
        state.downloadingSchedError = parseServerError(action.error);
      })
      .addCase(getSalesTaxPreview.pending, (state) => {
        state.gettingSalesTax = true;
      })
      .addCase(getSalesTaxPreview.fulfilled, (state, action) => {
        state.gettingSalesTax = false;
        state.gettingSalesTaxError = null;
        state.returnSalesTaxPreview = { ...action.payload };
      })
      .addCase(getSalesTaxPreview.rejected, (state, action) => {
        state.gettingSalesTax = false;
        state.gettingSalesTaxError = parseServerError(action.error);
      })
      .addCase(fetchSalesTaxPermits.pending, (state) => {
        state.fetchingTaxPermits = true;
      })
      .addCase(fetchSalesTaxPermits.fulfilled, (state, action) => {
        state.fetchingTaxPermits = false;
        state.fetchingTaxPermitsError = null;
        state.taxPermits = action.payload;
      })
      .addCase(fetchSalesTaxPermits.rejected, (state, action) => {
        state.fetchingTaxPermits = false;
        state.fetchingTaxPermitsError = parseServerError(action.error);
        state.taxPermits = [];
      })
      .addCase(submitSalesTax.pending, (state) => {
        state.submitingSalesTax = true;
      })
      .addCase(submitSalesTax.fulfilled, (state, action) => {
        state.submitingSalesTax = false;
        state.submitingSalesTaxError = null;
        state.submittedSalesTax = { ...action.payload };
      })
      .addCase(submitSalesTax.rejected, (state, action) => {
        state.submitingSalesTax = false;
        state.submitingSalesTaxError = parseServerError(action.error);
      })
      .addCase(createSystemOperator.pending, (state) => {
        state.creatingOperator = true;
      })
      .addCase(createSystemOperator.fulfilled, (state) => {
        state.creatingOperator = false;
        state.creatingOperatorError = null;
      })
      .addCase(createSystemOperator.rejected, (state, action) => {
        state.creatingOperator = false;
        state.creatingOperatorError = parseServerError(action.error);
      })
      .addCase(createEPRSettings.pending, (state) => {
        state.creatingEPRSettings = true;
      })
      .addCase(createEPRSettings.fulfilled, (state) => {
        state.creatingEPRSettings = false;
        state.creatingEPRSettingsError = null;
      })
      .addCase(createEPRSettings.rejected, (state, action) => {
        state.creatingEPRSettings = false;
        state.creatingEPRSettingsError = parseServerError(action.error);
      })
      .addCase(fetchListEPR.pending, (state) => {
        state.fetchingListEPR = true;
      })
      .addCase(fetchListEPR.fulfilled, (state, action) => {
        state.fetchingListEPR = false;
        state.fetchingListEPRError = null;
        state.listEPR = action.payload;
      })
      .addCase(fetchListEPR.rejected, (state, action) => {
        state.fetchingListEPR = false;
        state.fetchingListEPRError = parseServerError(action.error);
      })
      .addCase(fetchFiltersEPR.pending, (state) => {
        state.fetchingFiltersEPR = true;
      })
      .addCase(fetchFiltersEPR.fulfilled, (state, action) => {
        state.fetchingFiltersEPR = false;
        state.fetchingFiltersEPRError = null;
        state.filtersEPR = action.payload;
      })
      .addCase(fetchFiltersEPR.rejected, (state, action) => {
        state.fetchingFiltersEPR = false;
        state.fetchingFiltersEPRError = parseServerError(action.error);
      })
      .addCase(fetchEPRCategories.pending, (state) => {
        state.fetchingEPRParams = true;
      })
      .addCase(fetchEPRCategories.fulfilled, (state, action) => {
        state.fetchingEPRParams = false;
        state.fetchingEPRParamsError = null;
        state.eprCategories = action.payload;
      })
      .addCase(fetchEPRCategories.rejected, (state, action) => {
        state.fetchingEPRParams = false;
        state.fetchingEPRParamsError = parseServerError(action.error);
      })
      .addCase(fetchEPROperators.pending, (state) => {
        state.fetchingEPRParams = true;
      })
      .addCase(fetchEPROperators.fulfilled, (state, action) => {
        state.fetchingEPRParams = false;
        state.fetchingEPRParamsError = null;
        state.eprOperators = action.payload;
      })
      .addCase(fetchEPROperators.rejected, (state, action) => {
        state.fetchingEPRParams = false;
        state.fetchingEPRParamsError = parseServerError(action.error);
      })
      .addCase(createEprDeclaration.pending, (state) => {
        state.fetchingEPRParams = true;
      })
      .addCase(createEprDeclaration.fulfilled, (state, action) => {
        state.fetchingEPRParams = false;
        state.fetchingEPRParamsError = null;
        state.eprDeclaration = action.payload;
        if (action.payload.subcategories_data?.units) state.eprSubcategories.unitsDefault = action.payload.subcategories_data?.units
      })
      .addCase(createEprDeclaration.rejected, (state, action) => {
        state.fetchingEPRParams = false;
        state.fetchingEPRParamsError = parseServerError(action.error);
      })
      .addCase(fetchEprFurnitureCategories.pending, (state) => {
        state.fetchingEprFurnitureCategories = true;
      })
      .addCase(fetchEprFurnitureCategories.fulfilled, (state, action) => {
        state.fetchingEprFurnitureCategories = false;
        state.eprFurniture.categories = action.payload;
      })
      .addCase(fetchEprFurnitureCategories.rejected, (state) => {
        state.fetchingEprFurnitureCategories = false;
      })
      .addCase(fetchEprFurnitureProducts.pending, (state) => {
        state.fetchingEprFurnitureProducts = true;
      })
      .addCase(fetchEprFurnitureProducts.fulfilled, (state, action) => {
        state.fetchingEprFurnitureProducts = false;
        state.eprFurniture.products = action.payload;
      })
      .addCase(fetchEprFurnitureProducts.rejected, (state) => {
        state.fetchingEprFurnitureProducts = false;
      })
      .addCase(fetchEprFurnitureMaterials.pending, (state) => {
        state.fetchingEprFurnitureMaterials = true;
      })
      .addCase(fetchEprFurnitureMaterials.fulfilled, (state, action) => {
        state.fetchingEprFurnitureMaterials = false;
        state.eprFurniture.materials = action.payload;
      })
      .addCase(fetchEprFurnitureMaterials.rejected, (state) => {
        state.fetchingEprFurnitureMaterials = false;
      })
      .addCase(fetchEprFurnitureFeatures.pending, (state) => {
        state.fetchingEprFurnitureFeatures = true;
      })
      .addCase(fetchEprFurnitureFeatures.fulfilled, (state, action) => {
        state.fetchingEprFurnitureFeatures = false;
        state.eprFurniture.features = action.payload;
      })
      .addCase(fetchEprFurnitureFeatures.rejected, (state) => {
        state.fetchingEprFurnitureFeatures = false;
      })
      .addCase(createEprFurnitureEcoCode.pending, (state) => {
        state.creatingEprFurnitureEcoCode = true;
      })
      .addCase(createEprFurnitureEcoCode.fulfilled, (state) => {
        state.creatingEprFurnitureEcoCode = false;
        state.eprFurniture = {
          categories: null,
          products: null,
          materials: null,
          features: null,
        };
      })
      .addCase(createEprFurnitureEcoCode.rejected, (state, action) => {
        state.creatingEprFurnitureEcoCode = false;
        state.creatingEprFurnitureEcoCodeError = parseServerError(action.error);
      })
      .addCase(editTransaction.pending, (state) => {
        state.isEditingTransaction = true;
      })
      .addCase(editTransaction.fulfilled, (state) => {
        state.isEditingTransaction = false;
      })
      .addCase(editTransaction.rejected, (state) => {
        state.isEditingTransaction = false;
        state.editingTransactionError = true;
      })
      .addCase(fetchEprCountries.pending, (state) => {
        state.isFetchingEprCounties = true;
      })
      .addCase(fetchEprCountries.fulfilled, (state, action) => {
        state.eprCountries = action.payload;
        state.isFetchingEprCounties = false;
      })
      .addCase(fetchEprCountries.rejected, (state, action) => {
        state.isFetchingEprCounties = false;
        state.fetchingEprCountriesError = parseServerError(action.error);
      })
      .addCase(fetchEprDeclarations.pending, (state) => {
        state.fetchingEprDeclarations = true;
      })
      .addCase(fetchEprDeclarations.fulfilled, (state, action) => {
        state.fetchingEprDeclarations = false;
        state.declarationsEpr = { ...action.payload };
      })
      .addCase(fetchEprDeclarations.rejected, (state, action) => {
        state.fetchingEprDeclarations = false;
        state.fetchingEprDeclarationsError = parseServerError(action.error);
      })
      .addCase(downloadEprFile.pending, (state) => {
        state.downloadingEprFile = true;
      })
      .addCase(downloadEprFile.fulfilled, (state) => {
        state.downloadingEprFile = false;
      })
      .addCase(downloadEprFile.rejected, (state, action) => {
        state.downloadingEprFile = false;
        state.downloadingEprFileError = parseServerError(action.error);
      })
      .addCase(submitEprReport.pending, (state) => {
        state.submittingEprReport = true;
      })
      .addCase(submitEprReport.fulfilled, (state) => {
        state.submittingEprReport = false;
      })
      .addCase(submitEprReport.rejected, (state, action) => {
        state.submittingEprReport = false;
        state.submittingEprReportError = parseServerError(action.error);
      })
      .addCase(createDac7Report.pending, (state) => {
        state.creatingDac7Report = true;
      })
      .addCase(createDac7Report.fulfilled, (state, action) => {
        state.creatingDac7Report = false;
        state.dac7 = action.payload.merchants || [];
        state.business_name = action.payload.business_name;
        state.period = action.payload.period;
        state.dac7_summary = action.payload.data;
        state.user_status = action.payload.user_status ?? [];
        state.property_types = action.payload.property_types ?? [];
        state.relevant_activities = action.payload.relevant_activities ?? [];
        state.dac7_files_examples = action.payload.files;
      })
      .addCase(createDac7Report.rejected, (state, action) => {
        state.creatingDac7Report = false;
        state.creatingDac7ReportError = parseServerError(action.error);
      })
      .addCase(sendDac7Report.pending, (state) => {
        state.sendDac7Report = true;
      })
      .addCase(sendDac7Report.fulfilled, (state) => {
        state.sendDac7Report = false;
      })
      .addCase(sendDac7Report.rejected, (state, action) => {
        state.sendDac7Report = false;
        state.sendDac7ReportError = parseServerError(action.error);
      })
      .addCase(editMerchantInfo.pending, (state) => {
        state.editingMerchantInfo = true;
      })
      .addCase(editMerchantInfo.fulfilled, (state, action) => {
        state.editingMerchantInfo = false;
        state.dac7 = state.dac7.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
      })
      .addCase(editMerchantInfo.rejected, (state, action) => {
        state.editingMerchantInfo = false;
        state.editingMerchantInfoError = parseServerError(action.error);
      })
      .addCase(fetchDac7Reports.pending, (state) => {
        state.fetchingDac7Reports = true;
      })
      .addCase(fetchDac7Reports.fulfilled, (state, action) => {
        state.fetchingDac7Reports = false;
        if (action.payload.is_immovable) {
          state.dac7ReportsImmovable = action.payload.items;
        } else {
          state.dac7Reports = action.payload.items;
        }
      })
      .addCase(fetchDac7Reports.rejected, (state, action) => {
        state.fetchingDac7Reports = false;
        state.fetchingDac7ReportsError = parseServerError(action.error);
      })
      .addCase(create1099Report.pending, (state) => {
        state.creating1099Report = true;
        state.creating1099ReportError = null;
      })
      .addCase(create1099Report.fulfilled, (state, action) => {
        state.creating1099Report = false;
        state.creating1099ReportError = null;
        state.report1099 = action.payload;
      })
      .addCase(create1099Report.rejected, (state, action) => {
        state.creating1099Report = false;
        state.creating1099ReportError = parseServerError(action.error);
      })
      .addCase(addMerchantDac7.pending, (state) => {
        state.addingMerchant = true;
        state.addingMerchantError = null;
      })
      .addCase(addMerchantDac7.fulfilled, (state, action) => {
        state.addingMerchant = false;
        state.addingMerchantError = null;
        state.dac7 = [...state.dac7, action.payload];
      })
      .addCase(addMerchantDac7.rejected, (state, action) => {
        state.addingMerchant = false;
        state.addingMerchantError = parseServerError(action.error);
      })
      .addCase(getDac7Preview.pending, (state) => {
        state.loadingDac7Preview = true;
      })
      .addCase(getDac7Preview.fulfilled, (state, action) => {
        state.loadingDac7Preview = false;
        state.dac7Preview = action.payload;
      })
      .addCase(getDac7Preview.rejected, (state, action) => {
        state.loadingDac7Preview = false;
        state.dac7PreviewError = parseServerError(action.error);
      })
      .addCase(sendEprPaymentMemo.pending, (state) => {
        state.uploadingPaymentMemo = true;
        state.uploadingPaymentMemoError = null;
      })
      .addCase(sendEprPaymentMemo.fulfilled, (state) => {
        state.uploadingPaymentMemo = false;
        state.uploadingPaymentMemoError = null;
      })
      .addCase(sendEprPaymentMemo.rejected, (state, action) => {
        state.uploadingPaymentMemo = false;
        state.uploadingPaymentMemoError = parseServerError(action.error);
      })
      .addCase(getDeclarationTransaction.pending, (state) => {
        state.creatingEPRSettings = true;
      })
      .addCase(getDeclarationTransaction.fulfilled, (state, action) => {
        state.creatingEPRSettings = false;
        state.creatingEPRSettingsError = null;
        state.declarationsTransactions = { ...action.payload }
      })
      .addCase(getDeclarationTransaction.rejected, (state, action) => {
        state.creatingEPRSettings = false;
        state.creatingEPRSettingsError = parseServerError(action.error);
      })
      .addCase(fetchEprStatus.pending, (state) => {
        state.fetchEprStatus = true;
      })
      .addCase(fetchEprStatus.fulfilled, (state, action) => {
        state.fetchEprStatus = false;
        state.fetchEprStatusError = null;
        state.statuses = action.payload
      })
      .addCase(fetchEprStatus.rejected, (state, action) => {
        state.fetchEprStatus = false;
        state.fetchEprStatusError = parseServerError(action.error);
      });
  },
});

export const {
  selectCategory1,
  selectCategory2,
  selectCategory3,
  selectCategory4,
  selectCategory5,
  setQuantity,
  setVatReturnsData,
  setResetData,
  setQuantityForTwoSteps,
  setResetEprDeclaration
} = declarationsSlice.actions;

export default declarationsSlice.reducer;
