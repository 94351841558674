import { Tooltip } from "@shopify/polaris";
import React from "react";
import infoIMG from "img/info.svg";

const InfoTooltip = ({ content, style = {}, img = "", children }) => {
  return (
    <Tooltip content={content} zIndexOverride={9999999}>
      {children}

      <img
        style={{
          verticalAlign: "middle",
          margin: "0px 4px",
          cursor: "pointer",
          ...style,
        }}
        src={img || infoIMG}
        alt='info'
      />
    </Tooltip>
  );
};

export default InfoTooltip;
