import {
	AlphaStack,
	Button,
	ButtonGroup,
	Card,
	FormLayout,
	Layout,
	Page,
	Select,
	Text,
	TextField
} from '@shopify/polaris'
import ArrowLeftMinor from 'img/arrow-left.svg'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Datepicker from '../../../../components/Datepicker'
import Modal from '../../../../components/Modal'
import PageHelmet from '../../../../components/PageHelmet'
import SaveBar from '../../../../components/SaveBar/SaveBar'
import { t } from "i18next"

const counterparties =[
	{ value: 'Buyer', label: 'Buyer' },
	{ value: 'Seller', label: 'Seller' },
]

const AddExemption = () => {
	const [changeCounterparties, setChangeCounterparties] = useState('Buyer')
	const [nameBuyer, setNameBuyer] = useState('');
	const [customerLocation, setBuyerLocation] = useState('');
	const [exemptionType, setExemptionType] = useState('');
	const [exemptionRegion, setExemptionRegion] = useState('');
	const [certificate, setCertificate] = useState('');
	const [expirationDate, setExpirationDate] = useState('');
	const [going, setGoing] = useState('');
	const [goBackModalStatus, setGoBackModalStatus] = useState(false);
	
	const navigate = useNavigate()
	
	const toggleGoBackModalStatus = () => {
		setGoBackModalStatus(!goBackModalStatus)
	}
	
	const goBack = () => {
		if (goBackModalStatus) {
			setChangeCounterparties('Seller')
			setNameBuyer('')
			setBuyerLocation('')
			setExemptionType('')
			setExemptionRegion('')
			setCertificate('')
			setExpirationDate('')
			setGoing('')
			navigate(-1);
		}
	}
	
	const handleRoleChange = (value) => {
		setChangeCounterparties(value);
	};

	const onChangeHandler = () => {
		const tehData = {
			counterparties: changeCounterparties,
			name: nameBuyer.trim(),
			location: customerLocation,
			exemption_type: exemptionType,
			exemption_region: exemptionRegion,
			certificate: certificate,
			expiration_date: String(expirationDate),
			going: going,
		}
		// console.log('tehData', tehData)
	}
	

	return (
		<Page
			separator
			fullWidth
			title={
				<div style={{display: 'flex', alignItems: 'center'}}>
					<div
						style={{
							marginRight: '10px',
							display: 'flex',
							alignItems: 'center',
							cursor: 'pointer'
						}}
					>
						<button
							onClick={toggleGoBackModalStatus}
							style={{
								backgroundColor: 'transparent',
								borderRadius: '50%',
								padding: '8px',
								border: '1px solid #637381',
								cursor: 'pointer',
							}}
						>
              <img
	              style={{cursor: 'pointer', marginTop: '2px'}}
	              src={ArrowLeftMinor}
	              alt={ArrowLeftMinor}
              />
            </button >
          </div >
          <Text
	          variant='heading3xl'
	          as='span'
          >
            Add Certificate
          </Text >
        </div >
			}
		>
			
			{goBackModalStatus &&
				<Modal
					contentOnCenter
					title={t("vatReturns.warning")}
					iconType={"warning"}
					description={'Do you really want to go back? All data will be lost.'}
					onClose={goBack}
					visible={goBackModalStatus}
					footer={
						<ButtonGroup fullWidth distribution='center'>
	            <Button onClick={goBack} size='large'>
	              Yes
	            </Button>
	            <Button
		            primary
		            onClick={toggleGoBackModalStatus}
		            size='large'
	            >
	              No
	            </Button>
            </ButtonGroup>
					}
				/>
			}
			
			<PageHelmet title={"VAT Invoice Template"} />

      <Layout >
        <Layout.Section >
	        <div style={{maxHeight: 'calc(100vh - 260px)', overflowY: 'auto'}}>
          <FormLayout >
        <FormLayout.Group >
          <Card sectioned>
            <AlphaStack
	            fullWidth
	            spacing='4'
	            style={{
		            overflowY: 'auto',
		            maxHeight: 'calc(100vh - 200px)'
	            }}
            >
             <Select
	             id='exemptionSelectCounterparties'
	             value={changeCounterparties}
	             onChange={handleRoleChange}
	             options={counterparties}
	             label='Counterparties'
             />
              <TextField
	              id='Name'
	              type='text'
	              inputMode='text'
	              placeholder={`Enter Name ${changeCounterparties}`}
	              label={`Name ${changeCounterparties}`}
	              value={nameBuyer}
	              onChange={(newValue) => setNameBuyer(newValue)}
	              autoComplete='off'
              />

              <TextField
	              id='reply_to'
	              type='email'
	              inputMode='email'
	              placeholder={`Change ${changeCounterparties} location`}
	              label={`${changeCounterparties} location`}
	              value={customerLocation}
	              onChange={(newValue) => setBuyerLocation(newValue)}
	              autoComplete='off'
              />

              <TextField
	              id='subject'
	              type='text'
	              inputMode='text'
	              placeholder='Enter Exemption type'
	              label='Exemption type'
	              value={exemptionType}
	              onChange={(newValue) => setExemptionType(newValue)}
	              autoComplete='off'
              />
              <TextField
	              id='subject'
	              type='text'
	              inputMode='text'
	              placeholder='Enter Exemption region'
	              label='Exemption region'
	              value={exemptionRegion}
	              onChange={(newValue) => setExemptionRegion(newValue)}
	              autoComplete='off'
              />
              <TextField
	              id='subject'
	              type='text'
	              inputMode='text'
	              placeholder='Enter Certificate'
	              label='Certificate'
	              value={certificate}
	              onChange={(newValue) => setCertificate(newValue)}
	              autoComplete='off'
              />
	            <Datepicker
		            label={'Expiration date'}
		            placeholder={'Change Expiration date'}
		            allowRange={false}
		            value={expirationDate ? expirationDate.toISOString().split('T')[0] : ''}
		            onChange={(value) => setExpirationDate(new Date(value))}
		            autoComplete="off"
	            />
              <TextField
	              id='subject'
	              type='text'
	              inputMode='text'
	              placeholder={`${changeCounterparties === 'Seller' ? 'Enter this is incoming' : 'Enter this is outgoing'}`}
	              label={`${changeCounterparties === 'Seller' ? 'This is incoming' : 'This is outgoing'}`}
	              value={going}
	              onChange={(newValue) => setGoing(newValue)}
	              autoComplete='off'
              />
            </AlphaStack >
          </Card >
        </FormLayout.Group >
      </FormLayout >
	        <SaveBar fullWidth>
			      <ButtonGroup >
			        <Button
				        size='large'
				        onClick={toggleGoBackModalStatus}
			        >
			          Back
			        </Button >
			        <Button
				        primary
				        size='large'
				        onClick={onChangeHandler}
				        loading=''
				        disabled=''
			        >
			          Save
			        </Button >
			      </ButtonGroup >
			    </SaveBar >
	        </div>
        </Layout.Section >
      </Layout >
		</Page >
)
}

export default AddExemption