const getPackagingSchemaForCountry = ({ country, t }) => {
  const otherCountriesSchema = [
    [t("eprReporting.aluminium"), "aluminum_kg"],
    [t("eprReporting.glass"), "glass_kg"],
    [t("eprReporting.paper"), "paper_kg"],
    [t("eprReporting.plastic"), "plastic_kg"],
    [t("eprReporting.steel"), "steel_kg"],
    [t("eprReporting.wood"), "wood_kg"],
    [t("eprReporting.numberOfScu"), "number_of_csu"],
    [t("eprReporting.other"), "other_kg"],
  ];

  const polSchema = [
    [t("eprReporting.aluminium"), "aluminum_kg"],
    [t("eprReporting.glass"), "glass_kg"],
    [t("eprReporting.paper"), "paper_kg"],
    [t("eprReporting.plastic"), "plastic_kg"],
    [t("eprReporting.steel"), "steel_kg"],
    [t("eprReporting.wood"), "wood_kg"],
    [t("eprReporting.other"), "other_kg"],
  ];

  const deuSchema = [
    [t("eprReporting.glass"), "glass_kg"],
    [t("eprReporting.paperPaperboardCardboard"), "paper_kg"],
    [t("eprReporting.ferrousMetals"), "steel_kg"],
    [t("eprReporting.aluminium"), "aluminum_kg"],
    [t("eprReporting.plastic"), "plastic_kg"],
    [t("eprReporting.beverageCartonPackaging"), "beverage_carton_packaging_kg"],
    [t("eprReporting.otherCompositesPackaging"), "other_composite_packaging"],
    [t("eprReporting.otherMaterials"), "other_kg"],
  ];

  switch (country) {
    case "DEU":
      return deuSchema;
    case "POL":
      return polSchema;
    default:
      return otherCountriesSchema;
  }
};

export { getPackagingSchemaForCountry };
