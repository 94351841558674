export function getRoleTitle(user) {
  switch (user.role) {
    case 1:
      return "Owner";
    case 2:
      return "User";
    case 3:
      return "Tax agent owner";
    case 4:
      return "Tax agent user";
    default:
      return "Unknown";
  }
}
