import React from "react";
import Modal from "../Modal";
import UserGuide from "../UserGuide/UserGuide";

const UserGuideModal = ({ onClose, visible }) => {
  return (
    <Modal
      onClose={onClose}
      infoIconVisible={false}
      visible={visible}
      content={
        <div style={{ width: "600px" }}>
          <UserGuide
            renderInline
            onLinksNavigate={onClose}
            customStyles={{ headerText: { marginRight: "30px" } }}
          />
        </div>
      }
    />
  );
};

export default UserGuideModal;
