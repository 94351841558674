export const CancelSubscriptionVariant = [
	{
		id: 1,
		title: 'Too expensive',
		subTitle: 'The cost of the service seems too high for your business.',
	},
	{
		id: 2,
		title: 'Switched to another service',
		subTitle: 'You chose another company or platform for tax compliance.',
	},
	{
		id: 3,
		title: 'Missing necessary features',
		subTitle: 'The service lacks the features or tools you need.',
	},
	{
		id: 4,
		title: 'Business structure changes',
		subTitle: 'Your business has changed its tax plan or structure in a way that no longer requires these services.',
	},
	{
		id: 5,
		title: 'Poor service quality',
		subTitle: 'You were dissatisfied with the quality of support or services.',
	},
	{
		id: 6,
		title: 'Rarely used',
		subTitle: 'The service wasn’t used as often as planned.',
	},
	{
		id: 7,
		title: 'Difficult to use',
		subTitle: 'The service seems complex and inconvenient.',
	},
	{
		id: 8,
		title: 'Other',
		subTitle: '',
	},
]